import React from 'react';
import {
  Container, Divider, Form, Grid, Icon, List, Message, Segment,
} from 'semantic-ui-react';
import has from 'has';
import { handleChange, handleError, pipe } from '../../Helpers';
import EmptyChart from '../../Components/Private/Statistics/Plots/EmptyChart';

class Statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topNClientsPackages: [],
      topNClientsWork: [],
      topNPackages: [],
      number: 5,
      error: {
        visible: false,
        message: '',
        extra: [],
      },
    };
    this.handleChange = handleChange.bind(this);
    this.handleError = handleError.bind(this);
    this.handleTopNClientsPackages = this.handleTopNClientsPackages.bind(this);
    this.handleTopNClientsWork = this.handleTopNClientsWork.bind(this);
    this.handleTopNPackages = this.handleTopNPackages.bind(this);
    this.handleFilterTopN = this.handleFilterTopN.bind(this);
  }

  componentDidMount() {
    document.title = 'ThePipeTracker - Tops';
    this.handleRequest('');
  }

  handleRequest(filter) {
    pipe(
      'get',
      `/v1/stats/topN_clients_packages${filter}`,
      this.handleTopNClientsPackages,
      this.handleError,
      null,
      null,
    );

    pipe(
      'get',
      `/v1/stats/topN_packages${filter}`,
      this.handleTopNPackages,
      this.handleError,
      null,
      null,
    );

    pipe(
      'get',
      `/v1/stats/topN_clients_work${filter}`,
      this.handleTopNClientsWork,
      this.handleError,
      null,
      null,
    );
  }

  handleTopNClientsPackages(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const { number } = this.state;
      const topNClientsPackages = [];
      res.data.data.forEach((item) => {
        topNClientsPackages.push({ owner: item.owner, total: item.total, padding: false });
      });
      if (topNClientsPackages.length < number) {
        for (let i = topNClientsPackages.length; i < number; i += 1) {
          topNClientsPackages.push({ padding: true });
        }
      }
      this.setState({ topNClientsPackages });
    }
  }

  handleTopNClientsWork(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const { number } = this.state;
      const topNClientsWork = [];
      res.data.data.forEach((item) => {
        const hours = item.interval.months * 30 * 24
          + item.interval.days * 24 + item.interval.secs / 60 / 60;
        topNClientsWork.push({ owner: item.owner, total: hours.toFixed(3), padding: false });
      });
      if (topNClientsWork.length < number) {
        for (let i = topNClientsWork.length; i < number; i += 1) {
          topNClientsWork.push({ padding: true });
        }
      }
      this.setState({ topNClientsWork });
    }
  }

  handleTopNPackages(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const { number } = this.state;
      const topNPackages = [];
      res.data.data.forEach((item) => {
        const hours = item.interval.months * 30 * 24
          + item.interval.days * 24 + item.interval.secs / 60 / 60;
        topNPackages.push({
          owner: item.owner,
          total: hours.toFixed(3),
          description: item.package_description,
          padding: false,
        });
      });
      if (topNPackages.length < number) {
        for (let i = topNPackages.length; i < number; i += 1) {
          topNPackages.push({ padding: true });
        }
      }
      this.setState({ topNPackages });
    }
  }

  handleFilterTopN() {
    const { number } = this.state;
    let filter = '';
    if (number !== '' && number > 0) {
      filter = `?number=${number}`;
      this.handleRequest(filter);
    } else {
      this.setState({
        error: { visible: true, message: 'Número Inválido' },
      });
      window.setTimeout(() => {
        this.setState({
          error: { visible: false, message: '' },
        });
      }, 5000);
    }
  }

  handleAddEnterKey(e) {
    if (e.keyCode === 13) {
      this.handleFilterTopN();
    }
  }

  render() {
    const {
      number, error, topNClientsPackages, topNClientsWork, topNPackages,
    } = this.state;
    return (
      <>
        <Container className='privateContainer'>
          <Segment color={process.env.REACT_APP_PRIMARY_COLOR}>
            <h3>Top Clientes/Encomendas</h3>

            <Form.Input
              required
              autoComplete='off'
              icon='chart bar'
              iconPosition='left'
              placeholder='Número Top'
              name='number'
              type='number'
              value={number}
              onChange={this.handleChange}
              onKeyDown={(e) => this.handleAddEnterKey(e)}
              action={{
                color: process.env.REACT_APP_PRIMARY_COLOR,
                icon: 'search',
                onClick: this.handleFilterTopN,
              }}
            />

            <Divider />
            <Message
              hidden={!error.visible}
              negative
              header='Oops, algo de errado aconteceu'
              content={error.message}
              list={error.extra}
            />
            <Grid stackable>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <h3>Clientes mais encomendas</h3>
                  {topNClientsPackages.length !== 0
                    ? (
                      <Segment color={process.env.REACT_APP_SECONDARY_COLOR}>
                        <List ordered relaxed='very' divided>
                          {topNClientsPackages.map((item, i) => (
                            !item.padding
                              ? (
                                <List.Item key={item.owner + item.total}>
                                  <List.Header>{item.owner}</List.Header>
                                  <List.Description>
                                    Total:
                                    {' '}
                                    {item.total}
                                    {' '}
                                    Encomendas
                                  </List.Description>
                                </List.Item>
                              )
                              : (
                                // eslint-disable-next-line react/no-array-index-key
                                <List.Item key={i}>
                                  <Icon name='help' />
                                  <List.Content>
                                    <List.Header>Oops</List.Header>
                                    <List.Description>
                                      Não há dados suficientes.
                                    </List.Description>
                                  </List.Content>
                                </List.Item>
                              )
                          ))}
                        </List>
                      </Segment>
                    )
                    : <EmptyChart />}
                </Grid.Column>
                <Grid.Column>
                  <h3>Clientes mais Trabalho</h3>
                  {topNClientsWork.length !== 0
                    ? (
                      <Segment color={process.env.REACT_APP_SECONDARY_COLOR}>
                        <List ordered relaxed='very' divided>
                          {topNClientsWork.map((item, i) => (
                            !item.padding
                              ? (
                                <List.Item key={item.owner + item.total}>
                                  <List.Header>{item.owner}</List.Header>
                                  <List.Description>
                                    Total:
                                    {' '}
                                    {item.total}
                                    h
                                  </List.Description>
                                </List.Item>
                              )
                              : (
                                // eslint-disable-next-line react/no-array-index-key
                                <List.Item key={i}>
                                  <Icon name='help' />
                                  <List.Content>
                                    <List.Header>Oops</List.Header>
                                    <List.Description>
                                      Não há dados suficientes.
                                    </List.Description>
                                  </List.Content>
                                </List.Item>
                              )
                          ))}
                        </List>
                      </Segment>
                    )
                    : <EmptyChart />}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <h3>Encomendas Mais Trabalho</h3>
                  {topNPackages.length !== 0
                    ? (
                      <Segment color={process.env.REACT_APP_SECONDARY_COLOR}>
                        <List ordered relaxed='very' divided>
                          {topNPackages.map((item, i) => (
                            !item.padding
                              ? (
                                <List.Item key={item.owner + item.total}>
                                  <List.Header>{item.owner}</List.Header>
                                  <List.Description>
                                    Total:
                                    {' '}
                                    {item.total}
                                    h
                                    <br />
                                    Descrição:
                                    {' '}
                                    {item.description}
                                  </List.Description>
                                </List.Item>
                              )
                              : (
                                // eslint-disable-next-line react/no-array-index-key
                                <List.Item key={i}>
                                  <Icon name='help' />
                                  <List.Content>
                                    <List.Header>Oops</List.Header>
                                    <List.Description>
                                      Não há dados suficientes.
                                    </List.Description>
                                  </List.Content>
                                </List.Item>
                              )
                          ))}
                        </List>
                      </Segment>
                    )
                    : <EmptyChart />}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </>
    );
  }
}

export default Statistics;

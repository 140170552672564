import React from 'react';
import {
  Accordion,
  Button,
  Container,
  Dropdown,
  Grid,
  Icon,
  Input,
  Label,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react';
import '../CSS/ListItems.css';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import has from 'has';
import { handleError, pipe } from '../../Helpers';

class ListItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      minDate: null,
      maxDate: null,
      direction: null,
      sortedCol: null,
      allItems: [],
      error: {
        visible: false,
        message: '',
        extra: [],
      },
      loading: true,
      column: '',
      status: '',
      input: '',
      width: window.innerWidth,
      activeIndex: -1,
    };

    this.handleClickItem = this.handleClickItem.bind(this);
    this.handleItems = this.handleItems.bind(this);
    this.handleFilterItems = this.handleFilterItems.bind(this);
    this.handleColumnChange = this.handleColumnChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleError = handleError.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const { placeHolder } = this.props;
    document.title = `ThePipeTracker - ${placeHolder}`;
    window.addEventListener('resize', this.handleWindowSizeChange);

    const urlString = window.location.href;
    const url = new URL(urlString);

    if (url.searchParams !== undefined) {
      this.setState({
        input: url.searchParams.get('input') || '',
        column: url.searchParams.get('column') || '',
        minDate: url.searchParams.get('minDate') !== null ? new Date(atob(url.searchParams.get('minDate'))) : null,
        maxDate: url.searchParams.get('maxDate') !== null ? new Date(atob(url.searchParams.get('maxDate'))) : null,
        status: url.searchParams.get('status') || '',
      });
    }

    this.handleSearch();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange(event) {
    const el = event.target;
    this.setState({ width: el.window.innerWidth });
  }

  handleItems(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const items = res.data.data;
      this.setState({ items, allItems: items, loading: false });
    }
  }

  handleAddItem() {
    const { props } = this;
    const { history, location, extraRoute } = props;
    let route = '/new';
    if (has(props, 'extraRoute')) {
      route = extraRoute + route;
    }
    history.push(location.pathname + route);
  }

  handleSearch() {
    this.setState({ loading: true });
    const { api, kind, headers } = this.props;
    const { status, minDate, maxDate } = this.state;
    const urlString = window.location.href;
    const url = new URL(urlString);
    let options = '';

    if (url.searchParams !== undefined) {
      if (url.searchParams.get('status') !== null) options = `${options}status=${url.searchParams.get('status')}&`;
      if (url.searchParams.get('minDate') !== null) options = `${options}minDate=${moment(atob(url.searchParams.get('minDate'))).format('YYYY-MM-DDTHH:mm:ss')}&`;
      if (url.searchParams.get('maxDate') !== null) options = `${options}maxDate=${moment(atob(url.searchParams.get('maxDate'))).endOf('day').format('YYYY-MM-DDTHH:mm:ss')}`;
    } else {
      if (status !== '') options = `${options}status=${status}&`;
      if (minDate !== null) options = `${options}minDate=${moment(minDate).format('YYYY-MM-DDTHH:mm:ss')}&`;
      if (maxDate !== null) options = `${options}maxDate=${moment(maxDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss')}`;
    }

    // TODO: remove extra & on end
    const isReply = kind === 'replies' ? `&${options}` : `?${options}`;

    pipe(
      'get',
      api + (options !== '' ? isReply : ''),
      this.handleFilterItems,
      this.handleError,
      null,
      headers,
    );
  }

  handleFilterItems(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const items = res.data.data;
      this.setState({ items, allItems: items, loading: false });
    }
    const { allItems, input, column } = this.state;

    if (input.length !== 0 && column.length !== 0) {
      this.setState({
        items: allItems.filter((e) => e.attributes[column].toString().match(new RegExp(input.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i'))),
        loading: false,
      });
    } else {
      this.setState({ items: allItems, loading: false, error: { visible: false, message: '' } });
    }
  }

  handleClickItem(id) {
    const { props } = this;
    const { history, location, extraRoute } = this.props;
    let route = `/${id}/edit`;
    if (has(props, 'extraRoute')) {
      route = extraRoute + route;
    }
    history.push(location.pathname + route);
  }

  handleQueryParams(filter, value) {
    const { history, location } = this.props;
    const {
      input, column, minDate, maxDate, status,
    } = this.state;
    let query = '?';
    if ((input !== '' && filter !== 'input') || (filter === 'input' && value !== '')) query = `${query}input=${filter === 'input' ? value : input}&`;
    if ((column !== '' && filter !== 'column') || (filter === 'column' && value !== '')) query = `${query}column=${filter === 'column' ? value : column}&`;
    if ((minDate !== null && filter !== 'minDate') || (filter === 'minDate' && value !== null)) query = `${query}minDate=${filter === 'minDate' ? btoa(value.toISOString()) : btoa(minDate.toISOString())}&`;
    if ((maxDate !== null && filter !== 'maxDate') || (filter === 'maxDate' && value !== null)) query = `${query}maxDate=${filter === 'maxDate' ? btoa(value.toISOString()) : btoa(maxDate.toISOString())}&`;
    if ((status !== '' && filter !== 'status') || (filter === 'status' && value !== '')) query = `${query}status=${filter === 'status' ? value : status}&`;

    history.replace(location.pathname + query.slice(0, -1));
  }

  handleColumnChange(event, e) {
    this.setState({ column: e.value });
    this.handleQueryParams('column', e.value);
  }

  handleStatusChange(event, e) {
    this.setState({ status: e.value });
    this.handleQueryParams('status', e.value);
  }

  handleChangeDate(e, name) {
    this.setState({ [name]: e });
    this.handleQueryParams(name, e);
  }

  handleChange(e) {
    const el = (e.target ? e.target : e);
    this.setState({ [el.name]: el.value });
    this.handleQueryParams(el.name, el.value);
  }

  handleSort(clickedColumn) {
    this.setState({ loading: true });
    const simpleSort = function simpleSort(a, b, key) {
      if (b.attributes[key] > a.attributes[key]) {
        return -1;
      }
      return 0;
    };
    const sortBy = (key) => (a, b) => {
      if (typeof a.attributes[key] === 'string') return a.attributes[key].localeCompare(b.attributes[key], undefined, { sensitivity: 'base' });
      return (a.attributes[key] > b.attributes[key])
        ? 1 : simpleSort(a, b, key);
    };
    const { sortedCol, items, direction } = this.state;

    if (sortedCol !== clickedColumn) {
      this.setState({
        sortedCol: clickedColumn,
        items: items.concat().sort(sortBy(clickedColumn)),
        direction: 'ascending',
        loading: false,
      });
      return;
    }

    this.setState({
      items: items.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
      loading: false,
    });
  }

  handleClick(e, titleProps) {
    const { activeIndex } = this.state;
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const {
      sortedCol, direction, input, column,
      minDate, maxDate, status, width,
      loading, activeIndex, error, items,
    } = this.state;
    const { props, handleSort } = this;
    const {
      fields, options, filterState, kind,
      placeHolder, add, search, label, renderBodyRow,
    } = props;

    const headerRow = Object.keys(fields).map((k) => (
      <Table.HeaderCell
        key={k}
        sorted={sortedCol === k ? direction : null}
        onClick={() => handleSort(k)}
      >
        {fields[k]}
      </Table.HeaderCell>
    ));

    // table body
    const defaultRenderBodyRow = ({ attributes, id }, i) => ({
      key: id || `row-${i}`,
      cells: Object.keys(fields).map((e) => attributes[e] || `No ${e} specified`),
      onClick: () => this.handleClickItem(id),
    });

    const statusOptions = [
      { key: 'started', value: 'started', text: 'Em processamento' },
      { key: 'completed', value: 'completed', text: 'Concluida' },
    ];

    const isSearch = search ? 'tbody-search-container' : 'tbody-container';

    const filter = (
      <Grid stackable columns='equal'>
        <Grid.Column>
          <Input
            required
            placeholder='Procurar por'
            name='input'
            fluid
            value={input}
            onChange={this.handleChange}
          />
        </Grid.Column>
        <Grid.Column>
          <Dropdown
            required
            placeholder='Em'
            name='column'
            clearable
            selection
            fluid
            value={column}
            onChange={this.handleColumnChange}
            options={has(props, 'options') ? options
              : Object.keys(fields).map((k) => (
                { key: k, value: k, text: fields[k] }))}
          />
        </Grid.Column>
        <Grid.Column>
          <DatePicker
            className='react-datepicker-custom'
            placeholderText='Desde'
            selected={minDate}
            onChange={(e) => this.handleChangeDate(e, 'minDate')}
            isClearable
            withPortal={width <= 800}
            dateFormat='dd/MM/yyyy'
          />
        </Grid.Column>
        <Grid.Column>
          <DatePicker
            className='react-datepicker-custom'
            placeholderText='Até'
            selected={maxDate}
            onChange={(e) => this.handleChangeDate(e, 'maxDate')}
            isClearable
            withPortal={width <= 800}
            dateFormat='dd/MM/yyyy'
          />
        </Grid.Column>
        {(has(props, 'filterState')) && filterState
          ? (
            <Grid.Column>
              <Dropdown
                required
                placeholder='Estado'
                name='status'
                clearable
                selection
                fluid
                value={status}
                onChange={this.handleStatusChange}
                options={statusOptions}
              />
            </Grid.Column>
          )
          : ''}
        <Grid.Column textAlign='right'>
          <Button
            fluid
            basic
            icon='search'
            color={process.env.REACT_APP_PRIMARY_COLOR}
            labelPosition='left'
            size='small'
            content='Procurar'
            onClick={() => this.handleSearch()}
          />
        </Grid.Column>
      </Grid>
    );

    const filterPosition = width <= 800
      ? (
        <Accordion fluid>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={this.handleClick}
          >
            <Icon name='dropdown' />
            <b>Filtros</b>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            {filter}
          </Accordion.Content>
        </Accordion>
      )
      : filter;

    return (
      <>
        <Container className={kind === 'public-packages' ? 'publicContainer' : 'privateContainer'}>
          <Segment color={process.env.REACT_APP_PRIMARY_COLOR} loading={loading}>
            <Grid unstackable='true'>
              <Grid.Column width={9}>
                <h3 style={{ fontSize: width > 450 ? '' : '1em' }}>
                  Lista de
                  {' '}
                  {placeHolder}
                </h3>
              </Grid.Column>
              {(has(props, 'add')) && add
                ? (
                  <Grid.Column width={7} textAlign='right'>
                    <Button
                      icon
                      color={process.env.REACT_APP_PRIMARY_COLOR}
                      labelPosition='left'
                      size={width > 550 ? 'small' : 'mini'}
                      onClick={() => this.handleAddItem()}
                    >
                      <Icon name='add' />
                      Adicionar
                    </Button>
                  </Grid.Column>
                )
                : ''}
              {(has(props, 'label') && label)
                ? (
                  <Grid.Column width={7} textAlign='right'>
                    <Label
                      size='large'
                      circular
                      color={process.env.REACT_APP_SECONDARY_COLOR}
                      icon='star'
                      content={items.length}
                    />
                  </Grid.Column>
                )
                : ''}
            </Grid>
            {(has(props, 'search')) && search
              ? filterPosition
              : ''}

            <Message
              negative
              hidden={!error.visible}
              header='Erro'
              content={error.message}
              list={error.extra}
            />

            {items.length !== 0
              ? (
                <Table
                  color={process.env.REACT_APP_SECONDARY_COLOR}
                  stackable
                  selectable
                  sortable
                  fixed
                  singleLine
                  padded
                  headerRow={headerRow}
                  className={!(width <= 800) ? isSearch : ''}
                  renderBodyRow={(has(props, 'renderBodyRow'))
                    ? renderBodyRow : defaultRenderBodyRow}
                  tableData={items}
                />
              )
              : ''}

            <Message
              hidden={!!items.length}
              header='Lista Vazia'
              content='Não há items para listar.'
            />
          </Segment>
        </Container>
      </>
    );
  }
}

export default ListItems;

import React, { Component } from 'react';
import {
  Button, Form, List, Message, Modal,
} from 'semantic-ui-react';
import { handleChange, handleError, pipe } from '../../../Helpers';

class SavePathModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      description: '',
      error: {
        visible: false,
        message: '',
        extra: [],
      },
    };

    this.handleError = handleError.bind(this);
    this.handleChange = handleChange.bind(this);
    this.handleAddEnterKey = this.handleAddEnterKey.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }

  handleConfirm() {
    const { description } = this.state;
    const { path } = this.props;
    pipe(
      'post',
      '/v1/paths',
      this.handleSuccess,
      this.handleError,
      {
        path: {
          value: path.map((item) => item.value).toString(),
          description,
        },
      },
      null,
    );
  }

  handleAddEnterKey(e) {
    if (e.keyCode === 13) {
      this.handleConfirm();
    }
  }

  handleSuccess() {
    const { handleModal } = this.props;
    const { updatePathsList } = this.props;
    updatePathsList();
    handleModal('saveModal', false);
  }

  render() {
    const {
      description, error,
    } = this.state;
    const { path, open, handleModal } = this.props;

    return (
      <Modal size='small' open={open}>
        <Modal.Header>
          Deseja guardar o seguinte caminho?
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Input
              fluid
              autoComplete='off'
              placeholder='Nome do Caminho'
              name='description'
              value={description}
              onChange={this.handleChange}
              onKeyDown={(e) => this.handleAddEnterKey(e)}
            />
            <p />
          </Form>
          <List horizontal>
            {path.map((item, i) => (
              <List.Item key={item.value}>
                {`${i}. ${item.text}`}
              </List.Item>
            ))}
          </List>

          <Message
            negative
            hidden={!error.visible}
            header='Erro'
            content={error.message}
            list={error.extra}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => handleModal('saveModal', false)}
            basic
            color={process.env.REACT_APP_PRIMARY_COLOR}
            labelPosition='left'
            icon='minus'
            content='Não'
          />
          <Button
            onClick={this.handleConfirm}
            color={process.env.REACT_APP_PRIMARY_COLOR}
            labelPosition='left'
            icon='checkmark'
            content='Sim'
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default SavePathModal;

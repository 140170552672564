import React from 'react';
import {
  Button, Container, Grid, Icon, List, Message, Progress, Segment,
} from 'semantic-ui-react';
import has from 'has';
import moment from 'moment';
import { handleError, pipe } from '../../Helpers';
import ListStates from '../../Components/Private/ListStates';
import Error404 from '../Errors/Error404';

class EditPackage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      number: '',
      description: '',
      owner: '',
      ownerName: '',
      registered: '',
      completed: '',
      path: [],
      completionPercent: 0,
      status: '',
      states: {
        data: [],
      },
      error: {
        visible: false,
        status: '',
        message: '',
        extra: [],
      },
      loading: true,
    };

    this.handleError = handleError.bind(this);
    this.handlePackage = this.handlePackage.bind(this);
    this.handleSurvey = this.handleSurvey.bind(this);
  }

  componentDidMount() {
    document.title = 'ThePipeTracker - Encomenda';
    const { match } = this.props;
    const { code } = match.params;
    pipe(
      'get',
      `/v1/public/packages/${code}`,
      this.handlePackage,
      this.handleError,
      null,
      null,
    );
  }

  handlePackage(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const { attributes } = res.data.data;
      this.setState({
        number: attributes.number || 'No number specified',
        description: attributes.description || 'No description specified',
        owner: attributes.owner || 'No owner specified',
        ownerName: attributes.owner_name || 'No owner specified',
        registered: attributes.registered || 'No registered specified',
        completed: attributes.completed || 'No completed specified',
        status: attributes.status || 'No status specified',
        path: this.buildPath(attributes.path),
        states: res.data.data.states || { data: [] },
        loading: false,
      });

      this.calcCompletionPercent();
    }
  }

  handleSurvey() {
    const { history, location } = this.props;
    history.push(`${location.pathname}/survey`);
  }

  calcCompletionPercent() {
    const { states, path } = this.state;
    const places = states.data.map((item) => item.attributes.place_id);
    const rest = path.filter((place) => !places.includes(place));
    const completionPercent = 100 - (rest.length / path.length) * 100;
    this.setState({ completionPercent });
  }

  // eslint-disable-next-line class-methods-use-this
  buildPath(path) {
    const newPath = [];
    if (path !== null && path !== '') {
      path.split(',').forEach((place) => {
        const pl = parseInt(place, 10);
        if (!isNaN(pl)) {
          newPath.push(pl);
        }
      });
    }
    return newPath;
  }

  render() {
    const { match } = this.props;
    const { code } = match.params;
    const {
      loading, error, status, number, description, completionPercent,
      owner, registered, states, ownerName, completed, path,
    } = this.state;

    const started = states.data.length === 0 ? 'Registada' : 'Em processamento';

    return (
      <>
        <Container className='publicContainer'>
          {!loading && error.status === 404
            ? <Error404 />
            : (
              <Segment color={process.env.REACT_APP_PRIMARY_COLOR} loading={loading}>
                <Grid columns='equal' stackable>
                  <Grid.Column>
                    <h3>A sua Encomenda</h3>
                  </Grid.Column>
                  <Grid.Column textAlign='right'>
                    <Button
                      disabled={status !== 'completed'}
                      icon
                      color={process.env.REACT_APP_PRIMARY_COLOR}
                      labelPosition='left'
                      size='small'
                      onClick={this.handleSurvey}
                    >
                      <Icon name='star' />
                      Questionário Satisfação
                    </Button>
                  </Grid.Column>
                </Grid>

                <Grid stackable divided columns='equal' padded>
                  <Grid.Row>
                    <Grid.Column>
                      <List relaxed size='large' verticalAlign='middle'>
                        <List.Item>
                          <List.Content>
                            <List.Header>Número</List.Header>
                            {number}
                          </List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Content>
                            <List.Header>Descrição</List.Header>
                            {description}
                          </List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Content>
                            <List.Header>Cliente</List.Header>
                            <p>
                              {ownerName}
                              {' - '}
                              {owner}
                            </p>
                          </List.Content>
                        </List.Item>
                      </List>
                    </Grid.Column>
                    <Grid.Column>
                      <List relaxed size='large' verticalAlign='middle'>
                        <List.Item>
                          <List.Content>
                            <List.Header>Código Único</List.Header>
                            {code}
                          </List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Content>
                            <List.Header>Data Registada</List.Header>
                            {moment(registered).format('DD/MM/YYYY')}
                          </List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Content>
                            <List.Header>Estado</List.Header>
                            {status !== 'completed'
                              ? started
                              : 'Concluída'}
                            {path.length
                              ? (
                                <Progress
                                  percent={status === 'completed' ? 100 : completionPercent}
                                  success={status === 'completed'}
                                  indicating
                                />
                              )
                              : ''}
                          </List.Content>
                        </List.Item>
                      </List>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <Message
                  hidden={!error.visible}
                  negative
                  header='Oops, algo correu mal'
                  content={error.message}
                  list={error.extra}
                />
                <p />

                {!loading
                  ? (
                    <ListStates
                      items={states}
                      color={process.env.REACT_APP_SECONDARY_COLOR}
                      publicView
                      status={status}
                      completed={completed}
                      {...this.props}
                    />
                  )
                  : ''}
              </Segment>
            )}
        </Container>
      </>
    );
  }
}

export default EditPackage;

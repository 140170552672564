import React, { Component } from 'react';
import {
  Container, Dropdown, Image, Menu,
} from 'semantic-ui-react';
import logo from '../../resources/thepipetracker.png';
import { pipe } from '../../Helpers';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange(event) {
    const el = event.target;
    this.setState({ width: el.window.innerWidth });
  }

  handleLogout() {
    const { history } = this.props;
    localStorage.removeItem('token');
    localStorage.removeItem('usertype');
    history.push('/');
    history.push('/login');
  }

  handleItemClick(event, el) {
    const { history } = this.props;

    if (el.name === '/logout') {
      pipe(
        'post',
        '/auth/sign_out',
        this.handleLogout,
        this.handleLogout,
        { token: localStorage.getItem('token') },
        null,
      );
    } else {
      history.push(el.name);
    }
  }

  render() {
    const menu = {
      simple: [{
        key: 'about',
        name: '/about',
        content: 'Sobre',
      }],
      normal: [{
        key: 'package-list',
        name: '/packages',
        content: 'Encomendas',
      }, {
        key: 'pipeline',
        name: '/pipeline',
        content: 'Pipeline',
      }, {
        key: 'calendar',
        name: '/calendar',
        content: 'Calendário',
      }],
    };
    const { value } = this.props;
    const { width } = this.state;
    const options = menu[value];

    const requestImageFile = require.context('../../resources/', true, /^\.\/.*\.png$/);
    const image = requestImageFile(`./${process.env.REACT_APP_COMPANY_SHORT}.png`);

    if (value === 'normal') {
      options.push({
        key: 'logout',
        name: '/logout',
        content: '',
        icon: 'sign-out',
      });
    }

    if (width <= 1100) {
      return (
        <Menu fixed='top' size='massive' borderless>
          <Container>
            <Menu.Item
              name='/'
              onClick={this.handleItemClick}
              header
              style={{ maxWidth: '47%', maxHeight: '85px' }}
            >
              <Image
                style={{
                  paddingTop: '10px', paddingBottom: '10px', maxHeight: '50px', maxWidth: '100%',
                }}
                src={logo}
              />
            </Menu.Item>
            <Menu.Item
              name='/'
              header
              style={{ maxWidth: '40%', maxHeight: '85px' }}
            >
              <Image
                style={{
                  paddingTop: '10px', paddingBottom: '10px', maxHeight: '60px', maxWidth: '100%',
                }}
                src={image.default}
              />
            </Menu.Item>
            <Menu.Menu position='right' className='header'>
              <Dropdown item icon='bars'>
                {value !== 'admin'
                  ? (
                    <Dropdown.Menu>
                      {options.map((option) => (
                        <Dropdown.Item
                          key={option.key}
                          onClick={this.handleItemClick}
                          {...option}
                        />
                      ))}
                    </Dropdown.Menu>
                  )
                  : (
                    <Dropdown.Menu>
                      <Dropdown.Item
                        key='package-list'
                        name='/packages'
                        content='Encomendas'
                        onClick={this.handleItemClick}
                      />
                      <Dropdown.Item
                        key='pipeline'
                        name='/pipeline'
                        content='Pipeline'
                        onClick={this.handleItemClick}
                      />
                      <Dropdown.Item
                        key='calendar'
                        name='/calendar'
                        content='Calendário'
                        onClick={this.handleItemClick}
                      />
                      <Dropdown pointing='left' item text='Estatísticas'>
                        <Dropdown.Menu direction='left'>
                          <Dropdown.Item
                            key='stats'
                            name='/statistics'
                            content='Gráficos'
                            onClick={this.handleItemClick}
                          />
                          <Dropdown.Item
                            key='tops'
                            name='/tops'
                            content='Tops'
                            onClick={this.handleItemClick}
                          />
                          <Dropdown.Item
                            key='ratings'
                            name='/mean-ratings'
                            content='Ratings'
                            onClick={this.handleItemClick}
                          />
                          <Dropdown.Item
                            key='reply-list'
                            name='/packages/replies'
                            content='Respostas'
                            onClick={this.handleItemClick}
                          />
                          <Dropdown.Item
                            key='reports'
                            name='/reports'
                            content='Relatórios'
                            onClick={this.handleItemClick}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown pointing='left' item text='Gestão'>
                        <Dropdown.Menu direction='left'>
                          <Dropdown.Item
                            key='place-list'
                            name='/places'
                            content='Locais'
                            onClick={this.handleItemClick}
                          />
                          <Dropdown.Item
                            key='user-list'
                            name='/users'
                            content='Utilizadores'
                            onClick={this.handleItemClick}
                          />
                          <Dropdown.Item
                            key='question-list'
                            name='/questions'
                            content='Questões'
                            onClick={this.handleItemClick}
                          />
                          <Dropdown.Item
                            key='shift-list'
                            name='/shifts'
                            content='Turnos'
                            onClick={this.handleItemClick}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown.Item
                        key='logout'
                        name='/logout'
                        content=''
                        icon='sign-out'
                        onClick={this.handleItemClick}
                      />
                    </Dropdown.Menu>
                  )}
              </Dropdown>
            </Menu.Menu>
          </Container>
        </Menu>
      );
    }
    return (
      <Menu fixed='top' size='massive' borderless>
        <Container>
          <Menu.Item
            name='/'
            onClick={this.handleItemClick}
            header
            style={{ maxWidth: '47%', maxHeight: '85px' }}
          >
            <Image
              style={{
                paddingTop: '10px', paddingBottom: '10px', maxHeight: '50px', maxWidth: '100%',
              }}
              src={logo}
            />
          </Menu.Item>
          <Menu.Item
            name='/'
            header
            style={{ maxWidth: '40%', maxHeight: '85px' }}
          >
            <Image
              style={{
                paddingTop: '10px', paddingBottom: '10px', maxHeight: '60px', maxWidth: '100%',
              }}
              src={image.default}
            />
          </Menu.Item>
          {value !== 'admin'
            ? (
              <Menu.Menu position='right' className='header'>
                {options.map((option) => (
                  <Menu.Item
                    key={option.key}
                    onClick={this.handleItemClick}
                    {...option}
                  />
                ))}
              </Menu.Menu>
            )
            : (
              <Menu.Menu position='right'>
                <Menu.Item
                  key='package-list'
                  name='/packages'
                  content='Encomendas'
                  onClick={this.handleItemClick}
                />
                <Menu.Item
                  key='pipeline'
                  name='/pipeline'
                  content='Pipeline'
                  onClick={this.handleItemClick}
                />
                <Menu.Item
                  key='calendar'
                  name='/calendar'
                  content='Calendário'
                  onClick={this.handleItemClick}
                />
                <Dropdown item text='Estatísticas'>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      key='stats'
                      name='/statistics'
                      content='Gráficos'
                      onClick={this.handleItemClick}
                    />
                    <Dropdown.Item
                      key='tops'
                      name='/tops'
                      content='Tops'
                      onClick={this.handleItemClick}
                    />
                    <Dropdown.Item
                      key='ratings'
                      name='/mean-ratings'
                      content='Ratings'
                      onClick={this.handleItemClick}
                    />
                    <Dropdown.Item
                      key='reply-list'
                      name='/packages/replies'
                      content='Respostas'
                      onClick={this.handleItemClick}
                    />
                    <Dropdown.Item
                      key='reports'
                      name='/reports'
                      content='Relatórios'
                      onClick={this.handleItemClick}
                    />
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown item text='Gestão'>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      key='place-list'
                      name='/places'
                      content='Locais'
                      onClick={this.handleItemClick}
                    />
                    <Dropdown.Item
                      key='user-list'
                      name='/users'
                      content='Utilizadores'
                      onClick={this.handleItemClick}
                    />
                    <Dropdown.Item
                      key='question-list'
                      name='/questions'
                      content='Questões'
                      onClick={this.handleItemClick}
                    />
                    <Dropdown.Item
                      key='shift-list'
                      name='/shifts'
                      content='Turnos'
                      onClick={this.handleItemClick}
                    />
                  </Dropdown.Menu>
                </Dropdown>
                <Menu.Item
                  key='logout'
                  name='/logout'
                  content=''
                  icon='sign-out'
                  onClick={this.handleItemClick}
                />
              </Menu.Menu>
            )}
        </Container>
      </Menu>
    );
  }
}

export default Header;

import React from 'react';
import {
  Container, Grid, Header, Icon, Image, List, Message, Segment, Step,
} from 'semantic-ui-react';
import has from 'has';
import moment from 'moment';
import noStates from '../../resources/no_states.png';
import { handleChange, handleError } from '../../Helpers';

class ListStates extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      error: {
        visible: false,
        message: '',
        extra: [],
      },
      loading: true,
    };

    this.handleChange = handleChange.bind(this);
    this.handleError = handleError.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    const { items } = props;
    if (has(props, 'items') && items.length !== 0) {
      this.setState({ items: items.data });
    }
    this.setState({ loading: false });
  }

  render() {
    const {
      color, publicView, status, completed,
    } = this.props;
    const {
      loading, error, items,
    } = this.state;
    const statusPause = function statePauseStatus(pauses, length) {
      if (length > 0 && !pauses[length - 1].ended) {
        return 'Em Pausa';
      }
      return 'Em Processamento';
    };

    return (
      <>
        <Container>
          <Segment color={color} basic={publicView} loading={loading}>
            <Message
              negative
              hidden={!error.visible}
              header='Erro'
              content={error.message}
              list={error.extra}
            />

            {items.length ? (
              <>
                <List relaxed='very' size='large' verticalAlign='middle'>
                  {items.map((item) => (
                    <Step.Group key={item.id} widths={3} fluid size='small'>
                      <Step
                        icon={item.attributes.ended ? 'flag checkered' : 'play'}
                        title={item.place.data.attributes.name}
                        description={moment(item.attributes.timestamp_start).format('DD/MM/YY, HH:mm')}
                      />
                      <Step
                        active={
                        !item.attributes.ended
                        || (item.pauses.data.length > 0
                          && !item.pauses.data[item.pauses.data.length - 1].ended)
                      }
                        completed={item.attributes.ended}
                      >
                        <Icon
                          name={item.pauses.data.length > 0 && !item.pauses.data[item.pauses.data.length - 1].ended ? 'pause' : 'clock'}
                        />
                        <Step.Content>
                          <Step.Title>
                            {!item.attributes.ended
                              ? statusPause(item.pauses.data, item.pauses.data.length)
                              : 'Concluído'}
                          </Step.Title>
                          {!item.attributes.ended
                            ? (
                              <Step.Description>
                                {
                                item.pauses.data.length > 0
                                && !item.pauses.data[item.pauses.data.length - 1].ended
                                  ? (
                                    <p>
                                      {
                                        new Date(
                                          item.pauses.data[item.pauses.data.length - 1]
                                            .timestamp_start,
                                        ).toLocaleString()
                                      }
                                      <br />
                                      {item.pauses.data[item.pauses.data.length - 1].description}
                                    </p>
                                  )
                                  : ''
                              }
                              </Step.Description>
                            )
                            : (
                              <Step.Description>
                                {moment(item.attributes.timestamp_end).format('DD/MM/YY, HH:mm')}
                              </Step.Description>
                            )}
                        </Step.Content>
                      </Step>
                    </Step.Group>
                  ))}
                  {status === 'completed'
                    ? (
                      <Step.Group widths={3} fluid size='small'>
                        <Step
                          icon='shipping fast'
                          title='Encomenda Terminada'
                          description={moment(completed).format('DD/MM/YY, HH:mm')}
                        />
                      </Step.Group>
                    )
                    : ''}
                </List>
              </>
            )
              : (
                <Segment placeholder>
                  <Grid stackable textAlign='center' verticalAlign='middle' hidden={!!items.length}>
                    <Grid.Column width={8}>
                      <Header as='h2'>
                        AINDA NÃO CONSEGUIMOS INICIAR A SUA ENCOMENDA.
                        <Header sub>
                          Consulte mais tarde.
                        </Header>
                        <Header sub>
                          Obrigado pela compreensão.
                        </Header>
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Image src={noStates} size='medium' centered />
                    </Grid.Column>
                  </Grid>
                </Segment>
              )}
          </Segment>
        </Container>
      </>
    );
  }
}

export default ListStates;

import React from 'react';
import {
  FlexibleXYPlot, Hint, HorizontalGridLines, VerticalBarSeries, XAxis, YAxis,
} from 'react-vis';

class NumPackagesPlace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numPackagesPlaceValue: null,
    };
  }

  render() {
    const MARGIN_NUMBER = {
      left: 50,
      right: 30,
      bottom: 70,
      top: 5,
    };
    const { numPackagesPlace } = this.props;
    const { numPackagesPlaceValue } = this.state;
    const {
      domain, active, paused,
    } = numPackagesPlace;

    return (
      <FlexibleXYPlot
        xType='ordinal'
        margin={MARGIN_NUMBER}
        height={300}
        yDomain={domain}
      >
        <HorizontalGridLines />
        <XAxis tickLabelAngle={-45} />
        <YAxis />
        <VerticalBarSeries
          onValueMouseOver={(v) => this.setState({ numPackagesPlaceValue: v })}
          onValueMouseOut={() => this.setState({ numPackagesPlaceValue: null })}
          color='#007399'
          data={active}
        />
        <VerticalBarSeries
          onValueMouseOver={(v) => this.setState({ numPackagesPlaceValue: v })}
          onValueMouseOut={() => this.setState({ numPackagesPlaceValue: null })}
          color='#ff3300'
          data={paused}
        />
        {
          numPackagesPlaceValue
            ? <Hint value={numPackagesPlaceValue} />
            : null
        }
      </FlexibleXYPlot>
    );
  }
}

export default NumPackagesPlace;

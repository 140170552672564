import React from 'react';
import { Container, Grid, Image } from 'semantic-ui-react';
import error from '../../resources/error404.png';
import '../CSS/Error.css';

function Error404() {
  return (
    <>
      <Container className='tableContainer'>
        <Grid columns='equal' style={{ height: '80vh' }}>
          <Grid.Column verticalAlign='middle'>
            <Image className='image404' src={error} />
          </Grid.Column>
        </Grid>
      </Container>
    </>
  );
}

export default Error404;

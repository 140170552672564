import React from 'react';
import { Message } from 'semantic-ui-react';
import has from 'has';
import { Socket } from 'phoenix';
import {
  handleError, handleErrorChannel, handleErrorSocket,
} from '../../../Helpers';
import ConfirmModal from '../../../Components/Private/Pipeline/ConfirmModal';
import EmptyPipeline from '../../../Components/Private/Pipeline/EmptyPipeline';
import PlaceGroup from '../../../Components/Private/Pipeline/PlaceGroup';

class Pipeline extends React.Component {
  static treatPausedStates(pipeline) {
    return pipeline.map((place) => {
      const newStates = place.states.data.map((state) => {
        for (let i = 0; i < state.pauses.data.length && !(has(state, 'ended')) && !(state.ended); i += 1) {
          if (!(state.pauses.data[i].ended)) {
            return {
              paused: true,
              pauseId: state.pauses.data[i].id,
              ...state,
            };
          }
        }
        return {
          paused: false,
          pauseId: -1,
          ...state,
        };
      });

      return {
        states: newStates,
        place: place.place,
        place_id: place.place_id,
      };
    });
  }

  constructor(props) {
    super(props);

    this.state = {
      reconnect: 0,
      pipeline: [],
      number: [''],
      loading: true,
      loadingInput: false,
      success: false,
      confirmModal: {
        open: false,
        loading: true,
        kind: '',
        placeHolder: '',
        place: '',
        pack: '',
        state: '',
      },
      error: {
        visible: false,
        message: '',
        extra: [],
      },
      errorChannel: {
        visible: false,
        message: '',
        extra: [],
      },
      width: window.innerWidth,
      visible: [],
    };

    this.handleError = handleError.bind(this);
    this.handleErrorChannel = handleErrorChannel.bind(this);
    this.handleErrorSocket = handleErrorSocket.bind(this);
    this.handleUnauthorized = this.handleUnauthorized.bind(this);
    this.socketRequestInfo = this.socketRequestInfo.bind(this);
    this.handlePipeline = this.handlePipeline.bind(this);
    this.handleNumberChange = this.handleNumberChange.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalRequest = this.handleModalRequest.bind(this);
    this.handleModalLoading = this.handleModalLoading.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleAddEnterKey = this.handleAddEnterKey.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handlePause = this.handlePause.bind(this);
    this.handleResume = this.handleResume.bind(this);
    this.handleTerminate = this.handleTerminate.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.handleMinMax = this.handleMinMax.bind(this);
    this.handleAddState = this.handleAddState.bind(this);
    this.handleDeleteState = this.handleDeleteState.bind(this);
    this.handleNewPause = this.handleNewPause.bind(this);
    this.handleResumePause = this.handleResumePause.bind(this);
  }

  componentDidMount() {
    document.title = 'ThePipeTracker - Pipeline';

    this.socket = new Socket(
      `${process.env.REACT_APP_SOCKET}/socket`, // must use wss
      {},
    );
    this.socket.connect();
    this.socket.onOpen(this.socketRequestInfo);
    this.socket.onError(this.handleErrorSocket);

    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    this.socket.disconnect();
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleUnauthorized() {
    const { history } = this.props;
    localStorage.removeItem('token');
    localStorage.removeItem('usertype');
    history.push('/login?redirect=true');
  }

  handleWindowSizeChange(event) {
    const el = event.target;
    this.setState({ width: el.window.innerWidth });
  }

  handleSuccess() {
    this.setState({
      errorChannel: {
        visible: false,
        message: '',
        extra: [],
      },
    });
  }

  handlePipeline(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const { width } = this.state;
      const pipes = new Array(res.data.data.length);
      const visible = new Array(res.data.data.length);
      const pipeline = Pipeline.treatPausedStates(res.data.data);

      for (let i = 0; i < pipes.length; i += 1) {
        pipes[i] = '';
        visible[i] = !(width <= 800);
      }

      const storage = localStorage.getItem('minimized');
      let minimized;
      if (storage !== null) minimized = storage.split(',').map((item) => item === 'true');
      else minimized = visible;
      this.setState({
        pipeline, loading: false, number: pipes, visible: minimized,
      });
    }

    this.handleSuccess();
  }

  handleAddState(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const placeId = res.data.data.attributes.place_id;
      const { pipeline, confirmModal, number } = this.state;
      let found = false;
      for (let i = 0; i < pipeline.length && !found; i += 1) {
        if (pipeline[i].place_id === placeId) {
          number[i] = '';
          pipeline[i].states.push(res.data.data);
          found = true;
        }
      }
      if (confirmModal.request === 'new_state') {
        confirmModal.open = false;
        confirmModal.request = '';
      }
      const errorChannel = { visible: false, message: '', extra: [] };
      this.setState({
        pipeline, confirmModal, number, errorChannel,
      });
    }

    this.handleSuccess();
  }

  handleDeleteState(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const placeId = res.data.data.attributes.place_id;
      const stateId = res.data.data.id;
      const { pipeline, confirmModal } = this.state;
      for (let i = 0; i < pipeline.length; i += 1) {
        if (pipeline[i].place_id === placeId) {
          pipeline[i].states = pipeline[i].states.filter((value) => value.id !== stateId);
        }
      }
      if (
        confirmModal.request === 'delete_state'
        || confirmModal.request === 'complete_state'
        || confirmModal.request === 'complete_package'
      ) {
        confirmModal.open = false;
        confirmModal.request = '';
      }
      const errorChannel = { visible: false, message: '', extra: [] };
      this.setState({ pipeline, confirmModal, errorChannel });
    }

    this.handleSuccess();
  }

  handleNewPause(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const placeId = res.data.data.state.place_id;
      const stateId = res.data.data.state.id;
      const pauseId = res.data.data.id;
      const { pipeline, confirmModal } = this.state;

      for (let i = 0; i < pipeline.length; i += 1) {
        if (pipeline[i].place_id === placeId) {
          for (let j = 0; j < pipeline[i].states.length; j += 1) {
            if (pipeline[i].states[j].id === stateId) {
              pipeline[i].states[j].paused = true;
              pipeline[i].states[j].pauseId = pauseId;
            }
          }
        }
      }
      if (confirmModal.request === 'new_pause') {
        confirmModal.open = false;
        confirmModal.request = '';
      }
      const errorChannel = { visible: false, message: '', extra: [] };
      this.setState({ pipeline, confirmModal, errorChannel });
    }

    this.handleSuccess();
  }

  handleResumePause(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const placeId = res.data.data.state.place_id;
      const stateId = res.data.data.state.id;
      const { pipeline, confirmModal } = this.state;

      for (let i = 0; i < pipeline.length; i += 1) {
        if (pipeline[i].place_id === placeId) {
          for (let j = 0; j < pipeline[i].states.length; j += 1) {
            if (pipeline[i].states[j].id === stateId) {
              pipeline[i].states[j].paused = false;
              pipeline[i].states[j].pauseId = -1;
            }
          }
        }
      }
      if (confirmModal.request === 'resume_pause') {
        confirmModal.open = false;
        confirmModal.request = '';
      }
      const errorChannel = { visible: false, message: '', extra: [] };
      this.setState({ pipeline, confirmModal, errorChannel });
    }

    this.handleSuccess();
  }

  handleModalClose() {
    const { confirmModal } = this.state;
    confirmModal.open = false;
    const errorChannel = { visible: false, message: '', extra: [] };
    this.setState({ confirmModal, errorChannel });
  }

  handleModalRequest(requestType) {
    const { confirmModal } = this.state;
    confirmModal.request = requestType;
    this.setState({ confirmModal });
  }

  handleModalLoading(loading) {
    const { confirmModal } = this.state;
    confirmModal.loading = loading;
    this.setState({ confirmModal });
  }

  handleAdd(placeId, i) {
    const { number } = this.state;

    if (number[i] !== '') {
      const confirmModal = {
        open: true,
        loading: true,
        kind: 'new_state',
        placeHolder: 'adicionar encomenda',
        place: placeId,
        pack: number[i],
      };
      this.setState({ confirmModal });
    }
  }

  handleComplete(placeId, stateId, packageNumber) {
    const confirmModal = {
      open: true,
      loading: true,
      kind: 'complete_state',
      placeHolder: 'completar estado',
      place: placeId,
      pack: packageNumber,
      state: stateId,
    };
    this.setState({ confirmModal });
  }

  handleTerminate(placeId, stateId, packageNumber) {
    const confirmModal = {
      open: true,
      loading: true,
      kind: 'complete_package',
      placeHolder: 'terminar encomenda',
      place: placeId,
      pack: packageNumber,
      state: stateId,
    };
    this.setState({ confirmModal });
  }

  handleDelete(placeId, stateId, packageNumber) {
    const confirmModal = {
      open: true,
      loading: true,
      kind: 'delete_state',
      placeHolder: 'remover estado',
      place: placeId,
      pack: packageNumber,
      state: stateId,
    };
    this.setState({ confirmModal });
  }

  handleResume(placeId, stateId, pauseId, packageNumber) {
    const confirmModal = {
      open: true,
      loading: true,
      kind: 'resume_pause',
      placeHolder: 'recomeçar encomenda',
      place: placeId,
      pack: packageNumber,
      state: stateId,
      pause: pauseId,
    };
    this.setState({ confirmModal });
  }

  handlePause(placeId, stateId, packageNumber) {
    const confirmModal = {
      open: true,
      loading: true,
      kind: 'new_pause',
      placeHolder: 'pausar encomenda',
      place: placeId,
      pack: packageNumber,
      state: stateId,
    };
    this.setState({ confirmModal });
  }

  handleAddEnterKey(e, placeId, i) {
    if (e.keyCode === 13) {
      this.handleAdd(placeId, i);
    }
  }

  handleNumberChange(event) {
    const el = event.target;
    const { state } = this;
    const a = state[el.name];
    a[el.id] = el.value;
    this.setState({ [el.name]: a });
  }

  handleMinMax(place, action) {
    const { visible } = this.state;
    visible[place] = action;
    localStorage.setItem('minimized', visible);
    this.setState({ visible });
  }

  registerSocketMessages() {
    this.channel.on('full_pipeline', this.handlePipeline);
    this.channel.on('new_state', this.handleAddState);
    this.channel.on('complete_state', this.handleDeleteState); // completion is equivalent to remove state
    this.channel.on('delete_state', this.handleDeleteState); // remove state
    this.channel.on('new_pause', this.handleNewPause);
    this.channel.on('resume_pause', this.handleResumePause);
    this.channel.on('complete_package', this.handleDeleteState); // completion is equivalent to remove state
    this.channel.on('error', this.handleErrorChannel);
  }

  socketRequestInfo() {
    const { reconnect } = this.state;
    this.setState({ error: { visible: false, message: '' } });

    if (reconnect < 1) {
      this.channel = this.socket.channel('pipeline:lobby', { token: localStorage.getItem('token') });
      this.channel.join();
      this.channel.on('unauthorized', this.handleUnauthorized);
      this.channel.onError(this.handleErrorChannel);
      this.registerSocketMessages();

      this.channel.push('full_pipeline', {});
    }
    this.setState({ reconnect: reconnect + 1 });
  }

  render() {
    const { history } = this.props;
    const {
      success, error, pipeline, width,
      visible, loading, number, loadingInput,
      confirmModal, errorChannel, reconnect,
    } = this.state;
    const { open } = confirmModal;

    return (
      <>
        {open
          ? (
            <ConfirmModal
              onClose={this.handleModalClose}
              onLoading={this.handleModalLoading}
              onRequest={this.handleModalRequest}
              history={history}
              info={confirmModal}
              errorChannel={errorChannel}
              channel={this.channel}
            />
          )
          : ''}
        <Message
          visible={!success}
          hidden={!success}
          success
          header='Sucesso'
          content='A sua ação foi completa com sucesso'
        />
        <Message
          hidden={!error.visible}
          negative
          header='Oops, algo de errado aconteceu'
          content={error.message}
          list={error.extra}
        />
        {pipeline.length !== 0 && reconnect <= 1
          ? (
            <PlaceGroup
              pipeline={pipeline}
              width={width}
              visible={visible}
              loading={loading}
              number={number}
              loadingInput={loadingInput}
              handleNumberChange={this.handleNumberChange}
              handleMinMax={this.handleMinMax}
              handleAddEnterKey={this.handleAddEnterKey}
              handleAdd={this.handleAdd}
              handleResume={this.handleResume}
              handleTerminate={this.handleTerminate}
              handleComplete={this.handleComplete}
              handlePause={this.handlePause}
              handleDelete={this.handleDelete}
            />
          )
          : <EmptyPipeline loading={loading} reconnect={reconnect} />}
      </>
    );
  }
}

export default Pipeline;

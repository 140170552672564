import React from 'react';
import { Header, Icon, Segment } from 'semantic-ui-react';

// eslint-disable-next-line react/prefer-stateless-function
class EmptyChart extends React.Component {
  render() {
    const { loading } = this.props;
    return (
      <Segment
        loading={loading}
        placeholder
        style={{ height: '300', width: '100%' }}
      >
        <Header icon>
          <Icon name='area graph' />
          <br />
          <p>Não há dados suficientes</p>
        </Header>
      </Segment>
    );
  }
}

export default EmptyChart;

import React from 'react';
import {
  Button, Container, Form, Grid, Message, Segment,
} from 'semantic-ui-react';
import has from 'has';
import { handleError, pipe } from '../../../Helpers';
import Error404 from '../../Errors/Error404';

class DeleteUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      level: '',
      loading: true,
      error: {
        visible: false,
        status: '',
        message: '',
        extra: [],
      },
    };

    this.handleError = handleError.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleUser = this.handleUser.bind(this);
  }

  componentDidMount() {
    document.title = 'ThePipeTracker - Eliminar Utilizador';
    const { match } = this.props;
    const { id } = match.params;
    pipe(
      'get',
      `/v1/users/${id}`,
      this.handleUser,
      this.handleError,
      null,
      null,
    );
  }

  handleUser(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      this.setState({
        email: res.data.data.attributes.email,
        level: res.data.data.attributes.level,
        loading: false,
      });
    }
  }

  handleClick() {
    this.setState({ loading: true });
    const { match } = this.props;
    const { id } = match.params;
    const { email } = this.state;

    if (email !== '') {
      pipe(
        'delete',
        `/v1/users/${id}`,
        this.handleSuccess,
        this.handleError,
        null,
        null,
      );
    }
  }

  handleSuccess() {
    const { history } = this.props;
    history.push('/users');
  }

  render() {
    const {
      loading, error, email, level,
    } = this.state;
    return (
      <>
        <Container className='privateContainer'>
          {error.status === 404
            ? <Error404 />
            : (
              <Segment color={process.env.REACT_APP_PRIMARY_COLOR} loading={loading}>
                <Grid columns='equal'>
                  <Grid.Column>
                    <h3>Eliminar Utilizador</h3>
                  </Grid.Column>
                  <Grid.Column textAlign='right'>
                    <Button
                      basic
                      icon='times'
                      labelPosition='left'
                      color={process.env.REACT_APP_PRIMARY_COLOR}
                      size='small'
                      onClick={this.handleClick}
                      content='Eliminar Utilizador'
                    />
                  </Grid.Column>
                </Grid>
                <Form>
                  <Form.Input
                    disabled
                    label='Email'
                    name='email'
                    value={email}
                  />
                  <Form.Input
                    disabled
                    label='Tipo'
                    name='level'
                    value={level}
                  />
                  <Message
                    hidden={!error.visible}
                    negative
                    header='Erro'
                    content={error.message}
                    list={error.extra}
                  />
                </Form>
              </Segment>
            )}
        </Container>
      </>
    );
  }
}

export default DeleteUser;

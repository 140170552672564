import React from 'react';
import {
  Button, Container, Form, Grid, Message, Segment,
} from 'semantic-ui-react';
import {
  handleChange, handleError, pipe, setSuccessTimeout,
} from '../../../Helpers';

class NewPlace extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      order: '',
      servers: '',
      debit: '',
      loading: false,
      success: false,
      error: {
        visible: false,
        message: '',
        extra: [],
      },
    };

    this.setSuccessTimeout = setSuccessTimeout.bind(this);
    this.handleChange = handleChange.bind(this);
    this.handleError = handleError.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
  }

  componentDidMount() {
    document.title = 'ThePipeTracker - Adicionar Local';
  }

  handleCreate() {
    this.setState({ loading: true });
    const {
      order, name, servers, debit,
    } = this.state;

    if (this.verifyContent()
      && !isNaN(parseFloat(order)
      && !isNaN(parseFloat(servers)))) {
      pipe(
        'post',
        '/v1/places',
        this.handleSuccess,
        this.handleError,
        {
          place: {
            name,
            order,
            number_servers: servers,
            debit_per_hour: debit,
          },
        },
        null,
      );
    } else {
      const error = { visible: true, message: 'Por favor preencha todos os campos' };
      this.setState({ error, loading: false });
    }
  }

  handleSuccess() {
    this.setState({
      name: '',
      order: '',
      servers: '',
      debit: '',
      success: true,
      error: { visible: false, message: '' },
      loading: false,
    });
    this.setSuccessTimeout();
  }

  verifyContent() {
    const { name, order, servers } = this.state;
    return name !== ''
      && order !== ''
      && servers !== '';
  }

  render() {
    const {
      loading, name, order, success, error,
      debit, servers,
    } = this.state;
    return (
      <>
        <Container className='privateContainer'>
          <Segment color={process.env.REACT_APP_PRIMARY_COLOR} loading={loading}>
            <Grid columns='equal' stackable>
              <Grid.Column>
                <h3>Adicionar Novo Local</h3>
              </Grid.Column>
            </Grid>
            <p />
            <Form>
              <Form.Input
                required
                autoComplete='off'
                label='Nome'
                maxLength='50'
                name='name'
                value={name}
                onChange={this.handleChange}
              />
              <Grid columns='equal' stackable>
                <Grid.Row>
                  <Grid.Column>
                    <Form.Input
                      required
                      autoComplete='off'
                      label='Ordem no Pipeline'
                      name='order'
                      max='2147483647'
                      min='-2147483648'
                      type='number'
                      value={order}
                      onChange={this.handleChange}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      required
                      autoComplete='off'
                      label='Servidores'
                      name='servers'
                      max='2147483647'
                      min='-2147483648'
                      type='number'
                      value={servers}
                      onChange={this.handleChange}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      autoComplete='off'
                      label='Débito (Unid./h)'
                      name='debit'
                      max='2147483647'
                      min='-2147483648'
                      type='number'
                      value={debit}
                      onChange={this.handleChange}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
            <p />
            <Message
              visible={!success}
              hidden={!success}
              success
              header='Local Criado'
              content='Novo Local criado com sucesso'
            />
            <Message
              hidden={!error.visible}
              negative
              header='Criação de Local falhou'
              content={error.message}
              list={error.extra}
            />
            <Button
              icon='add'
              labelPosition='left'
              color={process.env.REACT_APP_PRIMARY_COLOR}
              size='large'
              onClick={this.handleCreate}
              content='Adicionar'
            />
          </Segment>
        </Container>
      </>
    );
  }
}

export default NewPlace;

import React from 'react';
import {
  Button, Form, Grid, Header, Message, Segment,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import has from 'has';
import { handleChange, handleError, pipe } from '../../../Helpers';
import '../../CSS/Login.css';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      loading: false,
      error: {
        visible: false,
        message: '',
      },
    };

    this.handleChange = handleChange.bind(this);
    this.handleError = handleError.bind(this);
    this.handleSuccessfulLogin = this.handleSuccessfulLogin.bind(this);
    this.handleUserType = this.handleUserType.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  componentDidMount() {
    document.title = 'ThePipeTracker - Login';
  }

  shouldComponentUpdate() {
    if ((localStorage.getItem('token') || '').length) {
      this.handleSuccessfulLogin();
      return false;
    }
    return true;
  }

  handleLogin() {
    this.setState({ loading: true });
    const { email, password } = this.state;
    pipe(
      'post',
      '/auth/sign_in',
      this.handleSuccessfulLogin,
      this.handleError,
      {
        email,
        password,
      },
    );
  }

  handleSuccessfulLogin(res) {
    if (res && has(res, 'data')) {
      localStorage.setItem('token', res.data.jwt);

      pipe(
        'get',
        '/v1/users/self',
        this.handleUserType,
        this.handleError,
      );
    }
  }

  handleUserType(res) {
    if (res && res.data && has(res.data, 'data')) {
      localStorage.setItem('usertype', res.data.data.attributes.level);
      const { history } = this.props;
      const urlString = window.location.href;
      const url = new URL(urlString);

      if (url.searchParams !== undefined) {
        if (!!url.searchParams.get('redirect') && url.searchParams.get('redirect') === 'true') history.goBack();
      }
      history.push('/');
    } else this.setState({ loading: false });
  }

  render() {
    const {
      loading, email, password, error,
    } = this.state;
    return (
      <>
        <Grid equal='true' stackable centered>
          <Grid.Row verticalAlign='bottom'>
            <Grid.Column className='grid-col'>
              <Form size='large'>
                <Segment raised loading={loading}>
                  <h2 className='grid-col'>
                    Bem-Vindo
                    <Header sub>
                      Entre com a sua conta
                    </Header>
                  </h2>
                  <p />
                  <Form.Input
                    fluid
                    icon='user'
                    iconPosition='left'
                    placeholder='Email'
                    name='email'
                    value={email}
                    onChange={this.handleChange}
                  />
                  <Form.Input
                    fluid
                    icon='lock'
                    iconPosition='left'
                    placeholder='Password'
                    type='password'
                    name='password'
                    value={password}
                    onChange={this.handleChange}
                  />
                  <Message
                    hidden={!error.visible}
                    negative
                    header='Erro'
                    content='Email ou Password inválidos'
                  />
                  <Button
                    color={process.env.REACT_APP_PRIMARY_COLOR}
                    fluid
                    size='large'
                    onClick={this.handleLogin}
                  >
                    Login
                  </Button>
                </Segment>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row verticalAlign='top'>
            <Grid.Column className='grid-col' textAlign='center'>
              <Message>
                <Link to='/forgot-password'>Esqueceu-se da sua password?</Link>
              </Message>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}

export default Login;

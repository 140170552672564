import React from 'react';
import moment from 'moment';
import ListItems from '../../../Components/Private/ListItems';

class Shifts extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickShift = this.handleClickShift.bind(this);
  }

  handleClickShift(id) {
    const { history, location } = this.props;
    const urlString = window.location.href;
    const url = new URL(urlString);
    history.push(`${location.pathname}/${id}/edit${url.search}`);
  }

  render() {
    const week = [
      'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo',
    ];
    const weekday = function getWeedayName(day) {
      return week[day - 1];
    };

    const shiftRenderBodyRow = ({ attributes, id }, i) => ({
      key: id || `row-${i}`,
      onClick: () => this.handleClickShift(id),
      cells: [
        { key: 'weekday', content: weekday(attributes.weekday) || 'No weekday specified' },
        { key: 'timestamp_start', content: moment(attributes.timestamp_start).format('HH:mm') || 'No start specified' },
        { key: 'timestamp_end', content: moment(attributes.timestamp_end).format('HH:mm') || 'No end specified' },
      ],
    });

    return (
      <ListItems
        placeHolder='Turnos'
        kind='shifts'
        api='/v1/shifts'
        add
        fields={{
          timestamp_start: 'Início',
          timestamp_end: 'Fim',
          weekday: 'Dia',
        }}
        renderBodyRow={shiftRenderBodyRow}
        {...this.props}
      />
    );
  }
}

export default Shifts;

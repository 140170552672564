import React from 'react';
import Survey from '../../../Components/Shared/Survey';

// eslint-disable-next-line react/prefer-stateless-function
class AnswerSurvey extends React.Component {
  render() {
    const { match } = this.props;
    const { id } = match.params;
    return (
      <Survey
        placeHolder='Resposta Questionário'
        kind='view'
        api={`/v1/packages/${id}/replies`}
        disabled
        {...this.props}
      />
    );
  }
}

export default AnswerSurvey;

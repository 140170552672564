import axios from 'axios';
import moment from 'moment';
import has from 'has';

function buildExtraError(data) {
  const extra = [];
  Object.keys(data).forEach((e) => {
    if (Array.isArray(data[e])) {
      data[e].map((d) => extra.push(d));
    }
  });
  return extra.filter((v, i, a) => a.indexOf(v) === i);
}

export function handleError(error) {
  // Polyfill
  if (!Array.isArray) {
    Array.isArray = function isArray(arg) {
      return Object.prototype.toString.call(arg) === '[object Array]';
    };
  }

  if (
    error.response
    && has(error.response.data, 'error')
    && (
      error.response.data.error === 'unauthenticated'
      || error.response.data.error === 'invalid_token'
      || error.response.data.error === 'no_resource_found'
    )
    && error.response.statusText === 'Unauthorized'
  ) {
    localStorage.removeItem('token');
    localStorage.removeItem('usertype');
    this.props.history.push('/login?redirect=true');
  } else if (error.response == null) {
    this.setState({
      error: {
        visible: true,
        status: -1,
        message: 'Erro de Ligação. Por favor tente mais tarde.',
      },
    });
  } else {
    const extra = buildExtraError(error.response.data.errors);

    if (error.response.status === 401) {
      this.setState({
        error: {
          visible: true,
          status: 401,
          message: 'Não possui autorização para esta ação.',
        },
      });
    } else if (error.response.status === 404) {
      this.setState({
        error: {
          visible: true,
          status: 404,
          message: 'Este conteúdo não existe.',
        },
      });
    } else if (error.response.status === 422) {
      this.setState({
        error: {
          visible: true,
          status: 422,
          extra,
        },
      });
    } else if (error.response.status === 400 || error.response.status >= 500) {
      this.setState({
        error: {
          visible: true,
          status: error.response.status,
          message: 'Oops, Algo correu mal.',
        },
      });
    } else {
      this.setState({
        error: {
          visible: true,
          status: error.response.status,
          message: `Erro ${error.response.status} - ${error.response.statusText}`,
          extra,
        },
      });
    }
    this.setState({ loading: false });
  }

  if (error.response && error.response.status !== 404) {
    window.setTimeout(() => {
      this.setState({ error: { visible: false, message: '' } });
    }, 5000);
  }
}

export function handleErrorSocket() {
  this.setState({
    error: { visible: true, message: 'Erro de Ligação. Por favor tente mais tarde.' },
    loading: true,
    pipeline: [],
    number: 0,
  });
}

export function handleErrorChannel(res) {
  if (res && has(res, 'data')) {
    let extra = [];
    if (has(res.data, 'error')) {
      extra = buildExtraError(res.data.error.errors);
    }

    if (res.data.status === 401) {
      this.setState({
        errorChannel: {
          visible: true,
          status: 401,
          message: 'Não possui autorização para esta ação.',
        },
      });
    } else if (res.data.status === 404) {
      this.setState({
        errorChannel: {
          visible: true,
          status: 404,
          message: 'Este conteúdo não existe.',
        },
      });
    } else if (res.data.status === 422) {
      this.setState({
        errorChannel: {
          visible: true,
          status: 422,
          extra,
        },
      });
    } else {
      this.setState({
        errorChannel: {
          visible: true,
          status: res.status,
          message: 'Oops, Algo correu mal.',
          extra,
        },
      });
    }
  } else {
    this.setState({
      errorChannel: {
        visible: true,
        status: '500',
        message: 'Oops, Algo correu mal.',
      },
    });
  }
  this.handleModalLoading(false);
}

export function handleChange(e) {
  const el = (e.target ? e.target : e);
  this.setState({ [el.name]: el.value });
}

export function getFromLocalStorage(item) {
  try {
    return localStorage.getItem(item);
  } catch (e) {
    return null;
  }
}

export function pipe(method, endpoint, onfulfilled, onrejected, data, defHeaders, cancelToken) {
  const apiEndpoint = process.env.REACT_APP_API + endpoint;

  const headers = defHeaders || {};
  headers.Authorization = `Bearer ${getFromLocalStorage('token')}`;
  headers['content-type'] = 'application/json';
  headers.accept = 'application/json';

  const config = {};
  config.method = method;
  config.url = apiEndpoint;
  config.headers = headers;
  config.cancelToken = cancelToken;
  if (data) config.data = data;

  return axios(config)
    .then(onfulfilled)
    .catch((thrown) => {
      if (!axios.isCancel(thrown)) {
        onrejected(thrown);
      }
    });
}

export function filterQueryDate(minDate, maxDate) {
  let options = '';
  if (minDate !== null) options = `${options}minDate=${moment(minDate).format('YYYY-MM-DDTHH:mm:ss')}&`;
  if (maxDate !== null) options = `${options}maxDate=${moment(maxDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss')}`;

  return options;
}

export function setSuccessTimeout(isMounted) {
  window.setTimeout(() => {
    if (isMounted) {
      this.setState({
        success: false,
      });
    }
  }, 5000);
}

export function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

export function getCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

import React from 'react';
import {
  Button, Container, Form, Message, Segment,
} from 'semantic-ui-react';
import {
  handleChange, handleError, pipe, setSuccessTimeout,
} from '../../../Helpers';

class NewUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      errorDiff: false,
      errorInvalid: false,
      success: false,
      loading: false,
      error: {
        visible: false,
        message: '',
        extra: [],
      },
    };

    this.setSuccessTimeout = setSuccessTimeout.bind(this);
    this.handleError = handleError.bind(this);
    this.handleChange = handleChange.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
  }

  componentDidMount() {
    document.title = 'ThePipeTracker - Adicionar Utilizador';
  }

  handleCreate() {
    this.setState({ loading: true });
    const { password, confirmPassword, email } = this.state;

    if ((/^.{8,}$/g.exec(password)) == null) {
      this.setState({ errorInvalid: true, loading: false });
    } else if (password !== confirmPassword) {
      this.setState({ errorDiff: true, loading: false });
    } else {
      pipe(
        'post',
        '/v1/users',
        this.handleSuccess,
        this.handleError,
        {
          user: {
            email,
            password,
          },
        },
        null,
      );
    }
  }

  handleSuccess() {
    this.setState({
      email: '',
      password: '',
      confirmPassword: '',
      success: true,
      loading: false,
      errorDiff: false,
      errorInvalid: false,
      error: { visible: false, message: '' },
    });
    this.setSuccessTimeout();
  }

  render() {
    const {
      loading, email, password, confirmPassword,
      errorDiff, error, success, errorInvalid,
    } = this.state;

    return (
      <>
        <Container className='privateContainer'>
          <Segment color={process.env.REACT_APP_PRIMARY_COLOR} loading={loading}>
            <h3>Adicionar Novo Utilizador</h3>
            <Form>
              <Form.Input
                required
                label='Email'
                name='email'
                maxLength='50'
                value={email}
                onChange={this.handleChange}
              />
              <Form.Input
                required
                label='Password'
                name='password'
                type='password'
                maxLength='50'
                value={password}
                onChange={this.handleChange}
              />
              <Form.Input
                required
                label='Confirmar Password'
                name='confirmPassword'
                maxLength='50'
                type='password'
                value={confirmPassword}
                onChange={this.handleChange}
              />
              <Message
                hidden={!errorInvalid}
                negative
                content='Tem de ter pelo menos 8 caracteres'
              />
              <Message
                hidden={!errorDiff}
                negative
                content='Passwords não são iguais'
              />
              <Message
                visible={!success}
                hidden={!success}
                success
                header='Utilizador Criado'
                content='Novo Utilizador criado com sucesso'
              />
              <Message
                hidden={!error.visible}
                negative
                header='Criação de Utilizador falhou'
                content={error.message}
                list={error.extra}
              />
              <Button
                icon='add'
                labelPosition='left'
                color={process.env.REACT_APP_PRIMARY_COLOR}
                size='large'
                onClick={this.handleCreate}
                content='Adicionar'
              />
            </Form>
          </Segment>
        </Container>
      </>
    );
  }
}

export default NewUser;

import React from 'react';
import { Label } from 'semantic-ui-react';
import ListItems from '../../Components/Private/ListItems';

class ListPublicPackages extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickPackage = this.handleClickPackage.bind(this);
  }

  handleClickPackage(number) {
    const { history } = this.props;
    history.push(`/public/my-package/${number}`);
  }

  render() {
    const { match } = this.props;
    const { owner } = match.params;
    const started = function hasStarted(statesCount) {
      if (statesCount === 0) {
        return { key: 'status', content: <Label color='red'>Registada</Label> };
      }
      return 'Em processamento';
    };

    const packageRenderBodyRow = ({ attributes }, i) => ({
      key: attributes.slug || `row-${i}`,
      onClick: () => this.handleClickPackage(attributes.number),
      cells: [
        { key: 'number', content: attributes.number || 'No number specified' },
        { key: 'description', content: attributes.description || 'No description specified' },
        { key: 'registered', content: new Date(attributes.registered).toLocaleString() || 'None' },
        (attributes.status === 'started')
          ? started(attributes.states_count)
          : { key: 'status', content: <Label color='green'>Concluída</Label> },
      ],
    });

    return (
      <ListItems
        kind='public-packages'
        placeHolder={`Encomendas - ${atob(owner)}`}
        api={`/v1/public/packages?owner=${owner}`}
        add={false}
        fields={{
          number: 'Código',
          description: 'Descrição',
          registered: 'Registada',
          status: 'Estado',
        }}
        renderBodyRow={packageRenderBodyRow}
        {...this.props}
      />
    );
  }
}

export default ListPublicPackages;

import 'react-app-polyfill/ie11';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Header from './Components/Shared/Header';
import Login from './Pages/Public/Authentication/Login';
import ChangePassword from './Pages/Public/Authentication/ChangePassword';
import MyFooter from './Components/Shared/MyFooter';
import Error404 from './Pages/Errors/Error404';
import About from './Pages/Shared/About';
import './App.css';
import ForgotPassword from './Pages/Public/Authentication/ForgotPassword';
import ErrorBoundary from './Pages/Errors/ErrorBoundary';
import Packages from './Pages/Private/Packages/Packages';
import NewPackage from './Pages/Private/Packages/New';
import EditPackage from './Pages/Private/Packages/Edit';
import Users from './Pages/Private/Users/Users';
import NewUser from './Pages/Private/Users/New';
import DeleteUser from './Pages/Private/Users/Delete';
import Places from './Pages/Private/Places/Places';
import NewPlace from './Pages/Private/Places/New';
import EditPlace from './Pages/Private/Places/Edit';
import Pipeline from './Pages/Private/Pipeline/Pipeline';
import PublicPackage from './Pages/Public/PublicPackage';
import Home from './Pages/Public/Home';
import Statistics from './Pages/Private/Statistics';
import Tops from './Pages/Private/Tops';
import ListPublicPackages from './Pages/Public/ListPublicPackages';
import ConfirmDeletePackage from './Pages/Private/Packages/ConfirmDeletePackage';
import Privacy from './Pages/Shared/Privacy';
import CookieBanner from './Components/Shared/CookieBanner';
import { getCookie, getFromLocalStorage } from './Helpers';
import Questions from './Pages/Private/Questions/Questions';
import NewQuestion from './Pages/Private/Questions/New';
import EditQuestion from './Pages/Private/Questions/Edit';
import Replies from './Pages/Private/Replies';
import AnswerSurvey from './Pages/Public/AnswerSurvey';
import ViewSurvey from './Pages/Private/Packages/ViewSurvey';
import Ratings from './Pages/Private/Ratings';
import Calendar from './Pages/Private/Calendar';
import Shifts from './Pages/Private/Shifts/Shifts';
import EditShift from './Pages/Private/Shifts/Edit';
import NewShift from './Pages/Private/Shifts/New';
import Reports from './Pages/Private/Reports';

function PublicRoute({
  component: Component, restricted, render, ...rest
}) {
  const authenticated = getFromLocalStorage('token') === null && restricted ? render : () => ('');
  return (
    <Route
      {...rest}
      render={render
        ? authenticated
        : (props) => (
          !!getFromLocalStorage('token') && restricted
            ? <Redirect to='/packages' />
            : <Component {...props} />
        )}
    />
  );
}

function PrivateRoute({
  component: Component, render, restricted, ...rest
}) {
  const permission = function hasPrivilege(props) {
    if ((getFromLocalStorage('usertype') === 'normal' && !restricted) || getFromLocalStorage('usertype') === 'admin') {
      return <Component {...props} />;
    }
    return <Redirect to='/packages' />;
  };

  return (
    <Route
      {...rest}
      render={render || ((props) => (
        getFromLocalStorage('token')
          ? permission(props)
          : <Redirect to='/login' />
      ))}
    />
  );
}

function App() {
  const routerSwitch = (
    <>
      <Switch>
        <PrivateRoute
          path='/'
          render={(props) => (
            <Header
              {...props}
              value={getFromLocalStorage('usertype') || 'simple'}
            />
          )}
        />
      </Switch>
      <Switch>
        <PrivateRoute restricted={false} exact path='/packages' component={Packages} />
        <PrivateRoute restricted={false} exact path='/packages/new' component={NewPackage} />
        <PrivateRoute restricted={false} exact path='/packages/:id/edit' component={EditPackage} />
        <PrivateRoute
          restricted={false}
          exact
          path='/packages/:id/delete'
          component={ConfirmDeletePackage}
        />
        <PrivateRoute restricted={false} exact path='/pipeline' component={Pipeline} />
        <PrivateRoute restricted={false} exact path='/calendar' component={Calendar} />
        <PrivateRoute restricted exact path='/packages/:id/reply' component={ViewSurvey} />
        <PrivateRoute restricted exact path='/packages/replies' component={Replies} />
        <PrivateRoute restricted exact path='/users' component={Users} />
        <PrivateRoute restricted exact path='/users/new' component={NewUser} />
        <PrivateRoute restricted exact path='/users/:id/edit' component={DeleteUser} />
        <PrivateRoute restricted exact path='/places' component={Places} />
        <PrivateRoute restricted exact path='/places/new' component={NewPlace} />
        <PrivateRoute restricted exact path='/places/:id/edit' component={EditPlace} />
        <PrivateRoute restricted exact path='/shifts' component={Shifts} />
        <PrivateRoute restricted exact path='/shifts/new' component={NewShift} />
        <PrivateRoute restricted exact path='/shifts/:id/edit' component={EditShift} />
        <PrivateRoute restricted exact path='/questions' component={Questions} />
        <PrivateRoute restricted exact path='/questions/new' component={NewQuestion} />
        <PrivateRoute restricted exact path='/questions/:id/edit' component={EditQuestion} />
        <PrivateRoute restricted exact path='/statistics' component={Statistics} />
        <PrivateRoute restricted exact path='/tops' component={Tops} />
        <PrivateRoute restricted exact path='/mean-ratings' component={Ratings} />
        <PrivateRoute restricted exact path='/reports' component={Reports} />

        <PublicRoute restricted={false} exact path='/about' component={About} />
        <PublicRoute restricted={false} exact path='/home' component={Home} />
        <PublicRoute restricted={false} exact path='/privacy-policy' component={Privacy} />
        <PublicRoute restricted exact path='/public/my-package/:code' component={PublicPackage} />
        <PublicRoute restricted exact path='/public/my-package/:code' component={PublicPackage} />
        <PublicRoute restricted exact path='/public/packages/:owner' component={ListPublicPackages} />
        <PublicRoute
          restricted
          exact
          path='/public/my-package/:code/survey'
          component={AnswerSurvey}
        />
        <PublicRoute restricted exact path='/login' component={Login} />
        <PublicRoute restricted exact path='/' component={Home} />
        <PublicRoute restricted exact path='/forgot-password' component={ForgotPassword} />
        <PublicRoute restricted exact path='/password/edit/:token?' component={ChangePassword} />
        <PrivateRoute restricted={false} exact path='/' render={() => <Redirect to='/packages' />} />
        <Route path='/' component={Error404} />
      </Switch>
      <Switch>
        <PublicRoute
          restricted
          path='/'
          render={(props) => <footer id='footer'><MyFooter {...props} value='other' /></footer>}
        />
      </Switch>
    </>
  );

  return (
    <ErrorBoundary>
      <>
        <div id='page-container'>
          <div id='content-wrap'>
            {getCookie('accepts-cookies') === 'true'
              ? ''
              : <CookieBanner />}
            {routerSwitch}
          </div>
        </div>
      </>
    </ErrorBoundary>
  );
}

export default App;

import React from 'react';
import {
  Container, Divider, Dropdown, Grid, Segment, Tab,
} from 'semantic-ui-react';
import 'react-vis/dist/style.css';
import DatePicker from 'react-datepicker';
import {
  filterQueryDate, handleChange, handleError,
} from '../../Helpers';
import PackageTab from '../../Components/Private/Statistics/Tabs/PackageTab';
import PlaceTab from '../../Components/Private/Statistics/Tabs/PlaceTab';
import QueueTab from '../../Components/Private/Statistics/Tabs/QueueTab';

class Statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minDate: null,
      maxDate: null,
      group: '',
      activeTab: 0,
    };

    this.handleChange = handleChange.bind(this);
    this.handleError = handleError.bind(this);
    this.filterQueryDate = filterQueryDate.bind(this);
    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  componentDidMount() {
    document.title = 'ThePipeTracker - Estatísticas';
    let { activeTab } = this.state;
    const urlString = window.location.href;
    const url = new URL(urlString);
    if (url.searchParams !== undefined) {
      if (url.searchParams.get('activeTab') !== null) {
        activeTab = url.searchParams.get('activeTab');
        this.setState({ activeTab });
      }
    }
  }

  handleGroupChange(event, e) {
    this.setState({ group: e.value });
  }

  handleChangeDate(e, name) {
    this.setState({ [name]: e });
  }

  handleTabChange(e, { activeIndex }) {
    const { history, location } = this.props;
    this.setState({ activeTab: activeIndex });
    history.replace(`${location.pathname}?activeTab=${activeIndex}`);
  }

  filterOptions() {
    const { group, minDate, maxDate } = this.state;
    let options = '';
    if (group !== '') options = `${options}group=${group}&`;

    options += this.filterQueryDate(minDate, maxDate);

    return options;
  }

  render() {
    const {
      minDate, maxDate, group, activeTab,
    } = this.state;

    const groupOptions = [
      { value: 'week', text: 'Semana' },
      { value: 'month', text: 'Mês' },
      { value: 'quarter', text: 'Trimestre' },
      { value: 'year', text: 'Ano' },
    ];

    const panes = [
      {
        menuItem: 'Encomendas',
        render: () => (
          <PackageTab filterOptions={this.filterOptions()} />
        ),
      },
      {
        menuItem: 'Locais',
        render: () => (
          <PlaceTab filterOptions={this.filterOptions()} />
        ),
      },
      {
        menuItem: 'Filas',
        render: () => (
          <QueueTab filterOptions={this.filterOptions()} />
        ),
      },
    ];

    return (
      <>
        <Container className='privateContainer'>
          <Segment color={process.env.REACT_APP_PRIMARY_COLOR}>
            <h3>Estatísticas</h3>
            <Grid centered stackable>
              <Grid.Row columns='equal'>
                <Grid.Column textAlign='left' width={4}>
                  <DatePicker
                    className='react-datepicker-custom'
                    placeholderText='Desde'
                    selected={minDate}
                    onChange={(e) => this.handleChangeDate(e, 'minDate')}
                    isClearable
                    dateFormat='dd/MM/yyyy'
                  />
                </Grid.Column>
                <Grid.Column textAlign='left' width={4}>
                  <DatePicker
                    className='react-datepicker-custom'
                    placeholderText='Até'
                    selected={maxDate}
                    onChange={(e) => this.handleChangeDate(e, 'maxDate')}
                    isClearable
                    dateFormat='dd/MM/yyyy'
                  />
                </Grid.Column>
                <Grid.Column textAlign='left' width={8}>
                  <Dropdown
                    required
                    placeholder='Agrupar por'
                    name='group'
                    clearable
                    selection
                    fluid
                    value={group}
                    onChange={this.handleGroupChange}
                    options={groupOptions}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider />

            <Tab
              activeIndex={activeTab}
              onTabChange={this.handleTabChange}
              panes={panes}
            />

          </Segment>
        </Container>
      </>
    );
  }
}

export default Statistics;

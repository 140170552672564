import React from 'react';
import {
  Accordion, Container, Icon, List, Segment,
} from 'semantic-ui-react';
import { handleChange, handleError } from '../../Helpers';

class Privacy extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: -1,
    };

    this.handleError = handleError.bind(this);
    this.handleChange = handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.title = 'ThePipeTracker - Política de Privacidade';
  }

  handleClick(e, titleProps) {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;
    return (
      <>
        <Container className='publicContainer'>
          <Segment color={process.env.REACT_APP_PRIMARY_COLOR}>
            <h3>Política de Privacidade</h3>

            <p>
              Para nós, é importante proteger os seus dados pessoais durante
              o processamento em todo o processo empresarial.
            </p>
            <p>
              Nos seguintes pontos, explicamos que informações são recolhidas no momento em que
              registou a sua encomenda, quando utiliza o nosso serviço ou visita o nosso website.
              Também é explicado de que forma estas informações são utilizadas.
            </p>

            <Accordion fluid>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={this.handleClick}
              >
                <Icon name='dropdown' />
                <b>O que são Dados Pessoais?</b>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                <p>
                  Os dados pessoais são qualquer informação relativa a uma pessoa singular
                  identificada ou identificável,
                  {' '}
                  <b>titular dos dados.</b>
                  {' '}
                  Uma
                  pessoa singular identificável é aquela que pode ser identificada, direta ou
                  indiretamente, nomeadamente por referência a elementos de
                  identificação como um nome, um número de identificação, dados de localização, uma
                  identificação online ou a um ou mais fatores específicos físicos,
                  psicológicos, genéticos, mentais, económicos, culturais ou de identidade social da
                  pessoa singular.
                  Estes incluem informações como o seu nome real, a morada, o número de telefone e a
                  data de nascimento. As informações que não podem ser ligadas à sua identidade
                  real, tais como os websites favoritos ou o número de utilizadores de um website,
                  não são consideradas dados pessoais.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={this.handleClick}
              >
                <Icon name='dropdown' />
                <b>Quais são os objetivos para o processamento de Dados Pessoais?</b>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 1}>
                <h5>Visitar o nosso Website</h5>
                <p>
                  Estamos empenhados em preservar a privacidade dos utilizadores dos nossos
                  websites. Quando visita as nossas páginas Web, os nossos servidores Web
                  podem guardar temporariamente, para fins de segurança, os dados de ligação
                  do computador que está a estabelecer ligação com o nosso website, uma lista
                  das páginas Web que visitou no nosso website,
                  a data e a duração da sua visita, o endereço IP do seu dispositivo, os dados de
                  identificação do tipo de navegador e do sistema operativo que utilizou, bem como o
                  website através do qual entrou no nosso website.
                  Informações pessoais adicionais como nome, morada, número de telefone
                  ou endereço de e-mail não são recolhidas exceto se fornecer estes dados
                  voluntariamente, por exemplo, ao preencher um formulário de contacto online,
                  como parte de um inquérito, ou pedido de informação.
                </p>
                <p>
                  A base legal para o processamento das referidas categorias de dados é o Art. 6 (1)
                  (a) do Regulamento Geral Europeu para a Protecção de Dados (European General Data
                  Protection Regulation - GDPR).
                  Devido aos objetivos mencionados, nomeadamente para garantir a segurança e o
                  estabelecimento de uma ligação sem problemas, temos o interesse legítimo em
                  processar estes dados.
                </p>
                <p>
                  Além disso, utilizamos cookies, ferramentas de controlo e ações de direcionamento.
                  Encontrará mais informação abaixo em
                  {' '}
                  <b>&quot;Utilização de Cookies&quot;</b>
                  .
                </p>
                <h5>Registo de Encomendas</h5>
                <p>
                  Por razões contratuais, necessitamos igualmente dos dados pessoais como email
                  e nome de cliente/empresa, para fornecer os nossos serviços. Estes dados são
                  fornecidos no momento de registo da encomenda com a empresa em questão
                  (
                  <b>{process.env.REACT_APP_COMPANY_FULL}</b>
                  )
                </p>
                <p>
                  Estes dados são utilizados, por exemplo, para notificar o cliente que a sua
                  encomenda foi registada com sucesso (através do envio de email), para possibilitar
                  o cliente de consultar todas as suas encomendas registadas e o seu estado atual.
                </p>
                <p>
                  A base legal é normalmente o Art. 6 (1) b) GDPR, dado que o processamento
                  serve para a execução de um contrato, do qual é uma parte.
                </p>
                <p>
                  Para mais Informações adicionais entre em contacto connosco através do formulário
                  acima.
                </p>
                <h5>Processamento para Fins Publicitários</h5>
                <p>
                  Não realizamos qualquer tipo de contacto para fins publicitário.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 2}
                index={2}
                onClick={this.handleClick}
              >
                <Icon name='dropdown' />
                <b>Utilização de Cookies</b>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 2}>
                <p>
                  Os &quot;cookies&quot; são pequenos ficheiros que permitem armazenar
                  informações relacionadas com o seu PC e o utilizador, especificamente,
                  quando visita um dos nossos websites. Os cookies ajudam-nos a determinar
                  a frequência com que as nossas páginas da Internet são visitadas,
                  bem como o número de utilizadores.
                </p>
                <p>
                  Com base no Art. 6 (1) f) GDPR, utilizamos cookies de sessões no sentido de
                  otimizar o nosso website e para garantir uma experiência prática e sem
                  perturbações para o utilizador.
                </p>
                <p>
                  Quando a acede ao nosso Website, podemos guardar um número de cookies no seu
                  navegador.
                  Utilizamos cookies para os seguintes propósitos:
                </p>
                <List bulleted>
                  <List.Item>
                    Para disponibilizar certas funções do Serviço.
                  </List.Item>
                  <List.Item>
                    Para disponibilizar estatísticas.
                  </List.Item>
                  <List.Item>
                    Para armazenar as suas preferências.
                  </List.Item>
                </List>
                <p>
                  Utilizamos &quot;cookies de sessão&quot; , &quot;cookies permanents&quot;
                  e &quot;cookies essenciais&quot; para correr o Serviço.
                  Poderemos utilizar cookies para autenticar utilizadores e impedir utilizações
                  fraudulentas de contas de utilizador.
                </p>
                <h5>Cookies de Terceiros</h5>
                <p>
                  Em adição aos nossos próprios cookies, poderemos também utilizar vários cookies de
                  terceiros para, por exemplo, reportar estatísticas de utilização do Serviço, entre
                  outros.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 3}
                index={3}
                onClick={this.handleClick}
              >
                <Icon name='dropdown' />
                <b>Gestão de Cookies</b>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 3}>
                <p>
                  Também é possível utilizar o nosso serviços sem cookies. No seu navegador, pode
                  desativar o armazenamento de cookies, limitá-lo em determinados websites ou
                  definir o navegador para que o informe
                  quando um cookie é enviado. Também pode eliminar cookies do disco rígido do seu PC
                  em qualquer altura.
                  Tenha em atenção que, neste caso, é de esperar uma apresentação limitada
                  da página e uma orientação limitada de utilizador.
                </p>
                <p>
                  Notar que, se eliminar os seus cookies ou utilizar um navegador ou computador
                  diferente, tem de definir novamente o seu estado de não participação.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 4}
                index={4}
                onClick={this.handleClick}
              >
                <Icon name='dropdown' />
                <b>Os meus dados são partilhados?</b>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 4}>
                <p>
                  ThePipeTracker não partilha, vende, transfere ou divulga de outra forma os seus
                  dados pessoais a terceiros e não o fará no futuro, exceto se for exigido por lei,
                  ou se tiver dado o seu consentimento expresso para tal.
                  Para Informação adicional sobre proteção de dados entre em contacto connosco.
                </p>
                <p>
                  Os fornecedores de serviço externo que realizam o processamento/armazenamento de
                  dados seguem também o GPDR.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 5}
                index={5}
                onClick={this.handleClick}
              >
                <Icon name='dropdown' />
                <b>Quais são os meus Direitos?</b>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 5}>
                <p>
                  Para além do direito de obter, tem os seguintes direitos:
                </p>
                <List bulleted>
                  <List.Item>
                    Pode solicitar informação sobre que dados pessoais estão armazenados.
                  </List.Item>
                  <List.Item>
                    Pode solicitar a alteração, eliminação ou bloqueio dos seus dados pessoais,
                    desde que estas ações sejam permitidas por lei e se encontrem em conformidade
                    com as condições contratuais existentes.
                  </List.Item>
                  <List.Item>
                    Pode solicitar receber os dados pessoais que forneceu num formato legível por
                    computador, estruturado e de uso comum.
                  </List.Item>
                </List>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 6}
                index={6}
                onClick={this.handleClick}
              >
                <Icon name='dropdown' />
                <b>Qual é a segurança dos meus Dados?</b>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 6}>
                <p>
                  ThePipeTracker toma medidas de segurança técnicas e organizacionais
                  necessárias para proteger os seus dados pessoais contra perdas e
                  utilização abusiva. Por exemplo, os seus dados são guardados num ambiente seguro
                  que não pode ser acedido pelo público.
                  Em alguns casos, os seus dados pessoais são encriptados pela tecnologia Secure
                  Socket Layer (SSL) durante a transmissão.
                  Isto significa que é utilizado um procedimento de encriptação aprovado para a
                  comunicação entre o seu computador e nossos servidores,
                  se o seu navegador for compatível com SSL.
                </p>
                <p>
                  Se nos contactar a através de e-mail, gostaríamos de referir que não é possível
                  garantir a confidencialidade das informações enviadas.
                  Os conteúdos das mensagens de e-mail podem ser lidos por terceiros.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 7}
                index={7}
                onClick={this.handleClick}
              >
                <Icon name='dropdown' />
                <b>Alterações à Política de Privacidade</b>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 7}>
                <p>
                  ThePipeTracker reserva-se o direito de alterar o Aviso de Privacidade em qualquer
                  altura com ou sem aviso prévio.
                  Consulte a mesma frequentemente para estar informado das alterações.
                  Ao utilizar o nosso serviço, concorda com o presente Aviso de Privacidade.
                </p>
                <p>
                  Esta declaração foi atualizada pela última vez a 16 de Agosto de 2019.
                </p>
              </Accordion.Content>
            </Accordion>
          </Segment>
        </Container>
      </>
    );
  }
}

export default Privacy;

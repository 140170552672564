import React from 'react';
import {
  Container, Grid, Label, List, Message, Progress, Rating, Segment, Statistic,
} from 'semantic-ui-react';
import '../CSS/Ratings.css';
import { DiscreteColorLegend } from 'react-vis';
import DatePicker from 'react-datepicker';
import has from 'has';
import {
  filterQueryDate, handleChange, handleError, pipe,
} from '../../Helpers';
import RatingsEvolution from '../../Components/Private/Statistics/Plots/RatingsEvolution';
import EmptyChart from '../../Components/Private/Statistics/Plots/EmptyChart';

class Ratings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minDate: null, // new Date(moment().subtract(6, 'month').format('M/D/YYYY')),
      maxDate: null, // new Date(moment().format('M/D/YYYY')),
      ratingsEvolution: {
        data: [],
        loading: true,
      },
      maxLength: 0,
      meanRatings: {
        data: [],
        loading: true,
      },
      error: {
        visible: false,
        message: '',
      },
    };
    this.handleChange = handleChange.bind(this);
    this.handleError = handleError.bind(this);
    this.filterQueryDate = filterQueryDate.bind(this);
    this.handleMeanRatings = this.handleMeanRatings.bind(this);
    this.handleRatingsEvolution = this.handleRatingsEvolution.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleRequest = this.handleRequest.bind(this);
  }

  componentDidMount() {
    document.title = 'ThePipeTracker - Tops';
    this.handleRequest();
  }

  handleRequest() {
    const { ratingsEvolution, meanRatings } = this.state;
    this.setState({
      ratingsEvolution: { ...ratingsEvolution, loading: true },
      meanRatings: { ...meanRatings, loading: true },
    });
    const options = this.filterOptions();

    pipe(
      'get',
      `/v1/stats/mean_rating_replies${options !== '' ? `?${options}` : ''}`,
      this.handleMeanRatings,
      this.handleError,
      null,
      null,
    );

    pipe(
      'get',
      `/v1/stats/rating_replies_evolution${options !== '' ? `?${options}` : ''}`,
      this.handleRatingsEvolution,
      this.handleError,
      null,
      null,
    );
  }

  handleRatingsEvolution(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const evolution = { data: [], loading: false };
      let maxLength = 0;
      res.data.data.forEach((question) => {
        const evo = [];
        let acc = 0;
        question.ratings.forEach((elem, i) => {
          acc += elem.rating;
          evo.push({
            x: i + 1,
            y: acc / (i + 1),
          });
          maxLength = (maxLength < i + 1) ? i + 1 : maxLength;
        });
        evolution.data.push({
          question: question.question_description,
          id: question.question_id,
          evo,
        });
      });

      this.setState({ ratingsEvolution: evolution, maxLength });
    }
  }

  handleMeanRatings(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const meanRatings = { data: [], loading: false };
      res.data.data.forEach((item) => {
        const counts = [0, 0, 0, 0, 0];
        let sum = 0; let
          count = 0;
        item.ratings.forEach((ratings) => {
          sum += ratings.rating * ratings.count;
          count += ratings.count;
          counts[ratings.rating - 1] = ratings.count;
        });
        meanRatings.data.push({
          question_description: item.question_description,
          question_id: item.question_id,
          ratings: counts,
          total_ratings: count,
          mean: sum / count,
        });
      });
      this.setState({ meanRatings });
    }
  }

  handleChangeDate(e, name) {
    this.setState({ [name]: e });
  }

  filterOptions() {
    const { minDate, maxDate } = this.state;
    return this.filterQueryDate(minDate, maxDate);
  }

  render() {
    const {
      error, meanRatings, ratingsEvolution,
      minDate, maxDate, maxLength,
    } = this.state;
    return (
      <>
        <Container className='privateContainer'>
          <Segment color={process.env.REACT_APP_PRIMARY_COLOR}>
            <h3>Ratings - Questionário</h3>
            <Message
              hidden={!error.visible}
              negative
              header='Oops, algo de errado aconteceu'
              content={error.message}
              list={error.extra}
            />

            <Message
              hidden={!!meanRatings.data.length}
              header='Sem Ratings'
              content='Ainda não foram respondidos questionários de satisfação pelos clientes.'
            />

            <Grid stackable>
              <Grid.Row columns='equal'>
                <Grid.Column textAlign='left' width={4}>
                  <DatePicker
                    className='react-datepicker-custom'
                    placeholderText='Desde'
                    selected={minDate}
                    onChange={(e) => this.handleChangeDate(e, 'minDate')}
                    isClearable
                    dateFormat='dd/MM/yyyy'
                  />
                </Grid.Column>
                <Grid.Column textAlign='left' width={4}>
                  <DatePicker
                    className='react-datepicker-custom'
                    placeholderText='Até'
                    selected={maxDate}
                    onChange={(e) => this.handleChangeDate(e, 'maxDate')}
                    isClearable
                    dateFormat='dd/MM/yyyy'
                  />
                </Grid.Column>
                <Grid.Column textAlign='right' width={8} verticalAlign='middle'>
                  <Label
                    as='a'
                    basic
                    color={process.env.REACT_APP_PRIMARY_COLOR}
                    content='Evolução Ratings'
                    icon='search'
                    onClick={this.handleRequest}
                    size='large'
                  />
                </Grid.Column>
              </Grid.Row>
              {ratingsEvolution.data.length !== 0
                ? (
                  <Grid.Row columns='equal'>
                    <Grid.Column verticalAlign='top' textAlign='center'>
                      <RatingsEvolution
                        ratingsEvolution={ratingsEvolution}
                        maxLength={maxLength}
                      />
                    </Grid.Column>
                  </Grid.Row>
                )
                : (
                  <Grid.Row columns='equal'>
                    <Grid.Column verticalAlign='top' textAlign='center'>
                      <EmptyChart loading={ratingsEvolution.loading} />
                    </Grid.Column>
                  </Grid.Row>
                )}
              <DiscreteColorLegend
                style={{ width: '100%', paddingLeft: '30px' }}
                orientation='horizontal'
                height={60}
                items={
                ratingsEvolution.data.map((item) => (
                  { title: item.id.toString() }
                ))
                }
              />
            </Grid>

            <List celled>
              {meanRatings.data.map((item) => (
                <List.Item key={item.question_id}>
                  <Segment basic loading={meanRatings.loading}>
                    <Grid stackable>
                      <Grid.Row columns='equal'>
                        <Grid.Column>
                          <h4 style={{ color: 'black' }}>
                            {item.question_id}
                            .
                            {' '}
                            {item.question_description}
                          </h4>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={4} verticalAlign='middle' textAlign='center'>
                          <Statistic label='em 5' size='huge' value={item.mean.toFixed(1)} />
                        </Grid.Column>
                        <Grid.Column width={12}>
                          {item.ratings.map((count, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Grid.Row key={i}>
                              <Grid.Column width={2} textAlign='right'>
                                <Rating
                                  disabled
                                  maxRating={i + 1}
                                  size='mini'
                                  rating={i + 1}
                                />
                              </Grid.Column>
                              <Grid.Column width={14} textAlign='left'>
                                <Progress
                                  color='grey'
                                  size='tiny'
                                  value={count}
                                  total={item.total_ratings}
                                />
                              </Grid.Column>
                            </Grid.Row>
                          ))}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                </List.Item>
              ))}
            </List>
          </Segment>
        </Container>
      </>
    );
  }
}

export default Ratings;

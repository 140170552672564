import React from 'react';
import {
  Document, Page, Text, View, StyleSheet, Image, Font,
} from '@react-pdf/renderer';
import moment from 'moment';
import logo from '../../resources/thepipetracker.png';
import font from '../../resources/GothamLight.otf';

// eslint-disable-next-line react/prefer-stateless-function
class ReportPDF extends React.Component {
  render() {
    // Register font
    Font.register({ family: 'Gotham', src: font });
    // Create styles
    const styles = StyleSheet.create({
      header: {
        flexDirection: 'row',
        alignItems: 'stretch',
      },
      mainSection: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
      },
      section: {
        marginLeft: 10,
        padding: 10,
        flexGrow: 1,
      },
      tableSection: {
        marginLeft: 25,
        flexGrow: 1,
      },
      image: {
        height: 30,
        maxWidth: '32%',
      },
      mainTitle: {
        textAlign: 'center',
        fontSize: 18,
      },
      sectionTitle: {
        textDecoration: 'underline',
        fontSize: 18,
      },
      tableTitle: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: 15,
      },
      text: {
        // fontFamily: 'Gotham',
        fontSize: 12,
      },
    });
    const {
      minDate, maxDate, registered, completed,
      onTime, surveyCount, production, ratings,
    } = this.props;
    const image = require(`../../resources/${process.env.REACT_APP_COMPANY_SHORT}.png`);

    return (
      <Document>
        <Page size='A4'>
          <View>
            <View fixed>
              <View style={[styles.header, { height: 37 }]}>
                <View style={[styles.tableSection, { margin: 10 }]}>
                  <Image
                    src={logo}
                    style={styles.image}
                  />
                </View>
                <View style={[styles.tableSection, { margin: 10 }]}>
                  <Image
                    src={image.default}
                    style={styles.image}
                  />
                </View>
              </View>
              <View style={[styles.mainSection, { backgroundColor: '#E8E8E8' }]} fixed>
                <Text style={styles.mainTitle}>
                  {moment(minDate).format('DD/MM/YYYY')}
                  {' - '}
                  {moment(maxDate).format('DD/MM/YYYY')}
                </Text>
              </View>
            </View>

            <View style={styles.header} fixed>
              <View style={[styles.tableSection, { width: 165 }]}>
                <Text style={styles.sectionTitle}> </Text>
              </View>
              <View style={styles.tableSection}>
                <Text style={styles.sectionTitle}>Atual</Text>
              </View>
              <View style={styles.tableSection}>
                <Text style={styles.sectionTitle}>Passado</Text>
              </View>
            </View>

            <View style={[styles.tableSection, { paddingTop: 10, marginTop: 10 }]}>
              <Text style={styles.sectionTitle}>Informação</Text>
            </View>

            <View style={styles.header}>
              <View style={[styles.tableSection]}>
                <Text style={styles.text}>Encomendas Registadas:</Text>
                <Text style={styles.text}>Encomendas Terminadas:</Text>
                <Text style={styles.text}>Entregues a tempo:</Text>
                <Text style={styles.text}>Respostas Obtidas:</Text>
              </View>
              <View style={[styles.tableSection, { marginLeft: 30 }]}>
                <Text style={styles.text}>{registered.present}</Text>
                <Text style={styles.text}>{completed.present}</Text>
                <Text style={styles.text}>{onTime.present}</Text>
                <Text style={styles.text}>{surveyCount.present}</Text>
              </View>
              <View style={styles.tableSection}>
                <Text style={styles.text}>{registered.past}</Text>
                <Text style={styles.text}>{completed.past}</Text>
                <Text style={styles.text}>{onTime.past}</Text>
                <Text style={styles.text}>{surveyCount.past}</Text>
              </View>
            </View>

            <View style={[styles.tableSection, { paddingTop: 10, marginTop: 10 }]}>
              <Text style={styles.sectionTitle}>Produção</Text>
            </View>

            {production.places.map((place, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <View key={i} wrap={false}>
                <View style={[styles.header]}>
                  <View style={[styles.tableSection]}>
                    <Text style={styles.tableTitle}>{place}</Text>
                  </View>
                </View>
                <View style={[styles.header, { paddingBottom: 25 }]}>
                  <View style={[styles.tableSection]}>
                    <Text style={styles.text}>Utilização:</Text>
                    <Text style={styles.text}>Performance:</Text>
                    <Text style={styles.text}>Eficiência:</Text>
                  </View>
                  <View style={[styles.tableSection, { marginLeft: 115 }]}>
                    <Text style={styles.text}>{`${production.present.utilization[i]}%`}</Text>
                    <Text style={styles.text}>{`${production.present.performance[i]}%`}</Text>
                    <Text style={styles.text}>{`${production.present.oee[i]}%`}</Text>
                  </View>
                  <View style={styles.tableSection}>
                    <Text style={styles.text}>{`${production.past.utilization[i]}%`}</Text>
                    <Text style={styles.text}>{`${production.past.performance[i]}%`}</Text>
                    <Text style={styles.text}>{`${production.past.oee[i]}%`}</Text>
                  </View>
                </View>
              </View>
            ))}

            <View style={[styles.tableSection]}>
              <Text style={styles.sectionTitle}>Questionários</Text>
            </View>

            {ratings.present.map((rating, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <View key={i} wrap={false}>
                <View style={[styles.header]}>
                  <View style={[styles.tableSection]}>
                    <Text style={styles.tableTitle}>{`${i + 1}. ${rating.question_description}`}</Text>
                  </View>
                </View>
                <View style={[styles.header, { paddingBottom: 25 }]}>
                  <View style={[styles.tableSection, { width: 105 }]}>
                    <Text style={styles.text}>Rating Médio:</Text>
                  </View>
                  <View style={[styles.tableSection, { marginLeft: 60 }]}>
                    <Text style={styles.text}>{rating.mean.toFixed(1)}</Text>
                  </View>
                  <View style={styles.tableSection}>
                    <Text style={styles.text}>{ratings.past[i].mean.toFixed(1)}</Text>
                  </View>
                </View>
              </View>
            ))}

          </View>
        </Page>
      </Document>
    );
  }
}

export default ReportPDF;

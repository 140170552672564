import React from 'react';
import { Label, Segment } from 'semantic-ui-react';

// eslint-disable-next-line react/prefer-stateless-function
class MinimizedPlace extends React.Component {
  render() {
    const {
      width, pipePlace, placeOrder, handleMinMax,
    } = this.props;
    return (
      <Segment.Group>
        <Segment
          color={process.env.REACT_APP_TERTIARY_COLOR}
          className={!(width <= 800) ? 'minimizedContent' : 'minimizedContentMobile'}
          secondary
        >
          <Label
            as='a'
            icon='angle right'
            size='big'
            content={!(width <= 800) ? '' : pipePlace.place}
            style={{ background: '#F3F4F5', color: 'rgba(0, 0, 0, 0.6)' }}
            onClick={() => handleMinMax(placeOrder, true)}
            attached='top'
          />
          {!(width <= 800)
            ? (
              <h4
                style={{
                  writingMode: !(width <= 800) ? 'vertical-rl' : '',
                  textOrientation: 'upright',
                  paddingTop: '3px',
                }}
              >
                {pipePlace.place}
              </h4>
            )
            : ''}
        </Segment>
      </Segment.Group>
    );
  }
}

export default MinimizedPlace;

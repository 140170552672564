import React from 'react';
import {
  Accordion, Button, Container, Form, Grid, Icon, Message, Segment,
} from 'semantic-ui-react';
import { handleChange, handleError, pipe } from '../../Helpers';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      number: '',
      activeIndex: -1,
      info: false,
      loading: false,
      error: {
        visible: false,
        message: '',
        extra: [],
      },
    };

    this.handleChange = handleChange.bind(this);
    this.handleError = handleError.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }

  componentDidMount() {
    document.title = 'ThePipeTracker - Home';
  }

  handleSearch(event) {
    const { history } = this.props;
    const { number, email } = this.state;

    if (number !== '') {
      history.push(`/public/my-package/${number}`);
    } else if (email !== '') {
      this.setState({ loading: true });
      pipe(
        'post',
        '/v1/public/packages',
        this.handleSuccess,
        this.handleError,
        {
          email,
        },
        null,
      );
    } else {
      const error = { visible: true, message: 'Por favor preencha um dos campos' };
      this.setState({ error });
      window.setTimeout(() => {
        this.setState({ error: { visible: false, message: '' } });
      }, 5000);
    }
    event.preventDefault();
  }

  handleSuccess() {
    this.setState({ loading: false, info: true });
    window.setTimeout(() => {
      this.setState({ info: false });
    }, 5000);
  }

  handleClick(e, titleProps) {
    const { activeIndex } = this.state;
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const {
      loading, number, error, activeIndex, info, email,
    } = this.state;
    return (
      <>
        <Container className='publicContainer'>
          <Segment color={process.env.REACT_APP_PRIMARY_COLOR} loading={loading}>
            <h3>Bem Vindo, procure a sua encomenda</h3>
            <p>*Escolha um método de pesquisa de encomenda*</p>
            <Form size='large'>
              <Grid divided>
                <Grid.Row columns='equal'>
                  <Grid.Column>
                    <Form.Input
                      fluid
                      icon='box'
                      iconPosition='left'
                      placeholder='Código'
                      label='Para ver Encomenda'
                      name='number'
                      value={number}
                      onChange={this.handleChange}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      fluid
                      icon='user'
                      iconPosition='left'
                      label='Para ver todas as Encomendas'
                      placeholder='Email'
                      name='email'
                      type='email'
                      value={email}
                      onChange={this.handleChange}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <p />
              <Button
                icon='search'
                labelPosition='left'
                color={process.env.REACT_APP_PRIMARY_COLOR}
                size='large'
                onClick={this.handleSearch}
                content='Procurar'
              />
              <Message
                info
                hidden={!info}
                header='Informação'
                content='Se contém encomendas registadas connosco, irá receber um email em breve.'
              />

              <Message
                negative
                hidden={!error.visible}
                header='Erro'
                content={error.message}
                list={error.extra}
              />
              <p />
              <Accordion fluid>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={this.handleClick}
                >
                  <Icon name='dropdown' />
                  <b>Termos e Condições</b>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                  <p>
                    Está autorizado a utilizar este sistema de tracking para consultar encomendas
                    registadas para si.
                    Nenhum outro uso deve ser feito deste sistema, sem o nosso consentimento.
                  </p>
                </Accordion.Content>
              </Accordion>
            </Form>
          </Segment>
        </Container>
      </>
    );
  }
}

export default Home;

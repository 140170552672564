import React from 'react';
import {
  Button, Container, Form, Grid, List, Message, Segment,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import has from 'has';
import axios from 'axios';
import {
  handleChange, handleError, pipe, setSuccessTimeout,
} from '../../../Helpers';
import SavePathModal from '../../../Components/Private/Packages/SavePathModal';
import ListPathsModal from '../../../Components/Private/Packages/ListPathsModal';

class NewPackage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      number: '',
      description: '',
      owner: '',
      owners: [],
      ownersDisplay: [],
      ownerName: '',
      deliveryDate: (new Date()).setDate(new Date().getDate() + 21), // default in 21 days
      quantity: '',
      path: [],
      pathDescription: [],
      listPlaces: [],
      saveModal: false,
      listModal: false,
      loading: false,
      success: false,
      listPaths: [],
      listDescriptions: [],
      error: {
        visible: false,
        message: '',
        extra: [],
      },
    };
    const { CancelToken } = axios;
    this.source = CancelToken.source();
    this.setSuccessTimeout = setSuccessTimeout.bind(this);
    this.handleChange = handleChange.bind(this);
    this.handleError = handleError.bind(this);
    this.handleOwnerChange = this.handleOwnerChange.bind(this);
    this.handleOwnerSelection = this.handleOwnerSelection.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleOwners = this.handleOwners.bind(this);
    this.handlePlaces = this.handlePlaces.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangePath = this.handleChangePath.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleSetPath = this.handleSetPath.bind(this);
    this.handleUpdatePathList = this.handleUpdatePathList.bind(this);
    this.handleListPaths = this.handleListPaths.bind(this);
  }

  componentDidMount() {
    document.title = 'ThePipeTracker - Adicionar Encomenda';
    this.mounted = true;
    this.setState({ loading: true });
    pipe(
      'get',
      '/v1/owners',
      this.handleOwners,
      this.handleError,
      null,
      null,
      this.source.token,
    );

    pipe(
      'get',
      '/v1/places?status=active',
      this.handlePlaces,
      this.handleError,
      null,
      null,
      this.source.token,
    );
  }

  componentWillUnmount() {
    this.source.cancel();
    this.mounted = false;
  }

  handlePlaces(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const listPlaces = res.data.data.map((place) => ({
        text: place.name,
        value: place.id,
        key: place.id,
      }));

      this.setState({
        listPlaces,
        loading: false,
      });
    }
  }

  handleOwners(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      this.setState({
        owners: res.data.data,
        ownersDisplay: res.data.data,
        loading: false,
      });
    }
  }

  handleCreate() {
    this.setState({ loading: true });
    const {
      number, description, owner, path,
      quantity, ownerName, deliveryDate,
    } = this.state;

    if (this.verifyContent() && !isNaN(parseFloat(number))) {
      pipe(
        'post',
        '/v1/packages',
        this.handleSuccess,
        this.handleError,
        {
          package: {
            number,
            description,
            owner,
            quantity,
            path: path.toString(),
            owner_name: ownerName,
            delivery_date: moment(deliveryDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          },
        },
        null,
      );
    } else if (isNaN(number)) {
      const error = { visible: true, message: 'Número de Encomenda Inválido' };
      this.setState({ error, loading: false });
    } else {
      const error = { visible: true, message: 'Por favor preencha todos os campos' };
      this.setState({ error, loading: false });
    }
  }

  handleSuccess() {
    const { owners } = this.state;
    // pedir atualização de owners
    pipe(
      'get',
      '/v1/owners',
      this.handleOwners,
      this.handleError,
      null,
      null,
      this.source.token,
    );

    this.setState({
      description: '',
      number: '',
      owner: '',
      ownerName: '',
      quantity: '',
      path: [],
      ownersDisplay: owners,
      deliveryDate: (new Date()).setDate(new Date().getDate() + 21), // default in 21 days
      success: true,
      error: { visible: false, message: '' },
    });
    this.setSuccessTimeout(this.mounted);
  }

  handleOwnerChange(e, searchParam) {
    const { owners } = this.state;
    const el = (e.target ? e.target : e);
    const ownersDisplay = owners.filter((owner) => owner[searchParam].toString().match(new RegExp(el.value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i')));
    this.setState({ [el.name]: el.value, ownersDisplay });
  }

  handleOwnerSelection(name, email) {
    this.setState({ owner: email, ownerName: name });
  }

  handleChangeDate(e) {
    this.setState({ deliveryDate: e });
  }

  handleChangePath(item) {
    const { listPlaces } = this.state;
    const path = item.value;
    const pathDescription = item.value.map((value) => (
      listPlaces.find((element) => element.value === value)
    ));
    this.setState({ path, pathDescription });
  }

  handleModal(name, status) {
    this.setState({ [name]: status });
  }

  handleUpdatePathList() {
    pipe(
      'get',
      '/v1/paths',
      this.handleListPaths,
      this.handleError,
      null,
      null,
    );
  }

  handleListPaths(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const listDescriptions = res.data.data.map((path) => path.attributes.description);
      const listPaths = res.data.data;
      this.setState({ listPaths, listDescriptions });
    }
  }

  handleSetPath(path) {
    const item = {};
    item.value = path.split(',').map((place) => parseInt(place, 10));
    this.handleChangePath(item);
  }

  verifyContent() {
    const {
      number, description, owner, ownerName, deliveryDate,
    } = this.state;
    return number !== ''
      && description !== ''
      && owner !== ''
      && ownerName !== ''
      && deliveryDate !== '';
  }

  render() {
    const {
      loading, number, description, deliveryDate, owner,
      quantity, ownerName, ownersDisplay, listModal, listPaths, listDescriptions,
      listPlaces, path, pathDescription, saveModal, success, error,
    } = this.state;
    return (
      <>
        <Container className='privateContainer'>
          <SavePathModal
            path={pathDescription}
            open={saveModal}
            handleModal={this.handleModal}
            updatePathsList={this.handleUpdatePathList}
          />
          <ListPathsModal
            open={listModal}
            listPaths={listPaths}
            listDescriptions={listDescriptions}
            handleModal={this.handleModal}
            setPath={this.handleSetPath}
            updatePathsList={this.handleUpdatePathList}
          />
          <Segment color={process.env.REACT_APP_PRIMARY_COLOR} loading={loading}>
            <Grid columns='equal' stackable>
              <Grid.Column>
                <h3>Adicionar Nova Encomenda</h3>
              </Grid.Column>
            </Grid>
            <p />
            <Form>
              <Form.Input
                required
                autoComplete='off'
                label='Número'
                name='number'
                max='9223372036854775807'
                min='-9223372036854775808'
                type='number'
                value={number}
                onChange={this.handleChange}
              />
              <Form.TextArea
                required
                autoComplete='off'
                label='Descrição (Limite 250 char.)'
                name='description'
                maxLength='250'
                value={description}
                onChange={this.handleChange}
              />
              <Form.Input
                autoComplete='off'
                label='Quantidade'
                name='quantity'
                max='9223372036854775807'
                min='-9223372036854775808'
                type='number'
                value={quantity}
                onChange={this.handleChange}
              />
              <Form.Dropdown
                renderLabel={(text, index) => `${index + 1}. ${text.text}`}
                label='Caminho de Produção'
                name='path'
                fluid
                multiple
                selection
                options={listPlaces}
                onChange={(e, item) => this.handleChangePath(item)}
                value={path}
              />
              <Button.Group floated='right' icon color={process.env.REACT_APP_PRIMARY_COLOR}>
                <Button
                  icon='save'
                  onClick={() => this.handleModal('saveModal', true)}
                />
                <Button
                  icon='cloud upload'
                  onClick={() => this.handleModal('listModal', true)}
                />
              </Button.Group>

              <p>
                Data de Entrega
                <span style={{ color: 'red' }}>*</span>
                {' '}
                (Valor default para 3 semanas)
              </p>
              <DatePicker
                className='react-datepicker-custom'
                selected={deliveryDate}
                onChange={(date) => this.handleChangeDate(date)}
                dateFormat='dd/MM/yyyy'
              />
              <Segment color={process.env.REACT_APP_SECONDARY_COLOR}>
                <Grid columns='equal' stackable divided>
                  <Grid.Column>
                    <h4>Informação de Cliente</h4>
                    <Form.Input
                      required
                      autoComplete='off'
                      label='Nome Cliente'
                      name='ownerName'
                      maxLength='50'
                      value={ownerName}
                      onChange={(e) => this.handleOwnerChange(e, 'owner_name')}
                    />
                    <Form.Input
                      required
                      autoComplete='off'
                      label='Email Cliente'
                      name='owner'
                      maxLength='50'
                      type='email'
                      value={owner}
                      onChange={(e) => this.handleOwnerChange(e, 'owner')}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Segment
                      style={{ height: '170px', overflow: 'auto' }}
                      basic
                      loading={loading}
                    >
                      <List selection verticalAlign='middle' divided>
                        {ownersDisplay.map((item) => (
                          <List.Item
                            key={item.owner_name + item.owner}
                            onClick={() => this.handleOwnerSelection(item.owner_name, item.owner)}
                          >
                            <List.Icon name='user' verticalAlign='middle' />
                            <List.Content>
                              <List.Header>
                                {item.owner_name}
                              </List.Header>
                              <List.Description>
                                {item.owner}
                              </List.Description>
                            </List.Content>
                          </List.Item>
                        ))}
                      </List>
                    </Segment>
                  </Grid.Column>
                </Grid>
              </Segment>
            </Form>
            <p />
            <Button
              icon='add'
              labelPosition='left'
              color={process.env.REACT_APP_PRIMARY_COLOR}
              size='large'
              onClick={this.handleCreate}
              content='Adicionar'
            />
            <Message
              visible={!success}
              hidden={!success}
              success
              header='Encomenda Criada'
              content='Nova Encomenda criada com sucesso'
            />
            <Message
              hidden={!error.visible}
              negative
              header='Criação de Encomenda falhou'
              content={error.message}
              list={error.extra}
            />
          </Segment>
        </Container>
      </>
    );
  }
}

export default NewPackage;

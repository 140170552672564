import React from 'react';
import moment from 'moment';
import ListItems from '../../Components/Private/ListItems';

class Replies extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickPackage = this.handleClickPackage.bind(this);
  }

  handleClickPackage(id) {
    const { history } = this.props;
    history.push(`/packages/${id}/reply`);
  }

  render() {
    const packageRenderBodyRow = ({ attributes, id }, i) => ({
      key: attributes.slug || `row-${i}`,
      onClick: () => this.handleClickPackage(id),
      cells: [
        { key: 'number', content: attributes.number || 'No number specified' },
        { key: 'description', content: attributes.description || 'No description specified' },
        { key: 'owner', content: attributes.owner || 'None' },
        { key: 'owner_name', content: attributes.owner_name || 'None' },
        { key: 'completed', content: moment(attributes.completed).format('DD/MM/YYYY') || 'None' },
      ],
    });

    return (
      <ListItems
        className='privateContainer'
        placeHolder='Respostas de Questionário'
        kind='replies'
        api='/v1/packages?type=with_replies'
        search
        label
        fields={{
          number: 'Número',
          description: 'Descrição',
          owner: 'Email',
          owner_name: 'Cliente',
          completed: 'Completa',
        }}
        renderBodyRow={packageRenderBodyRow}
        options={[
          { key: 'number', value: 'number', text: 'Número' },
          { key: 'description', value: 'description', text: 'Descrição' },
          { key: 'owner', value: 'owner', text: 'Email' },
          { key: 'owner_name', value: 'owner_name', text: 'Cliente' },
        ]}
        {...this.props}
      />
    );
  }
}

export default Replies;

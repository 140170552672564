import React from 'react';
import ListItems from '../../../Components/Private/ListItems';

// eslint-disable-next-line react/prefer-stateless-function
class Users extends React.Component {
  render() {
    return (
      <ListItems
        kind='users'
        placeHolder='Utilizadores'
        api='/v1/users'
        add // {(localStorage.getItem('usertype') === 'admin')}
        fields={{
          email: 'Email',
          level: 'Tipo',
        }}
        {...this.props}
      />
    );
  }
}

export default Users;

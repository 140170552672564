import React from 'react';
import {
  Button, Container, Form, Grid, Message, Segment,
} from 'semantic-ui-react';
import {
  handleChange, handleError, pipe, setSuccessTimeout,
} from '../../../Helpers';

class NewQuestion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      description: '',
      order: '',
      type: '',
      loading: false,
      success: false,
      error: {
        visible: false,
        message: '',
        extra: [],
      },
    };

    this.setSuccessTimeout = setSuccessTimeout.bind(this);
    this.handleChange = handleChange.bind(this);
    this.handleError = handleError.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
  }

  componentDidMount() {
    document.title = 'ThePipeTracker - Adicionar Questão';
  }

  handleCreate() {
    this.setState({ loading: true });
    const { order, description, type } = this.state;

    if (this.verifyContent() && !isNaN(parseFloat(order))) {
      pipe(
        'post',
        '/v1/questions',
        this.handleSuccess,
        this.handleError,
        {
          question: {
            description,
            order,
            type,
          },
        },
        null,
      );
    } else {
      const error = { visible: true, message: 'Por favor preencha todos os campos' };
      this.setState({ error, loading: false });
    }
  }

  handleSuccess() {
    this.setState({
      description: '',
      order: '',
      type: '',
      success: true,
      error: { visible: false, message: '' },
      loading: false,
    });
    this.setSuccessTimeout();
  }

  verifyContent() {
    const { order, description, type } = this.state;
    return description !== '' && order !== '' && type !== '';
  }

  render() {
    const options = [
      { key: 'rating', text: 'Rating', value: 'rating' },
      { key: 'text', text: 'Texto', value: 'text' },
    ];
    const {
      order, description, type, loading, error, success,
    } = this.state;
    return (
      <>
        <Container className='privateContainer'>
          <Segment color={process.env.REACT_APP_PRIMARY_COLOR} loading={loading}>
            <Grid columns='equal' stackable>
              <Grid.Column>
                <h3>Adicionar Nova Questão</h3>
              </Grid.Column>
            </Grid>
            <p />
            <Form>
              <Form.Input
                required
                autoComplete='off'
                label='Descrição'
                name='description'
                maxLength='250'
                value={description}
                onChange={this.handleChange}
              />
              <Form.Input
                required
                autoComplete='off'
                label='Ordem no Questionário'
                name='order'
                max='2147483647'
                min='-2147483648'
                type='number'
                value={order}
                onChange={this.handleChange}
              />
              <Form.Dropdown
                required
                label='Tipo'
                name='type'
                fluid
                selection
                onChange={(e, item) => this.handleChange(item)}
                value={type}
                options={options}
              />
            </Form>
            <p />
            <Message
              visible={!success}
              hidden={!success}
              success
              header='Questão Criada'
              content='Nova Questão criada com sucesso'
            />
            <Message
              hidden={!error.visible}
              negative
              header='Criação de Questão falhou'
              content={error.message}
              list={error.extra}
            />
            <Button
              icon='add'
              labelPosition='left'
              color={process.env.REACT_APP_PRIMARY_COLOR}
              size='large'
              onClick={this.handleCreate}
              content='Adicionar'
            />
          </Segment>
        </Container>
      </>
    );
  }
}

export default NewQuestion;

import React, { Component } from 'react';
import {
  Button, Container, Message, Segment,
} from 'semantic-ui-react';
import has from 'has';
import { handleChange, handleError, pipe } from '../../Helpers';

class ConfirmPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: {
        visible: false,
        message: '',
        extra: [],
      },
      loading: true,
    };

    this.handleError = handleError.bind(this);
    this.handleChange = handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  async componentDidMount() {
    document.title = 'ThePipeTracker - Confirmar Ação';

    this.setState({ loading: false });
  }

  handleClose() {
    const { props } = this;
    const { history, backTo } = props;
    if (has(props, 'backTo')) {
      history.replace(backTo);
    } else history.goBack();
  }

  handleConfirm() {
    this.setState({ loading: true });
    const {
      kind, method, api,
    } = this.props;
    let data = null;

    if (kind === 'terminate') {
      data = {
        package: {
          status: 'completed',
        },
      };
    }

    pipe(
      method,
      api,
      this.handleClose,
      this.handleError,
      data,
      null,
    );
  }

  render() {
    const {
      loading, error,
    } = this.state;
    const { kind, placeHolder, match } = this.props;
    const { complete } = match.params;

    return (
      <>
        <Container>
          <Segment
            attached
            loading={loading}
            color={process.env.REACT_APP_PRIMARY_COLOR}
          >
            <h5>
              Deseja confirmar
              {' '}
              {placeHolder}
              ?
            </h5>
            {kind === 'terminate'
              ? <p>A encomenda será marcada como concluída e o cliente receberá o email.</p>
              : <p>Podem haver estados abertos que serão eliminados.</p>}
            <Button
              onClick={this.handleClose}
              basic
              color={process.env.REACT_APP_PRIMARY_COLOR}
              labelPosition='left'
              icon='minus'
              content='Não'
            />
            <Button
              onClick={complete === 'complete-t' || kind === 'terminate' ? this.handleTerminate : this.handleConfirm}
              color={process.env.REACT_APP_PRIMARY_COLOR}
              labelPosition='left'
              icon='checkmark'
              content='Sim'
            />
            <Message
              negative
              hidden={!error.visible}
              header='Erro'
              content={error.message}
              list={error.extra}
            />
          </Segment>
        </Container>
      </>
    );
  }
}

export default ConfirmPage;

import React from 'react';
import { Container, Image } from 'semantic-ui-react';
import error from '../../resources/error500.png';
import '../CSS/Error.css';

function Error5xx() {
  return (
    <>
      <Container className='tableContainer'>
        <Image className='image5xx' src={error} />
      </Container>
    </>
  );
}

export default Error5xx;

import React from 'react';
import { ArcSeries, FlexibleXYPlot, LabelSeries } from 'react-vis';

// eslint-disable-next-line react/prefer-stateless-function
class DeliveryOverall extends React.Component {
  render() {
    const { numPackages } = this.props;
    const { percentage, loading } = numPackages;

    return (
      <FlexibleXYPlot
        xDomain={[-5, 5]}
        yDomain={[-5, 5]}
        height={300}
        animation
      >
        <ArcSeries
          radiusDomain={[0, 4]}
          colorRange={['#ff3300', '#007399']}
          padAngle={0.1}
          data={[
            {
              color: 2,
              radius0: 2,
              radius: 4.2,
              angle: percentage * Math.PI * 2,
              angle0: 0,
            },
            {
              color: 1,
              radius0: 2,
              radius: 4.2,
              angle: (1 - percentage) * Math.PI * 2
                + percentage * Math.PI * 2,
              angle0: percentage * Math.PI * 2,
            },
          ]}
          colorType='category'
          opacity={loading ? 0.2 : 1}
        />
        <LabelSeries
          animation
          labelAnchorX='middle'
          labelAnchorY='middle'
          color='#007399'
          data={[{
            x: 0,
            y: 0,
            label: `${Math.floor(percentage * 100).toString()}%`,
            style: {
              fontSize: '2.5em',
              fontWeight: 'bold',
              opacity: loading ? 0.2 : 1,
            },
          }]}
        />
      </FlexibleXYPlot>
    );
  }
}

export default DeliveryOverall;

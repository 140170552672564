import React from 'react';
import {
  Button, Container, Form, List, Message, Segment,
} from 'semantic-ui-react';
import {
  handleChange, handleError, pipe, setSuccessTimeout,
} from '../../Helpers';

class About extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      description: '',
      loading: false,
      error: {
        visible: false,
        message: '',
        extra: [],
      },
      success: false,
    };

    this.setSuccessTimeout = setSuccessTimeout.bind(this);
    this.handleError = handleError.bind(this);
    this.handleChange = handleChange.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.handleContact = this.handleContact.bind(this);
  }

  componentDidMount() {
    document.title = 'ThePipeTracker - Sobre';
  }

  handleSend() {
    this.setState({ loading: true });
    const { email, description } = this.state;
    if (email !== '' && description !== '') {
      pipe(
        'post',
        '/v1/public/contact',
        this.handleContact,
        this.handleError,
        {
          contact: {
            email,
            description,
          },
        },
        null,
      );
    } else {
      this.setState({ loading: false, error: { visible: true, message: 'Por favor preencha todos os campos' } });
      window.setTimeout(() => {
        this.setState({
          error: { visible: false, message: '' },
        });
      }, 5000);
    }
  }

  handleContact() {
    this.setState({
      description: '',
      email: '',
      success: true,
      loading: false,
      error: { visible: false, message: '' },
    });
    this.setSuccessTimeout();
  }

  render() {
    const {
      loading, email, description, success, error,
    } = this.state;
    return (
      <>
        <Container className='publicContainer'>
          <Segment color={process.env.REACT_APP_PRIMARY_COLOR}>
            <h2>ThePipeTracker - Tracking de Encomendas</h2>
            <p>Está interessado em algo semelhante para a sua empresa?</p>
            <List bulleted>
              <List.Item>Registe e gira encomendas de forma simples e rápida.</List.Item>
              <List.Item>Envio de emails automático com registo/conclusão de encomenda.</List.Item>
              <List.Item>Gestão do pipeline (linha de montagem) da sua empresa.</List.Item>
              <List.Item>
                Observe estatísticas interessantes sobre o seu pipeline, encomendas e satisfação
                dos clientes.
              </List.Item>
            </List>
            <p>
              Descubra mais em
              {' '}
              <a href='https://www.thepipetracker.com'>thepipetracker.com</a>
            </p>
            <p>Para outras questões não hesite também em contactar-nos.</p>
            <h5>Entre agora em contacto</h5>
            <Segment color={process.env.REACT_APP_SECONDARY_COLOR} loading={loading}>
              <Form>
                <Form.Input
                  required
                  autoComplete='off'
                  label='Email de Contacto'
                  name='email'
                  type='email'
                  value={email}
                  onChange={this.handleChange}
                />
                <Form.TextArea
                  required
                  autoComplete='off'
                  label='Conteúdo da Mensagem'
                  name='description'
                  value={description}
                  onChange={this.handleChange}
                />
              </Form>
              <p />
              <Message
                hidden={!success}
                success
                header='Obrigado pelo contacto'
                content='Mensagem enviada com sucesso'
              />
              <Message
                hidden={!error.visible}
                negative
                header='Oops, algo correu mal'
                content={error.message}
                list={error.extra}
              />
              <Button
                icon='send'
                labelPosition='left'
                color={process.env.REACT_APP_PRIMARY_COLOR}
                size='large'
                onClick={this.handleSend}
                content='Enviar'
              />
            </Segment>
          </Segment>

          <Segment color={process.env.REACT_APP_PRIMARY_COLOR}>
            <h3>Créditos</h3>
            <a href='https://www.freepik.com/free-photos-vectors/design'>
              Error Pages
            </a>
            {' '}
            - Design vector created by freepik - www.freepik.com
            <br />
            <a href='https://www.freepik.com/free-photos-vectors/logo'>
              Package on Logo
            </a>
            {' '}
            - Logo vector created by freepik - www.freepik.com
            <br />
            <a href='https://www.freepik.com/free-photos-vectors/calendar'>
              No State Package
            </a>
            {' '}
            - Calendar vector created by freepik - www.freepik.com
          </Segment>
        </Container>
      </>
    );
  }
}

export default About;

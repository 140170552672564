import React from 'react';
import {
  Button, Container, Form, Grid, Message, Segment,
} from 'semantic-ui-react';
import has from 'has';
import { handleChange, handleError, pipe } from '../../../Helpers';
import Error404 from '../../Errors/Error404';

class EditPlace extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      status: '',
      order: '',
      servers: '',
      debit: '',
      error: {
        visible: false,
        status: '',
        message: '',
        extra: [],
      },
      loading: true,
    };

    this.handleChange = handleChange.bind(this);
    this.handleError = handleError.bind(this);
    this.handleConfirmChangesClick = this.handleConfirmChangesClick.bind(this);
    this.handlePlace = this.handlePlace.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }

  componentDidMount() {
    document.title = 'ThePipeTracker - Editar Local';
    const { match } = this.props;
    const { id } = match.params;
    pipe(
      'get',
      `/v1/places/${id}`,
      this.handlePlace,
      this.handleError,
    );
  }

  handlePlace(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      this.setState({
        name: res.data.data.attributes.name || 'No name specified',
        status: res.data.data.attributes.status || '',
        order: res.data.data.attributes.order || '',
        servers: res.data.data.attributes.number_servers || '',
        debit: res.data.data.attributes.debit_per_hour || '',
        loading: false,
      });
    }
  }

  handleConfirmChangesClick() {
    this.setState({ loading: true });
    const { match } = this.props;
    const { id } = match.params;
    const {
      order, status, servers, debit,
    } = this.state;

    if (this.verifyContent()
      && !isNaN(parseFloat(order))
      && !isNaN(parseFloat(servers))) {
      pipe(
        'patch',
        `/v1/places/${id}`,
        this.handleSuccess,
        this.handleError,
        {
          place: {
            status,
            order,
            number_servers: servers,
            debit_per_hour: debit,
          },
        },
      );
    } else {
      const error = { visible: true, message: 'Por favor preencha todos os campos' };
      this.setState({ error, loading: false });
    }
  }

  handleSuccess() {
    const { history } = this.props;
    history.push('/places');
  }

  verifyContent() {
    const { order, servers } = this.state;
    return order !== ''
      && servers !== '';
  }

  render() {
    const options = [
      { key: 'a', text: 'Active', value: 'active' },
      { key: 'i', text: 'Inactive', value: 'inactive' },
    ];
    const {
      error, loading, status, order, name,
      servers, debit,
    } = this.state;

    return (
      <>
        <Container className='privateContainer'>
          {error.status === 404
            ? <Error404 />
            : (
              <Segment color={process.env.REACT_APP_PRIMARY_COLOR} loading={loading}>
                <h3>Editar Local</h3>
                <Form>
                  <Form.Input
                    disabled
                    label='Name'
                    name='name'
                    maxLength='50'
                    value={name}
                  />
                  <Grid columns='equal' stackable>
                    <Grid.Row>
                      <Grid.Column>
                        <Form.Input
                          required
                          autoComplete='off'
                          label='Ordem no Pipeline'
                          name='order'
                          max='2147483647'
                          min='-2147483648'
                          type='number'
                          value={order}
                          onChange={this.handleChange}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Form.Input
                          required
                          autoComplete='off'
                          label='Servidores'
                          name='servers'
                          max='2147483647'
                          min='-2147483648'
                          type='number'
                          value={servers}
                          onChange={this.handleChange}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Form.Input
                          autoComplete='off'
                          label='Débito (Unid./h)'
                          name='debit'
                          max='2147483647'
                          min='-2147483648'
                          type='number'
                          value={debit}
                          onChange={this.handleChange}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <p />
                  <Form.Dropdown
                    required
                    label='Status'
                    name='status'
                    fluid
                    selection
                    onChange={(e, item) => this.handleChange(item)}
                    value={status}
                    options={options}
                  />
                  <Message
                    hidden={!error.visible}
                    negative
                    header='Edição de Local Falhou'
                    content={error.message}
                    list={error.extra}
                  />
                  <Button
                    icon='edit'
                    labelPosition='left'
                    color={process.env.REACT_APP_PRIMARY_COLOR}
                    size='large'
                    onClick={this.handleConfirmChangesClick}
                    content='Confirmar'
                  />
                </Form>
              </Segment>
            )}
        </Container>
      </>
    );
  }
}

export default EditPlace;

import React, { Component } from 'react';
import {
  Button, Form, Grid, Icon, Label, Message, Modal, Segment,
} from 'semantic-ui-react';
import has from 'has';
import { handleChange, handleError, pipe } from '../../../Helpers';

class ConfirmModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      input: '',
      fullPackage: {
        attributes: {
          number: null,
          description: null,
        },
      },
      fullPlace: {
        attributes: {
          name: null,
        },
      },
      error: {
        visible: false,
        message: '',
        extra: [],
      },
    };

    this.handleError = handleError.bind(this);
    this.handleChange = handleChange.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handlePackage = this.handlePackage.bind(this);
    this.handlePlace = this.handlePlace.bind(this);
  }

  componentDidMount() {
    const { info, onLoading } = this.props;
    const { pack, place, kind } = info;

    if (kind !== 'delete-package') {
      this.handleInfo(pack, place);
    } else {
      onLoading(false);
    }
  }

  async handleInfo(pack, place) {
    await pipe(
      'get',
      `/v1/packages/${pack}?type=number`,
      this.handlePackage,
      this.handleError,
    );

    await pipe(
      'get',
      `/v1/places/${place}`,
      this.handlePlace,
      this.handleError,
    );
  }

  handlePackage(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      this.setState({ fullPackage: res.data.data });
    }
  }

  handlePlace(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const { onLoading } = this.props;
      this.setState({ fullPlace: res.data.data });
      onLoading(false);
    }
  }

  handleConfirm() {
    const { onLoading } = this.props;
    onLoading(true);
    const { info, channel, onRequest } = this.props;
    const {
      kind, place, state, pause,
    } = info;
    const { input, fullPackage } = this.state;
    let data = null;

    if (kind === 'new_state') {
      data = {
        state: {
          package_id: fullPackage.id,
          place_id: place,
        },
      };
    } else if (kind === 'delete_state') {
      data = {
        state_id: state,
      };
    } else if (kind === 'new_pause') {
      data = {
        pause: {
          state_id: state,
          description: input,
        },
      };
    } else if (kind === 'resume_pause') {
      data = {
        pause_id: pause,
        pause: {
          ended: true,
        },
      };
    } else if (kind === 'complete_state') {
      data = {
        state_id: state,
        state: {
          ended: true,
        },
      };
    } else if (kind === 'complete_package') {
      data = {
        state: {
          id: state,
          ended: true,
        },
        package: {
          id: fullPackage.id,
          status: 'completed',
        },
      };
    }
    onRequest(kind);
    channel.push(kind, data);
  }

  getIcon() {
    const { info } = this.props;
    const { kind } = info;
    let icon;
    switch (kind) {
      case 'new_state':
        icon = 'add';
        break;
      case 'delete_state':
        icon = 'trash alternate';
        break;
      case 'new_pause':
        icon = 'pause';
        break;
      case 'resume_pause':
        icon = 'play';
        break;
      case 'complete_state':
        icon = 'flag checkered';
        break;
      case 'complete_package':
        icon = 'shipping fast';
        break;
      default:
        icon = '';
        break;
    }
    return icon;
  }

  render() {
    const {
      fullPlace, fullPackage, input, error,
    } = this.state;
    const { onClose, info, errorChannel } = this.props;
    const {
      kind, placeHolder, open,
    } = info;
    const icon = this.getIcon();

    return (
      <Modal size='small' open={open} onClose={onClose} closeOnDimmerClick={false}>
        <Modal.Header>
          Deseja confirmar
          {' '}
          {placeHolder}
          ?
        </Modal.Header>
        <Modal.Content>
          <Segment basic loading={info.loading}>
            <Grid>
              <Grid.Column width={4} textAlign='center' verticalAlign='middle'>
                <Icon name={icon} size='huge' />
              </Grid.Column>
              <Grid.Column width={12}>
                <Label size='large' basic color='black' content={fullPlace.attributes.name || 'Inválido'} detail={fullPackage.attributes.number || 'Inválido'} />
                <p />
                <b>
                  {fullPackage.attributes.owner_name || 'Inválido'}
                  :
                </b>
                {' '}
                {fullPackage.attributes.description || 'Inválido'}
                <p />
                {kind === 'new_pause'
                  ? (
                    <Form>
                      <Form.Input
                        fluid
                        autoComplete='off'
                        placeholder='Razão da Pausa (opcional)'
                        name='input'
                        value={input}
                        onChange={this.handleChange}
                      />
                      <p />
                    </Form>
                  )
                  : ''}
                <p />
              </Grid.Column>
            </Grid>

            <Message
              negative
              hidden={!error.visible}
              header='Erro'
              content={error.message}
              list={error.extra}
            />
            <Message
              negative
              hidden={!errorChannel.visible}
              header='Erro'
              content={errorChannel.message}
              list={errorChannel.extra}
            />

          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={onClose}
            basic
            color={process.env.REACT_APP_PRIMARY_COLOR}
            labelPosition='left'
            icon='minus'
            content='Não'
          />
          <Button
            disabled={error.visible}
            onClick={this.handleConfirm}
            color={process.env.REACT_APP_PRIMARY_COLOR}
            labelPosition='left'
            icon='checkmark'
            content='Sim'
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ConfirmModal;

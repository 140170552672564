import React from 'react';
import {
  FlexibleXYPlot, Hint, HorizontalGridLines, VerticalBarSeries, XAxis, YAxis,
} from 'react-vis';

class MeanTimePlace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meanTimePlaceValue: null,
    };
  }

  render() {
    const MARGIN_NUMBER = {
      left: 50,
      right: 30,
      bottom: 70,
      top: 5,
    };
    const { meanTimePlace } = this.props;
    const { meanTimePlaceValue } = this.state;
    const { domain, data, loading } = meanTimePlace;

    return (
      <FlexibleXYPlot
        xType='ordinal'
        yDomain={domain}
        margin={MARGIN_NUMBER}
        height={300}
        animation
      >
        <HorizontalGridLines />
        <XAxis tickLabelAngle={-45} />
        <YAxis tickFormat={(v) => `${v}h`} />
        <VerticalBarSeries
          onValueMouseOver={(v) => this.setState({ meanTimePlaceValue: v })}
          onValueMouseOut={() => this.setState({ meanTimePlaceValue: null })}
          color='#007399'
          data={data}
          opacity={loading ? 0.2 : 1}
        />
        {meanTimePlaceValue ? <Hint value={meanTimePlaceValue} /> : null}
      </FlexibleXYPlot>
    );
  }
}

export default MeanTimePlace;

import React from 'react';
import {
  FlexibleXYPlot, HorizontalGridLines, LineSeries, XAxis, YAxis,
} from 'react-vis';

// eslint-disable-next-line react/prefer-stateless-function
class RatingsEvolution extends React.Component {
  render() {
    const MARGIN_NUMBER = {
      left: 50,
      right: 30,
      bottom: 70,
      top: 5,
    };
    const { ratingsEvolution, maxLength } = this.props;

    return (
      <FlexibleXYPlot
        margin={MARGIN_NUMBER}
        height={300}
        yDomain={[1, 5]}
        animation
      >
        <HorizontalGridLines />
        <XAxis
          tickFormat={(v) => [...Array(maxLength + 1).keys()][v]}
        />
        <YAxis />
        {ratingsEvolution.data.map((data) => (
          <LineSeries
            key={data.id}
            curve='curveMonotoneX'
            data={data.evo}
            opacity={ratingsEvolution.loading ? 0.2 : 1}
            style={{
              strokeWidth: 2,
            }}
          />
        ))}
      </FlexibleXYPlot>
    );
  }
}

export default RatingsEvolution;

import React from 'react';
import {
  Button, Checkbox, Container, Form, Grid, Icon, Message, Segment, Statistic,
} from 'semantic-ui-react';
import moment from 'moment';
import has from 'has';
import DatePicker from 'react-datepicker';
import { handleChange, handleError, pipe } from '../../../Helpers';
import ListStates from '../../../Components/Private/ListStates';
import Error404 from '../../Errors/Error404';
import SavePathModal from '../../../Components/Private/Packages/SavePathModal';
import ListPathsModal from '../../../Components/Private/Packages/ListPathsModal';

class EditPackage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      number: '',
      description: '',
      quantity: '',
      path: [],
      pathDescription: [],
      listPlaces: [],
      owner: '',
      ownerOriginal: '',
      ownerName: '',
      status: '',
      completed: new Date(),
      deliveryDate: null,
      prediction: null,
      states: [],
      checked: false,
      saveModal: false,
      listModal: false,
      listPaths: [],
      listDescriptions: [],
      error: {
        visible: false,
        status: '',
        message: '',
        extra: [],
      },
      loading: true,
      success: false,
    };

    this.handleChange = handleChange.bind(this);
    this.handleError = handleError.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handlePackage = this.handlePackage.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleTerminate = this.handleTerminate.bind(this);
    this.handleReDo = this.handleReDo.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangePath = this.handleChangePath.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handlePlaces = this.handlePlaces.bind(this);
    this.handleSetPath = this.handleSetPath.bind(this);
    this.handleUpdatePathList = this.handleUpdatePathList.bind(this);
    this.handleListPaths = this.handleListPaths.bind(this);
  }

  async componentDidMount() {
    document.title = 'ThePipeTracker - Editar Encomenda';
    const { match } = this.props;
    const { id } = match.params;

    await pipe(
      'get',
      '/v1/places?status=active',
      this.handlePlaces,
      this.handleError,
      null,
      null,
    );

    await pipe(
      'get',
      `/v1/packages/${id}`,
      this.handlePackage,
      this.handleError,
      null,
      null,
    );
  }

  handlePlaces(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const listPlaces = res.data.data.map((place) => ({
        text: place.name,
        value: place.id,
        key: place.id,
      }));

      this.setState({ listPlaces });
    }
  }

  handlePackage(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const { attributes } = res.data.data;
      this.setState({
        number: attributes.number || 'No number specified',
        description: attributes.description || 'No description specified',
        quantity: attributes.quantity || '',
        path: this.buildPath(attributes.path),
        owner: attributes.owner || 'No owner specified',
        ownerOriginal: attributes.owner || 'No owner specified',
        ownerName: attributes.owner_name || 'No owner specified',
        deliveryDate: attributes.delivery_date
          ? new Date(attributes.delivery_date)
          : null,
        status: attributes.status || 'No status specified',
        completed: attributes.completed || new Date(),
        states: res.data.data.states || [],
        prediction: attributes.prediction !== null ? attributes.prediction.attributes : null,
        loading: false,
      });
    }
  }

  handleEdit() {
    this.setState({ loading: true });
    const { match } = this.props;
    const { id } = match.params;
    const {
      number, description, owner, ownerName,
      deliveryDate, checked, quantity, path,
    } = this.state;

    if (this.verifyContent() && !isNaN(parseInt(number, 10))) {
      pipe(
        'patch',
        `/v1/packages/${id}?resend=${checked}`,
        this.handleSuccess,
        this.handleError,
        {
          package: {
            number,
            description,
            owner,
            quantity,
            path: path.toString(),
            owner_name: ownerName,
            delivery_date: moment(deliveryDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          },
        },
        null,
      );
    } else if (isNaN(parseInt(number, 10))) {
      const error = { visible: true, message: 'Número de Encomenda Inválido' };
      this.setState({ error, loading: false });
    } else {
      const error = { visible: true, message: 'Por favor preencha todos os campos' };
      this.setState({ error, loading: false });
    }
  }

  handleDelete() {
    const { history, match } = this.props;
    const { id } = match.params;
    history.push(`/packages/${id}/delete`);
  }

  handleTerminate() {
    this.setState({ loading: true });
    const { match } = this.props;
    const { id } = match.params;
    pipe(
      'patch',
      `/v1/packages/${id}`,
      this.handleSuccess,
      this.handleError,
      {
        package: {
          status: 'completed',
        },
      },
      null,
    );
  }

  handleReDo() {
    this.setState({ loading: true });
    const { match } = this.props;
    const { id } = match.params;
    pipe(
      'patch',
      `/v1/packages/${id}`,
      this.handleSuccess,
      this.handleError,
      {
        package: {
          status: 'started',
        },
      },
      null,
    );
  }

  handleSuccess() {
    const { history } = this.props;
    const urlString = window.location.href;
    const url = new URL(urlString);
    if (url.searchParams !== undefined) {
      if (url.searchParams.get('redirect') !== null && url.searchParams.get('redirect') === 'calendar') {
        history.push(`/calendar?firstDay=${url.searchParams.get('firstDay') || moment().format('YYYY-MM-DD')}`);
        return;
      }
    }
    history.push(`/packages${url.search}`);
  }

  handleToggle() {
    const { checked } = this.state;
    this.setState({ checked: !checked });
  }

  handleEmailChange(e) {
    const { ownerOriginal } = this.state;
    const el = (e.target ? e.target : e);
    if (el.value === ownerOriginal) this.setState({ checked: false });
    this.setState({ [el.name]: el.value });
  }

  handleChangeDate(e) {
    this.setState({ deliveryDate: e });
  }

  handleChangePath(item) {
    const { listPlaces } = this.state;
    const path = item.value;
    const pathDescription = item.value.map((value) => (
      listPlaces.find((element) => element.value === value)
    ));
    this.setState({ path, pathDescription });
  }

  handleModal(name, status) {
    this.setState({ [name]: status });
  }

  handleUpdatePathList() {
    pipe(
      'get',
      '/v1/paths',
      this.handleListPaths,
      this.handleError,
      null,
      null,
    );
  }

  handleListPaths(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const listDescriptions = res.data.data.map((path) => path.attributes.description);
      const listPaths = res.data.data;
      this.setState({ listPaths, listDescriptions });
    }
  }

  handleSetPath(path) {
    const item = {};
    item.value = path.split(',').map((place) => parseInt(place, 10));
    this.handleChangePath(item);
  }

  verifyContent() {
    const {
      id, number, description, owner, ownerName, deliveryDate,
    } = this.state;
    return id !== '' && number !== ''
      && description !== '' && owner !== ''
      && ownerName !== '' && deliveryDate !== null;
  }

  buildPath(path) {
    const { listPlaces } = this.state;
    const newPath = [];
    if (path !== null && path !== '') {
      path.split(',').forEach((place) => {
        const pl = parseInt(place, 10);
        if (!isNaN(pl)) {
          newPath.push(pl);
        }
      });
      const pathDescription = newPath.map((value) => (
        listPlaces.find((element) => element.value === value)
      ));
      this.setState({ pathDescription });
    }
    return newPath;
  }

  render() {
    const {
      error, status, number, description, deliveryDate, ownerName, completed,
      quantity, owner, ownerOriginal, checked, success, loading, states,
      listPlaces, path, pathDescription, saveModal, listModal, listPaths, listDescriptions,
      prediction,
    } = this.state;
    return (
      <>
        <Container className='privateContainer'>
          <SavePathModal
            path={pathDescription}
            open={saveModal}
            handleModal={this.handleModal}
            updatePathsList={this.handleUpdatePathList}
          />
          <ListPathsModal
            open={listModal}
            listPaths={listPaths}
            listDescriptions={listDescriptions}
            handleModal={this.handleModal}
            setPath={this.handleSetPath}
            updatePathsList={this.handleUpdatePathList}
          />
          {error.status === 404
            ? <Error404 />
            : (
              <Segment color={process.env.REACT_APP_PRIMARY_COLOR} loading={loading}>
                <Grid columns='equal'>
                  <Grid.Column>
                    <h3>Editar Encomenda</h3>
                  </Grid.Column>
                  <Grid.Column textAlign='right'>
                    <Button
                      basic
                      icon='trash alternate'
                      labelPosition='left'
                      color={process.env.REACT_APP_PRIMARY_COLOR}
                      onClick={this.handleDelete}
                      content='Eliminar'
                    />
                    {status === 'started'
                      ? (
                        <Button
                          icon='shipping fast'
                          labelPosition='left'
                          color={process.env.REACT_APP_PRIMARY_COLOR}
                          onClick={this.handleTerminate}
                          content='Terminar'
                        />
                      )
                      : (
                        <Button
                          icon='undo alternate'
                          labelPosition='left'
                          color={process.env.REACT_APP_PRIMARY_COLOR}
                          onClick={this.handleReDo}
                          content='Refazer'
                        />
                      )}
                  </Grid.Column>
                </Grid>
                <Form>
                  <Form.Input
                    disabled={status === 'completed'}
                    required
                    autoComplete='off'
                    label='Número'
                    name='number'
                    max='9223372036854775807'
                    min='-9223372036854775808'
                    type='number'
                    value={number}
                    onChange={this.handleChange}
                  />
                  <Form.TextArea
                    disabled={status === 'completed'}
                    required
                    autoComplete='off'
                    label='Descrição'
                    maxLength='250'
                    name='description'
                    value={description}
                    onChange={this.handleChange}
                  />
                  <Form.Input
                    disabled={status === 'completed'}
                    autoComplete='off'
                    label='Quantidade'
                    name='quantity'
                    max='9223372036854775807'
                    min='-9223372036854775808'
                    type='Quantity'
                    value={quantity}
                    onChange={this.handleChange}
                  />
                  <Form.Dropdown
                    renderLabel={(text, index) => `${index + 1}. ${text.text}`}
                    disabled={status === 'completed'}
                    label='Caminho de Produção'
                    name='path'
                    fluid
                    multiple
                    selection
                    options={listPlaces}
                    onChange={(e, item) => this.handleChangePath(item)}
                    value={path}
                  />
                  <Button.Group floated='right' icon color={process.env.REACT_APP_PRIMARY_COLOR}>
                    <Button
                      disabled={status === 'completed'}
                      icon='save'
                      onClick={() => this.handleModal('saveModal', true)}
                    />
                    <Button
                      disabled={status === 'completed'}
                      icon='cloud upload'
                      onClick={() => this.handleModal('listModal', true)}
                    />
                  </Button.Group>
                  <Grid>
                    <Grid.Column width={5}>
                      <p>
                        Data de Entrega
                        {' '}
                        <span style={{ color: 'red' }}>*</span>
                      </p>
                      <DatePicker
                        disabled={status === 'completed'}
                        className='react-datepicker-custom'
                        selected={deliveryDate}
                        onChange={(date) => this.handleChangeDate(date)}
                        dateFormat='dd/MM/yyyy'
                      />
                      {new Date(deliveryDate) < new Date()
                      && status !== 'completed'
                        ? (
                          <Icon
                            color='red'
                            name='warning sign'
                            size='big'
                            style={{
                              paddingTop: '10px',
                              paddingLeft: '10px',
                            }}
                          />
                        )
                        : ''}
                      <p />
                    </Grid.Column>
                    <Grid.Column width={5} verticalAlign='bottom'>
                      {prediction !== null
                        ? (
                          <Statistic size='tiny'>
                            <Statistic.Value>
                              {moment(prediction.delivery).format('DD/MM/YY, HH:mm')}
                              {' '}
                              <Icon size='small' name={new Date(prediction.delivery) > new Date(deliveryDate) ? 'warning sign' : ''} />
                            </Statistic.Value>
                            <Statistic.Label>
                              {`Previsão (${moment(prediction.last_updated).fromNow()})`}
                            </Statistic.Label>
                          </Statistic>
                        )
                        : <Statistic size='tiny' value='Encomenda' label='Sem Previsão' />}
                    </Grid.Column>
                  </Grid>
                  <Segment color={process.env.REACT_APP_SECONDARY_COLOR}>
                    <Grid columns='equal'>
                      <Grid.Column>
                        <Form.Input
                          disabled={status === 'completed'}
                          required
                          label='Nome Cliente'
                          name='ownerName'
                          maxLength='50'
                          value={ownerName}
                          onChange={this.handleChange}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Form.Input
                          disabled={status === 'completed'}
                          required
                          label='Email Cliente'
                          name='owner'
                          type='email'
                          maxLength='50'
                          value={owner}
                          onChange={this.handleEmailChange}
                        />
                      </Grid.Column>
                    </Grid>
                    <p />
                    <Checkbox
                      label='Reenviar Email'
                      disabled={owner === ownerOriginal}
                      onChange={this.handleToggle}
                      checked={checked}
                    />
                  </Segment>
                </Form>
                <p />
                <Message
                  hidden={!success}
                  success
                  header='Encomenda editada'
                  content='Encomenda alterada com sucesso'
                />
                <Message
                  hidden={!error.visible}
                  negative
                  header='Atualização de Encomenda falhou'
                  content={error.message}
                  list={error.extra}
                />
                <Button
                  icon='edit'
                  labelPosition='left'
                  disabled={status === 'completed'}
                  color={process.env.REACT_APP_PRIMARY_COLOR}
                  size='large'
                  onClick={this.handleEdit}
                  content='Confirmar'
                />
              </Segment>
            )}
          {!loading && error.status !== 404
            ? (
              <ListStates
                items={states}
                color={process.env.REACT_APP_PRIMARY_COLOR}
                status={status}
                completed={completed}
                {...this.props}
              />
            )
            : ''}
        </Container>
      </>
    );
  }
}

export default EditPackage;

import React, { Component } from 'react';
import {
  Grid, Image, List, Segment,
} from 'semantic-ui-react';
import '../CSS/MyFooter.css';
import { Link } from 'react-router-dom';

class MyFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange(event) {
    const el = event.target;
    this.setState({ width: el.window.innerWidth });
  }

  render() {
    const { value } = this.props;
    const { width } = this.state;
    const image = require(`../../resources/${process.env.REACT_APP_COMPANY_SHORT}.png`);
    return (
      <Segment className='footer'>
        <Grid columns='equal' verticalAlign='middle'>
          <Grid.Column textAlign='center' width={6}>
            <List bulleted horizontal>
              {value === 'other'
                ? (
                  <List.Item>
                    <Link style={{ fontSize: width > 400 ? '1em' : '0.8em' }} to='/login'>
                      Login
                    </Link>
                  </List.Item>
                ) : ''}
              <List.Item>
                <Link style={{ fontSize: width > 400 ? '1em' : '0.8em' }} to='/home'>
                  Home
                </Link>
              </List.Item>
              <List.Item>
                <Link style={{ fontSize: width > 400 ? '1em' : '0.8em' }} to='/about'>
                  Sobre
                </Link>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column textAlign='center' width={4}>
            <Image
              centered
              size='mini'
              src={image.default}
            />
          </Grid.Column>
          <Grid.Column textAlign='center' width={6}>
            <Link style={{ fontSize: width > 400 ? '1em' : '0.8em' }} to='/privacy-policy'>
              Política de Privacidade
            </Link>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

export default MyFooter;

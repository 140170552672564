import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import HttpsRedirect from 'react-https-redirect';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';
import App from './App';

// ========================================

Sentry.init({
  dsn: process.env.NODE_ENV === 'production'
    ? 'https://3748bd6e2da04425a781b329a8cdbb2d@sentry.io/1724582' : '',
});

ReactDOM.render(
  !!process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'staging'
    ? (
      <BrowserRouter>
        <App />
      </BrowserRouter>
    )
    : (
      <HttpsRedirect>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HttpsRedirect>
    ),
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import {
  Button, Container, Divider, Form, Grid, Message, Rating, Segment,
} from 'semantic-ui-react';
import has from 'has';
import { handleError, pipe } from '../../Helpers';
import Error404 from '../../Pages/Errors/Error404';

class Survey extends React.Component {
  constructor(props) {
    super(props);
    const { disabled } = props;
    this.state = {
      questions: [],
      replies: [],
      packageId: '',
      negative: false,
      disabled: !!(has(props, 'disabled') && disabled),
      error: {
        visible: false,
        status: '',
        message: '',
        extra: [],
      },
      loading: true,
    };

    this.handleText = this.handleText.bind(this);
    this.handleError = handleError.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePackage = this.handlePackage.bind(this);
    this.handleQuestions = this.handleQuestions.bind(this);
    this.handleReplies = this.handleReplies.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleRate = this.handleRate.bind(this);
  }

  async componentDidMount() {
    const {
      placeHolder, apiPackage, kind, api,
    } = this.props;

    document.title = `ThePipeTracker - ${placeHolder}`;
    if (kind === 'answer') {
      await pipe(
        'get',
        apiPackage,
        this.handlePackage,
        this.handleError,
      );
    }
    await pipe(
      'get',
      api,
      this.handleReplies,
      this.handleError,
    );
  }

  handlePackage(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      this.setState({
        packageId: res.data.data.id,
      });
    }
  }

  handleReplies(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      if (res.data.data.length !== 0) {
        const { packageId } = this.state;
        const { kind } = this.props;
        const replies = [];
        const questions = [];

        res.data.data.forEach((reply) => {
          replies.push({
            description: reply.attributes.description,
            question_id: reply.question.data.attributes.id,
            packageId,
            required: reply.question.data.attributes.type === 'rating',
          });
          questions.push(reply.question.data);
        });

        this.setState({
          disabled: (kind === 'answer' && res.data.data.length !== 0) || kind === 'view',
          replies,
          questions,
          loading: false,
        });
      } else {
        pipe(
          'get',
          '/v1/public/questions',
          this.handleQuestions,
          this.handleError,
        );
      }
    }
  }

  handleQuestions(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const { packageId } = this.state;
      const replies = [];
      res.data.data.forEach((question) => {
        replies.push({
          description: '',
          question_id: question.id,
          packageId,
          required: question.attributes.type === 'rating',
        });
      });
      this.setState({
        questions: res.data.data,
        replies,
        loading: false,
      });
    }
  }

  handleSubmit() {
    this.setState({ loading: true });

    if (this.verifyContent()) {
      pipe(
        'post',
        '/v1/public/replies',
        this.handleSuccess,
        this.handleError,
        {
          replies: this.buildReply(),
        },
      );
    } else {
      const error = { visible: true, message: 'Por favor preencha os campos obrigatórios' };
      this.setState({ error, loading: false });
    }
  }

  handleRate(rating, key) {
    const { replies } = this.state;
    const newReply = replies;
    newReply[key].description = rating.toString();
    const negative = this.isNegative();
    this.setState({ replies: newReply, negative });
  }

  handleText(e) {
    const { replies } = this.state;
    const el = (e.target ? e.target : e);
    const newReply = replies;
    newReply[el.name].description = el.value;
    this.setState({ replies: newReply });
  }

  handleSuccess() {
    const { history, match } = this.props;
    const { code } = match.params;
    history.push(`/public/my-package/${code}`);
  }

  buildReply() {
    const { replies } = this.state;
    const newReplies = [];
    replies.forEach((reply) => {
      if (reply.description !== '') {
        newReplies.push({
          description: reply.description,
          question_id: reply.question_id,
          package_id: reply.packageId,
        });
      }
    });
    return newReplies;
  }

  isNegative() {
    const { replies } = this.state;
    let flag = true;
    let acc = 0;
    let count = 0;
    replies.forEach((reply) => {
      if (reply.required && reply.description !== '') {
        acc += parseInt(reply.description, 10);
        count += 1;
      } else if (reply.required && reply.description === '') {
        flag = false;
      }
    });

    return flag && (acc / count) < 3;
  }

  verifyContent() {
    const { replies, negative } = this.state;
    let flag = true;
    replies.forEach((reply) => {
      if ((reply.required || negative) && reply.description === '') flag = false;
    });
    return flag;
  }

  render() {
    const { kind } = this.props;
    const {
      error, loading, disabled, questions, replies, negative,
    } = this.state;
    return (
      <>
        <Container className={kind === 'answer' ? 'publicContainer' : 'privateContainer'}>
          {error.status === 404
            ? <Error404 />
            : (
              <Segment color={process.env.REACT_APP_PRIMARY_COLOR} loading={loading}>
                <h3>Questionário de Satisfação</h3>
                <p />
                <Message
                  hidden={!disabled || kind === 'view'}
                  info
                  header='Obrigado Pela Resposta'
                  content='Já respondeu ao questionário sobre esta encomenda'
                />
                {questions.map((question, i) => (
                  <Form key={question.id}>
                    <Divider />
                    {question.attributes.type === 'rating'
                      ? (
                        <Grid stackable verticalAlign='middle'>
                          <Grid.Column verticalAlign='middle' width={10}>
                            <p>
                              {question.attributes.description}
                              {' '}
                              <span style={{ color: 'red' }}>*</span>
                            </p>
                          </Grid.Column>
                          <Grid.Column width={6}>
                            <Rating
                              disabled={disabled}
                              maxRating={5}
                              icon='star'
                              size='massive'
                              rating={replies[i].description}
                              onRate={(e, { rating }) => this.handleRate(rating, i)}
                            />
                          </Grid.Column>
                        </Grid>
                      )
                      : (
                        <Grid verticalAlign='middle'>
                          <Grid.Column verticalAlign='middle'>
                            <p>
                              {question.attributes.description}
                              {' '}
                              (Caracteres:
                              {' '}
                              {250 - replies[i].description.length}
                              )
                              <span hidden={!negative} style={{ color: 'red' }}> * Por favor indique o que correu mal</span>
                            </p>
                            <Form.TextArea
                              disabled={disabled}
                              name={i}
                              value={replies[i].description}
                              onChange={this.handleText}
                              maxLength='250'
                            />
                          </Grid.Column>
                        </Grid>
                      )}
                  </Form>
                ))}
                <p />
                <Message
                  hidden={!error.visible}
                  negative
                  header='Erro no Questionário'
                  content={error.message}
                  list={error.extra}
                />
                {kind === 'answer'
                  ? (
                    <Button
                      disabled={disabled}
                      icon='upload'
                      labelPosition='left'
                      color={process.env.REACT_APP_PRIMARY_COLOR}
                      size='large'
                      onClick={this.handleSubmit}
                      content='Submeter'
                    />
                  )
                  : ''}
              </Segment>
            )}
        </Container>
      </>
    );
  }
}

export default Survey;

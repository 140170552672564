import React from 'react';
import { Button, Card } from 'semantic-ui-react';
import moment from 'moment';
import '../../CSS/StateCard.css';

// eslint-disable-next-line react/prefer-stateless-function
class StateCard extends React.Component {
  calcNumMaximizedPlaces() {
    const { visible } = this.props;
    const result = visible.filter((place) => place).length;
    return result > 0 ? result : 1;
  }

  render() {
    const MOBILE_VERSION_PX = 800;
    const MAX_CARD_SIZE = 500;
    const {
      width, state, pipePlace, handleResume, handleTerminate,
      handleComplete, handlePause, handleDelete,
      calcNumMinimizedPlaces,
    } = this.props;
    const numMaximized = this.calcNumMaximizedPlaces();
    const numMinimized = calcNumMinimizedPlaces();
    const minimizedPadd = numMinimized * 48; // size of minimized pane
    const cardsPerRow = Math.floor((width - minimizedPadd) / numMaximized / MAX_CARD_SIZE);

    return (
      <Card
        color={process.env.REACT_APP_TERTIARY_COLOR}
        className={!(width <= MOBILE_VERSION_PX) ? 'cardGroup' : 'cardGroupMobile'}
        style={{ '--cards': cardsPerRow > 0 ? cardsPerRow : 1, '--min': numMinimized }}
      >
        <Card.Content>
          {state.paused
            ? (
              <Button
                size='mini'
                floated='right'
                icon='play'
                basic
                id={state.id}
                color={process.env.REACT_APP_TERTIARY_COLOR}
                onClick={() => {
                  handleResume(pipePlace.place_id,
                    state.id, state.pauseId,
                    state.package.data.attributes.number);
                }}
              />
            )
            : (
              <Button
                size='mini'
                floated='right'
                icon='shipping fast'
                basic
                id={state.id}
                color={process.env.REACT_APP_PRIMARY_COLOR}
                onClick={() => {
                  handleTerminate(pipePlace.place_id,
                    state.id, state.package.data.attributes.number);
                }}
              />
            )}
          <Card.Header>
            {state.package.data.attributes.number}
          </Card.Header>
          <Card.Meta>
            {moment(state.attributes.timestamp_start).format('DD/MM/YY, HH:mm')}
          </Card.Meta>
          <Card.Description
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: state.paused ? '5' : '3', /* number of lines to show */
              lineHeight: '20px',
              height: state.paused ? '105px' : '57px',
            }}
          >
            <b>
              {state.package.data.attributes.owner_name}
              :
              {' '}
            </b>
            {state.package.data.attributes.description}
          </Card.Description>
        </Card.Content>
        {state.paused
          ? ''
          : (
            <Card.Content extra>
              <Button.Group size='mini' fluid>
                <Button
                  id={state.id}
                  basic
                  color={process.env.REACT_APP_TERTIARY_COLOR}
                  content='Completo'
                  onClick={() => {
                    handleComplete(pipePlace.place_id, state.id,
                      state.package.data.attributes.number);
                  }}
                />
                <Button
                  id={state.id}
                  icon='pause'
                  basic
                  color={process.env.REACT_APP_TERTIARY_COLOR}
                  onClick={() => {
                    handlePause(pipePlace.place_id, state.id,
                      state.package.data.attributes.number);
                  }}
                />
                <Button
                  id={state.id}
                  icon='trash alternate'
                  color={process.env.REACT_APP_SECONDARY_COLOR}
                  onClick={() => {
                    handleDelete(pipePlace.place_id, state.id,
                      state.package.data.attributes.number);
                  }}
                />
              </Button.Group>
            </Card.Content>
          )}
      </Card>
    );
  }
}

export default StateCard;

import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { Button, Grid } from 'semantic-ui-react';
import Error5xx from './Error5xx';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorInfo: null,
      eventId: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });

    this.setState({
      errorInfo,
    });
  }

  render() {
    const { errorInfo, eventId } = this.state;
    const { children } = this.props;
    if (errorInfo) {
      return (
        <>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Error5xx />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign='center' width={16}>
                <Button
                  color={process.env.REACT_APP_PRIMARY_COLOR}
                  onClick={() => Sentry.showReportDialog({ eventId })}
                >
                  Report feedback
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      );
    }

    return children;
  }
}

export default ErrorBoundary;

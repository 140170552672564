import React from 'react';
import {
  FlexibleXYPlot, HorizontalGridLines, LineSeries, XAxis, YAxis,
} from 'react-vis';

// eslint-disable-next-line react/prefer-stateless-function
class DeliveryPercentage extends React.Component {
  render() {
    const MARGIN_NUMBER = {
      left: 50,
      right: 30,
      bottom: 70,
      top: 5,
    };
    const { numPackages } = this.props;
    const { labelX, onTime, loading } = numPackages;

    return (
      <FlexibleXYPlot
        margin={MARGIN_NUMBER}
        height={300}
        yDomain={[0, 100]}
        animation
      >
        <HorizontalGridLines />
        <XAxis
          title='Data'
          tickFormat={(v) => labelX[v]}
          tickLabelAngle={-45}
        />
        <YAxis
          title='%'
        />
        <LineSeries
          color='007399'
          curve='curveMonotoneX'
          data={onTime}
          opacity={loading ? 0.2 : 1}
          style={{
            strokeWidth: 4,
          }}
        />
      </FlexibleXYPlot>
    );
  }
}

export default DeliveryPercentage;

import React from 'react';
import {
  Grid, Label, Message, Tab,
} from 'semantic-ui-react';
import 'react-vis/dist/style.css';
import has from 'has';
import { DiscreteColorLegend } from 'react-vis';
import axios from 'axios';
import {
  handleChange, handleError, pipe,
} from '../../../../Helpers';
import NumPackagesPlace from '../Plots/NumPackagesPlace';
import EmptyChart from '../Plots/EmptyChart';
import MeanTimePlace from '../Plots/MeanTimePlace';
import NumPackagesPlaceGrouped from '../Plots/NumPackagesPlaceGrouped';
import Efficiency from '../Plots/Efficiency';

class PlaceTab extends React.Component {
  static createDataMatrix(xx, yy) {
    const matrix = [];
    for (let i = 0; i < xx.length; i += 1) {
      matrix.push([]);
      for (let j = 1; j < yy.length; j += 1) {
        matrix[i].push({ x: i, y: j, color: '0' });
      }
    }
    return matrix;
  }

  constructor(props) {
    super(props);
    this.state = {
      numPackagesPlace: {
        active: [],
        paused: [],
        domain: [],
        loading: true,
      },
      numPackagesPlaceGrouped: {
        data: [],
        labelX: [],
        labelY: [],
        loading: true,
      },
      meanTimePlace: {
        data: [],
        domain: [],
        loading: true,
      },
      efficiency: {
        utilization: [],
        performance: [],
        oee: [],
        domain: [],
        loading: true,
      },
      error: {
        visible: false,
        message: '',
        extra: [],
      },
    };
    const { CancelToken } = axios;
    this.source = CancelToken.source();
    this.handleChange = handleChange.bind(this);
    this.handleError = handleError.bind(this);
    this.handlePackagesPlace = this.handlePackagesPlace.bind(this);
    this.handleMeanTimePlace = this.handleMeanTimePlace.bind(this);
    this.handlePackagesPlaceGrouped = this.handlePackagesPlaceGrouped.bind(this);
    this.handleOEE = this.handleOEE.bind(this);
    this.handleFilterNumPackagesPlaceGrouped = this.handleFilterNumPackagesPlaceGrouped.bind(this);
    this.handleFilterMeanTimePlace = this.handleFilterMeanTimePlace.bind(this);
    this.handleFilterOEE = this.handleFilterOEE.bind(this);
  }

  componentDidMount() {
    pipe(
      'get',
      '/v1/stats/num_packages_place',
      this.handlePackagesPlace,
      this.handleError,
      null,
      null,
      this.source.token,
    );

    pipe(
      'get',
      '/v1/stats/num_packages_place_grouped',
      this.handlePackagesPlaceGrouped,
      this.handleError,
      null,
      null,
      this.source.token,
    );

    pipe(
      'get',
      '/v1/stats/mean_time_place',
      this.handleMeanTimePlace,
      this.handleError,
      null,
      null,
      this.source.token,
    );

    pipe(
      'get',
      '/v1/stats/utilization_factor',
      this.handleOEE,
      this.handleError,
      null,
      null,
      this.source.token,
    );
  }

  componentWillUnmount() {
    this.source.cancel();
  }

  handlePackagesPlace(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const numPackagesPlace = {
        active: [{ x: 'Not Started', y: 0 }], paused: [], domain: [0, 0], loading: false,
      };

      res.data.data.active.forEach((item) => {
        numPackagesPlace.active.push({ x: item.place_name.toString(), y: item.count });

        if (item.count > numPackagesPlace.domain[1]) numPackagesPlace.domain[1] = item.count;
      });

      res.data.data.paused.forEach((item) => {
        numPackagesPlace.paused.push({ x: item.place_name.toString(), y: item.count });

        if (item.count > numPackagesPlace.domain[1]) numPackagesPlace.domain[1] = item.count;
      });

      this.setState({ numPackagesPlace });
    }
  }

  handlePackagesPlaceGrouped(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const numPackagesPlaceGrouped = {
        data: [], labelX: [], labelY: [], loading: false,
      };
      let uniqueX = [];
      let uniqueY = [{ id: 0, name: '' }];

      res.data.data.forEach((item) => {
        uniqueX.push(`${item.part}/${item.year}`);
        uniqueY.push(item.place_name);
      });

      // unique values
      uniqueY = uniqueY.filter((v, i, a) => a.indexOf(v) === i);
      uniqueX = uniqueX.filter((v, i, a) => a.indexOf(v) === i);
      // P1 > P2
      const sortPart = (a, b) => (parseInt(a.split('/')[0], 10) > parseInt(b.split('/')[0], 10) ? 1 : -1);
      // Y1 < Y2
      const sortYear2 = (a, b) => (parseInt(a.split('/')[1], 10) < parseInt(b.split('/')[1], 10) ? -1 : sortPart(a, b));
      // Y1 > Y2
      const sortYear = (a, b) => (parseInt(a.split('/')[1], 10) > parseInt(b.split('/')[1], 10) ? 1 : sortYear2(a, b));
      uniqueX.sort((a, b) => (sortYear(a, b)));

      const matrix = PlaceTab.createDataMatrix(uniqueX, uniqueY);

      res.data.data.forEach((item) => {
        const x = uniqueX.indexOf(`${item.part}/${item.year}`);
        const y = uniqueY.indexOf(item.place_name);
        matrix[x][y - 1].color = -item.count;
      });

      uniqueY.forEach((name, i) => {
        if (name.length > 5) {
          uniqueY[i] = `${name.substr(0, 5)}.`;
        }
      });

      numPackagesPlaceGrouped.data = [].concat(...matrix);
      numPackagesPlaceGrouped.labelX = uniqueX;
      numPackagesPlaceGrouped.labelY = uniqueY;

      this.setState({ numPackagesPlaceGrouped });
    }
  }

  handleMeanTimePlace(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const meanTimePlace = { data: [], domain: [0, 0], loading: false };

      res.data.data.forEach((item) => {
        const hours = item.interval.months * 30 * 24
          + item.interval.days * 24 + item.interval.secs / 60 / 60;
        meanTimePlace.data.push({
          x: item.place_name.toString(),
          y: item.total !== 0 ? (hours / item.total).toFixed(3) : 0,
        });

        if ((hours / item.total) > meanTimePlace.domain[1]) {
          meanTimePlace.domain[1] = (hours / item.total);
        }
      });
      this.setState({ meanTimePlace });
    }
  }

  handleOEE(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const efficiency = {
        utilization: [], performance: [], oee: [], domain: [0, 0], loading: false,
      };

      res.data.data.utilization.forEach((item, i) => {
        efficiency.utilization.push({
          x: item.place_name.toString(),
          y: (item.factor * 100).toFixed(3),
        });

        efficiency.performance.push({
          x: res.data.data.performance[i].place_name.toString(),
          y: (res.data.data.performance[i].factor * 100).toFixed(3),
        });

        efficiency.oee.push({
          x: res.data.data.performance[i].place_name.toString(),
          y: (res.data.data.performance[i].factor * item.factor * 100).toFixed(3),
        });

        if (item.factor * 100 > efficiency.domain[1]) {
          efficiency.domain[1] = (item.factor * 100) + 10;
        }
        if (res.data.data.performance[i].factor * 100 > efficiency.domain[1]) {
          efficiency.domain[1] = (res.data.data.performance[i].factor * 100) + 10;
        }
      });
      this.setState({ efficiency });
    }
  }

  handleFilterNumPackagesPlaceGrouped() {
    const { numPackagesPlaceGrouped } = this.state;
    this.setState({ numPackagesPlaceGrouped: { ...numPackagesPlaceGrouped, loading: true } });
    const { filterOptions } = this.props;
    const options = filterOptions;
    pipe(
      'get',
      `/v1/stats/num_packages_place_grouped${options !== '' ? `?${options}` : ''}`,
      this.handlePackagesPlaceGrouped,
      this.handleError,
      null,
      null,
      this.source.token,
    );
  }

  handleFilterMeanTimePlace() {
    const { meanTimePlace } = this.state;
    this.setState({ meanTimePlace: { ...meanTimePlace, loading: true } });
    const { filterOptions } = this.props;
    const options = filterOptions;
    pipe(
      'get',
      `/v1/stats/mean_time_place${options !== '' ? `?${options}` : ''}`,
      this.handleMeanTimePlace,
      this.handleError,
      null,
      null,
      this.source.token,
    );
  }

  handleFilterOEE() {
    const { efficiency } = this.state;
    this.setState({ efficiency: { ...efficiency, loading: true } });
    const { filterOptions } = this.props;
    const options = filterOptions;
    pipe(
      'get',
      `/v1/stats/utilization_factor${options !== '' ? `?${options}` : ''}`,
      this.handleOEE,
      this.handleError,
      null,
      null,
      this.source.token,
    );
  }

  render() {
    const {
      numPackagesPlace, meanTimePlace, efficiency,
      numPackagesPlaceGrouped, error,
    } = this.state;
    const LEG1_ITEMS = [
      { title: 'Utilização', color: '#007399', strokeWidth: 13 },
      { title: 'Performance', color: '#ff3300', strokeWidth: 13 },
      { title: 'Eficiência', color: '#56E29E', strokeWidth: 13 },
    ];

    return (
      <Tab.Pane attached>

        <Message
          hidden={!error.visible}
          negative
          header='Oops, algo de errado aconteceu'
          content={error.message}
          list={error.extra}
        />

        <Grid centered stackable>
          <Grid.Row columns='equal'>
            {(numPackagesPlace.active.length !== 0 || numPackagesPlace.paused.length !== 0)
              ? (
                <Grid.Column textAlign='center'>
                  <Label
                    basic
                    color={process.env.REACT_APP_PRIMARY_COLOR}
                    content='Encomendas por Local'
                    size='large'
                  />
                  <p />
                  <NumPackagesPlace numPackagesPlace={numPackagesPlace} />
                </Grid.Column>
              )
              : (
                <Grid.Column textAlign='center'>
                  <Label
                    basic
                    color={process.env.REACT_APP_PRIMARY_COLOR}
                    content='Encomendas por Local'
                    size='large'
                  />
                  <EmptyChart loading={numPackagesPlace.loading} />
                </Grid.Column>
              )}
            {meanTimePlace.data.length !== 0
              ? (
                <Grid.Column textAlign='center'>
                  <Label
                    as='a'
                    basic
                    color={process.env.REACT_APP_PRIMARY_COLOR}
                    content='Tempo por Local'
                    size='large'
                    icon='search'
                    labelposition='left'
                    onClick={this.handleFilterMeanTimePlace}
                  />
                  <p />
                  <MeanTimePlace meanTimePlace={meanTimePlace} />
                </Grid.Column>
              )
              : (
                <Grid.Column textAlign='center'>
                  <Label
                    as='a'
                    basic
                    color={process.env.REACT_APP_PRIMARY_COLOR}
                    content='Tempo por Local'
                    size='large'
                    icon='search'
                    labelposition='left'
                    onClick={this.handleFilterMeanTimePlace}
                  />
                  <EmptyChart loading={meanTimePlace.loading} />
                </Grid.Column>
              )}
          </Grid.Row>
          <Grid.Row centered columns='equal'>
            <Grid.Column textAlign='center'>
              <Label
                as='a'
                basic
                color={process.env.REACT_APP_PRIMARY_COLOR}
                content='Eficiência de Local'
                size='large'
                icon='search'
                labelposition='left'
                onClick={this.handleFilterOEE}
              />
            </Grid.Column>
            <Grid.Column textAlign='left'>
              <DiscreteColorLegend
                orientation='horizontal'
                style={{ width: '100%', paddingLeft: '30px' }}
                items={LEG1_ITEMS}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns='equal'>
            {efficiency.utilization.length !== 0
              ? (
                <Grid.Column textAlign='center'>
                  <Efficiency
                    efficiency={efficiency}
                  />
                </Grid.Column>
              )
              : (
                <Grid.Column textAlign='center'>
                  <EmptyChart loading={efficiency.loading} />
                </Grid.Column>
              )}
          </Grid.Row>
          <Grid.Row columns='equal'>
            {numPackagesPlaceGrouped.data.length !== 0
              ? (
                <Grid.Column textAlign='center'>
                  <Label
                    as='a'
                    basic
                    color={process.env.REACT_APP_PRIMARY_COLOR}
                    content='Heatmap Encomendas Por Local'
                    size='large'
                    icon='search'
                    labelposition='left'
                    onClick={this.handleFilterNumPackagesPlaceGrouped}
                  />
                  <p />
                  <NumPackagesPlaceGrouped
                    numPackagesPlaceGrouped={numPackagesPlaceGrouped}
                  />
                </Grid.Column>
              )
              : (
                <Grid.Column textAlign='center'>
                  <Label
                    as='a'
                    basic
                    color={process.env.REACT_APP_PRIMARY_COLOR}
                    content='Heatmap Encomendas Por Local'
                    size='large'
                    icon='search'
                    labelposition='left'
                    onClick={this.handleFilterNumPackagesPlaceGrouped}
                  />
                  <EmptyChart loading={numPackagesPlaceGrouped.loading} />
                </Grid.Column>
              )}
            <p />
          </Grid.Row>
        </Grid>
      </Tab.Pane>
    );
  }
}

export default PlaceTab;

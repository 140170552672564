import React, { Component } from 'react';
import {
  Button, List, Message, Modal,
} from 'semantic-ui-react';
import { handleChange, handleError, pipe } from '../../../Helpers';

class ListPathsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: {
        visible: false,
        message: '',
        extra: [],
      },
    };

    this.handleError = handleError.bind(this);
    this.handleChange = handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const { updatePathsList } = this.props;
    updatePathsList();
  }

  handleClick(itemIndex) {
    const { setPath, listPaths } = this.props;
    setPath(listPaths[itemIndex].attributes.value);
    const { handleModal } = this.props;
    handleModal('listModal', false);
  }

  handleDelete(itemIndex) {
    const { listPaths, updatePathsList } = this.props;
    pipe(
      'delete',
      `/v1/paths/${listPaths[itemIndex].id}`,
      updatePathsList,
      this.handleError,
      null,
      null,
    );
  }

  render() {
    const { error } = this.state;
    const { open, handleModal, listDescriptions } = this.props;

    return (
      <Modal size='small' open={open}>
        <Modal.Header>
          Selecione o caminho que deseja
        </Modal.Header>
        <Modal.Content>
          <List bulleted selection relaxed divided size='big' style={{ maxHeight: '50vh', overflow: 'auto' }}>
            {listDescriptions.map((path, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <List.Item key={i} onClick={() => this.handleClick(i)}>
                <List.Content floated='right'>
                  <Button
                    size='tiny'
                    icon='trash alternate'
                    color={process.env.REACT_APP_SECONDARY_COLOR}
                    onClick={() => this.handleDelete(i)}
                  />
                </List.Content>
                {path}
              </List.Item>
            ))}
          </List>

          <Message
            negative
            hidden={!error.visible}
            header='Erro'
            content={error.message}
            list={error.extra}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => handleModal('listModal', false)}
            basic
            color={process.env.REACT_APP_PRIMARY_COLOR}
            labelPosition='left'
            icon='minus'
            content='Cancelar'
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ListPathsModal;

import React from 'react';
import Survey from '../../Components/Shared/Survey';

// eslint-disable-next-line react/prefer-stateless-function
class AnswerSurvey extends React.Component {
  render() {
    const { match } = this.props;
    const { code } = match.params;
    return (
      <Survey
        placeHolder='Questionário'
        kind='answer'
        apiPackage={`/v1/public/packages/${code}`}
        api={`/v1/public/packages/${code}/replies`}
        {...this.props}
      />
    );
  }
}

export default AnswerSurvey;

import React from 'react';
import {
  Button, Container, Form, Message, Segment,
} from 'semantic-ui-react';
import { handleChange, handleError, pipe } from '../../../Helpers';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confirmPassword: '',
      token: '',
      errorDiff: false,
      errorInvalid: false,
      loading: false,
      error: {
        visible: false,
        message: '',
        extra: [],
      },
    };

    this.handleError = handleError.bind(this);
    this.handleChange = handleChange.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
  }

  componentDidMount() {
    document.title = 'ThePipeTracker - Reset Password';

    const urlString = window.location.href;
    const url = new URL(urlString);
    this.setState({ token: url.searchParams.get('token') });
  }

  handleChangePassword() {
    this.setState({ loading: true });
    const { password, confirmPassword, token } = this.state;

    if ((/^.{8,}$/g.exec(password)) == null) {
      this.setState({ errorInvalid: true, loading: false });
    } else if (password !== confirmPassword) {
      this.setState({ errorDiff: true, loading: false });
    } else {
      pipe(
        'patch',
        `/auth/passwords/${token}`,
        this.handleSuccess,
        this.handleError,
        {
          user: {
            password,
          },
        },
      );
    }
  }

  handleSuccess() {
    const { history } = this.props;
    history.push('/');
    history.push('/login');
  }

  render() {
    const {
      loading, password, confirmPassword, error, errorInvalid, errorDiff,
    } = this.state;
    return (
      <>
        <Container className='publicContainer'>
          <Segment color={process.env.REACT_APP_PRIMARY_COLOR} loading={loading}>
            <h3>Inserir Nova Password</h3>
            <Form>
              <Form.Input
                required
                label='Nova Password'
                name='password'
                maxLength='50'
                type='password'
                value={password}
                onChange={this.handleChange}
              />
              <Form.Input
                required
                label='Confirmar Password'
                name='confirmPassword'
                maxLength='50'
                type='password'
                value={confirmPassword}
                onChange={this.handleChange}
              />
              <Message
                hidden={!errorInvalid}
                negative
                size='mini'
                content='Tem de ter pelo menos 8 caracteres'
              />
              <Message
                hidden={!errorDiff}
                negative
                size='mini'
                content='Passwords não são iguais'
              />
              <Message
                hidden={!error.visible}
                negative
                size='mini'
                content={error.message}
                list={error.extra}
              />
              <Button
                icon='key'
                labelPosition='left'
                color={process.env.REACT_APP_PRIMARY_COLOR}
                onClick={this.handleChangePassword}
                content='Confirmar'
              />
            </Form>
          </Segment>
        </Container>
      </>
    );
  }
}

export default ChangePassword;

import React from 'react';
import {
  Button,
  Container, Grid, Loader, Message, Segment,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import has from 'has';
import { PDFViewer } from '@react-pdf/renderer';
import moment from 'moment';
import axios from 'axios';
import {
  filterQueryDate, handleChange, handleError, pipe,
} from '../../Helpers';
import ReportPDF from '../../Components/Private/ReportPDF';

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      minDate: new Date(moment().subtract(3, 'month').format('M/D/YYYY')),
      maxDate: new Date(moment().format('M/D/YYYY')),
      registered: {
        present: '--',
        past: '--',
      },
      completed: {
        present: '--',
        past: '--',
      },
      onTime: {
        present: '--',
        past: '--',
      },
      surveyCount: {
        present: '--',
        past: '--',
      },
      production: {
        places: [],
        present: {},
        past: {},
      },
      ratings: {
        present: {},
        past: {},
      },
      error: {
        visible: false,
        message: '',
      },
    };
    const { CancelToken } = axios;
    this.source = CancelToken.source();
    this.handleChange = handleChange.bind(this);
    this.handleError = handleError.bind(this);
    this.filterQueryDate = filterQueryDate.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleReport = this.handleReport.bind(this);
    this.requestReport = this.requestReport.bind(this);
  }

  componentDidMount() {
    document.title = 'ThePipeTracker - Relatórios';

    this.requestReport();
  }

  componentWillUnmount() {
    this.source.cancel();
  }

  handleReport(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      this.handleEfficiency(res.data.data.production);
      this.handleRatings(res.data.data.ratings);

      this.setState({
        registered: res.data.data.registered,
        completed: res.data.data.completed,
        onTime: res.data.data.on_time,
        surveyCount: res.data.data.survey_count,
      });
    }
    this.setState({ loading: false });
  }

  handleRatings(rates) {
    const meanRatings = { present: [], past: [] };
    rates.present.data.forEach((item) => {
      let sum = 0; let
        count = 0;
      item.ratings.forEach((ratings) => {
        sum += ratings.rating * ratings.count;
        count += ratings.count;
      });
      meanRatings.present.push({
        question_description: item.question_description,
        mean: sum / count,
      });
    });

    rates.past.data.forEach((item) => {
      let sum = 0; let
        count = 0;
      item.ratings.forEach((ratings) => {
        sum += ratings.rating * ratings.count;
        count += ratings.count;
      });
      meanRatings.past.push({
        question_description: item.question_description,
        mean: sum / count,
      });
    });
    this.setState({ ratings: meanRatings });
  }

  // eslint-disable-next-line class-methods-use-this
  handleEfficiency(production) {
    const prod = {
      places: [],
      present: {
        utilization: [],
        performance: [],
        oee: [],
      },
      past: {
        utilization: [],
        performance: [],
        oee: [],
      },
    };
    // eslint-disable-next-line array-callback-return
    production.present.data.utilization.map((place, i) => {
      prod.places.push(`${place.place_order}. ${place.place_name}`);
      const util = place.factor;
      const perf = production.present.data.performance[i].factor;
      prod.present.utilization.push((util * 100).toFixed(0));
      prod.present.performance.push((perf * 100).toFixed(0));
      prod.present.oee.push((util * perf * 100).toFixed(0));
    });

    // eslint-disable-next-line array-callback-return
    production.past.data.utilization.map((place, i) => {
      const util = place.factor;
      const perf = production.past.data.performance[i].factor;
      prod.past.utilization.push((util * 100).toFixed(0));
      prod.past.performance.push((perf * 100).toFixed(0));
      prod.past.oee.push((util * perf * 100).toFixed(0));
    });

    this.setState({ production: prod });
  }

  handleChangeDate(e, name) {
    this.setState({ [name]: e, loading: true });
  }

  requestReport() {
    const { minDate, maxDate } = this.state;
    pipe(
      'post',
      '/v1/report',
      this.handleReport,
      this.handleError,
      {
        minDate,
        maxDate,
      },
      null,
      this.source.token,
    );
  }

  render() {
    const {
      error, registered, completed, onTime, production,
      minDate, maxDate, loading, surveyCount, ratings,
    } = this.state;
    return (
      <>
        <Container className='privateContainer'>
          <Segment color={process.env.REACT_APP_PRIMARY_COLOR}>
            <h3>Relatório</h3>
            <Message
              hidden={!error.visible}
              negative
              header='Oops, algo de errado aconteceu'
              content={error.message}
              list={error.extra}
            />

            <Grid stackable>
              <Grid.Row columns='equal'>
                <Grid.Column textAlign='left' width={4}>
                  <DatePicker
                    className='react-datepicker-custom'
                    placeholderText='Desde'
                    selected={minDate}
                    onChange={(e) => this.handleChangeDate(e, 'minDate')}
                    isClearable
                    dateFormat='dd/MM/yyyy'
                  />
                </Grid.Column>
                <Grid.Column textAlign='left' width={4}>
                  <DatePicker
                    className='react-datepicker-custom'
                    placeholderText='Até'
                    selected={maxDate}
                    onChange={(e) => this.handleChangeDate(e, 'maxDate')}
                    isClearable
                    dateFormat='dd/MM/yyyy'
                  />
                </Grid.Column>
                <Grid.Column textAlign='right' width={8} verticalAlign='middle'>
                  <Button
                    color={process.env.REACT_APP_PRIMARY_COLOR}
                    onClick={this.requestReport}
                    content='Procurar'
                    icon='search'
                    size='large'
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <p />
            {error.visible || loading
              ? (
                <Segment placeholder>
                  <Loader active />
                </Segment>
              )
              : (
                <PDFViewer width='100%' style={{ height: '100vh' }}>
                  <ReportPDF
                    minDate={minDate}
                    maxDate={maxDate}
                    registered={registered}
                    completed={completed}
                    onTime={onTime}
                    surveyCount={surveyCount}
                    production={production}
                    ratings={ratings}
                  />
                </PDFViewer>
              )}

          </Segment>
        </Container>
      </>
    );
  }
}

export default Reports;

import React from 'react';
import {
  FlexibleXYPlot, HeatmapSeries, XAxis, YAxis,
} from 'react-vis';

// eslint-disable-next-line react/prefer-stateless-function
class NumPackagesPlaceGrouped extends React.Component {
  render() {
    const MARGIN_TEXT = {
      left: 45,
      right: 10,
      bottom: 70,
      top: 5,
    };
    const { numPackagesPlaceGrouped } = this.props;
    const {
      labelX, labelY, data, loading,
    } = numPackagesPlaceGrouped;

    return (
      <FlexibleXYPlot
        margin={MARGIN_TEXT}
        height={500}
        animation
      >
        <XAxis
          tickFormat={(v) => labelX[v]}
          tickLabelAngle={-45}
        />
        <YAxis
          tickFormat={(v) => labelY[v]}
          tickLabelAngle={-45}
        />
        <HeatmapSeries
          className='heatmap-series-example'
          colorRange={['#ff3300', '#ffcc00']}
          data={data}
          opacity={loading ? 0.2 : 1}
        />
      </FlexibleXYPlot>
    );
  }
}

export default NumPackagesPlaceGrouped;

import React from 'react';
import {
  Button, Card, Checkbox, Grid, Icon, Label, Message, Modal, Popup, Segment,
} from 'semantic-ui-react';
import moment from 'moment';
import has from 'has';
import { handleChange, handleError, pipe } from '../../Helpers';
import '../CSS/Calendar.css';
import 'moment/locale/pt';

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstDay: moment(), // monday of current week
      week: new Array(7),
      weekends: false,
      completed: false,
      loading: true,
      openInfo: false,
      selectedDay: moment(),
      pack: {
        id: '',
        attributes: {
          number: '',
          description: '',
          delivery_date: moment(),
          completed: moment(),
          owner_name: '',
          last_place: '',
        },
      },
      success: false,
      error: {
        visible: false,
        message: '',
        extra: [],
      },
      width: window.innerWidth,
    };

    this.handleChange = handleChange.bind(this);
    this.handleError = handleError.bind(this);
    this.handleCalendar = this.handleCalendar.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleShift = this.handleShift.bind(this);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
  }

  componentDidMount() {
    document.title = 'ThePipeTracker - Calendar';

    moment.locale('pt');

    const completed = localStorage.getItem('completed') === 'true' || false;
    const weekends = localStorage.getItem('weekends') === 'true' || false;
    this.setState({
      weekends,
      completed,
    });

    const urlString = window.location.href;
    const url = new URL(urlString);

    const startOfWeek = moment.localeData().firstDayOfWeek();
    let firstDay = moment().startOf('week').add(1 - startOfWeek, 'days');

    if (url.searchParams !== undefined) {
      if (url.searchParams.get('firstDay') !== null) firstDay = moment(new Date(url.searchParams.get('firstDay'))).startOf('week').add(1 - startOfWeek, 'days');
    }

    this.buildFrame(firstDay.diff(moment().startOf('week').add(1 - startOfWeek, 'days'), 'days'));
    const week = firstDay.format('W');
    const year = firstDay.format('YYYY');

    this.requestData(week, year, completed);

    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange(event) {
    const el = event.target;
    this.setState({ width: el.window.innerWidth });
  }

  handleCalendar(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const { week } = this.state;
      res.data.data.forEach((day) => {
        week[day.weekday - 1].packages = day.packages;
      });

      this.setState({ week, loading: false });
    }
  }

  handleOpenInfoModal(pack) {
    this.setState({ openInfo: true, pack });
  }

  handleEdit() {
    const { history } = this.props;
    const { pack, firstDay } = this.state;
    history.push(`/packages/${pack.id}/edit?redirect=calendar&firstDay=${firstDay.format('YYYY-MM-DD')}`);
  }

  handleClose(modal) {
    this.setState({ [modal]: false });
  }

  handleToggle(name, modal) {
    const { state } = this;
    const { firstDay } = state;
    const check = state[name];
    localStorage.setItem(name, (!check).toString());
    this.setState({ [name]: !check, [modal]: false });

    if (name === 'completed') {
      this.setState({ loading: true });
      const week = firstDay.format('W');
      const year = firstDay.format('YYYY');
      this.requestData(week, year, !check);
    }
  }

  handleShift(shift) {
    const { history, location } = this.props;
    const { completed } = this.state;
    const firstDay = this.buildFrame(shift);
    const week = firstDay.format('W');
    const year = firstDay.format('YYYY');
    history.replace(`${location.pathname}?firstDay=${firstDay.format('YYYY-MM-DD')}`);

    this.requestData(week, year, completed);
  }

  buildFrame(shift) {
    this.setState({ loading: true });
    const { firstDay } = this.state;
    const startOfWeek = moment.localeData().firstDayOfWeek();
    const week = new Array(7);
    const newFirstDay = shift !== 0 ? firstDay.add(shift, 'days') : moment().startOf('week').add(1 - startOfWeek, 'days');
    for (let i = 0; i < 7; i += 1) {
      week[i] = {
        weekday: i + 1,
        day: moment(newFirstDay).startOf('week').add(i + 1 - startOfWeek, 'days'),
        packages: { data: [] },
      };
    }

    this.setState({ week, firstDay: newFirstDay });
    return newFirstDay;
  }

  requestData(week, year, completed) {
    const nCompleted = completed ? 'all' : 'not_completed';
    pipe(
      'get',
      `/v1/calendar/${year}/weeks/${week}?filter=${nCompleted}`,
      this.handleCalendar,
      this.handleError,
    );
  }

  render() {
    const MOBILE_VERSION_PX = 800;
    const {
      openInfo, pack, error, loading, firstDay, width, weekends, week, completed, success,
    } = this.state;
    const prediction = function getPrediction(packAux) {
      if (packAux.attributes.prediction) {
        return new Date(packAux.attributes.prediction.attributes.delivery);
      }
      return null;
    };
    const predictionLastUpdate = function predictionLastUpdate(packAux) {
      if (packAux.attributes.prediction) {
        return new Date(packAux.attributes.prediction.attributes.last_updated);
      }
      return null;
    };
    const completedOnTime = function completedOnTime(packAux) {
      const comp = new Date(packAux.attributes.completed).setHours(0, 0, 0, 0);
      const delivery = new Date(packAux.attributes.delivery_date).setHours(0, 0, 0, 0);
      if (comp <= delivery) {
        return 'green';
      }
      return 'yellow';
    };
    const predictionOnTime = function predictionOnTime(packAux) {
      const predDate = prediction(packAux);
      const delivery = new Date(packAux.attributes.delivery_date).setHours(0, 0, 0, 0);
      if (predDate !== null && predDate >= delivery && delivery > new Date()) {
        return 'yellow';
      }
      return 'red';
    };
    const InfoModal = () => (
      <Modal size='small' open={openInfo} onClose={() => this.handleClose('openInfo')}>
        <Modal.Header>
          Encomenda
          {' '}
          {pack.attributes.number}
        </Modal.Header>
        <Modal.Content>
          <Grid columns='equal'>
            <Grid.Column>
              <p>
                <b>Cliente:</b>
                {' '}
                {pack.attributes.owner_name}
                <br />
                <b>Descrição:</b>
                {' '}
                {pack.attributes.description}
                <br />
              </p>
            </Grid.Column>
            <Grid.Column>
              <p>
                <b>Local:</b>
                {' '}
                {pack.attributes.last_place || 'Registada'}
                <br />
                <b>Entrega:</b>
                {' '}
                {moment(pack.attributes.delivery_date).format('DD MMMM YYYY')}
                {new Date(pack.attributes.delivery_date) < new Date()
                  && pack.attributes.status !== 'completed'
                  ? <Icon color='red' name='warning sign' />
                  : ''}
                <br />
                <b>Previsão:</b>
                {' '}
                {prediction(pack) !== null
                  ? moment(prediction(pack)).format('DD/MM/YY, HH:mm')
                  : 'Sem Previsão'}
                {prediction(pack) !== null
                && prediction(pack) > new Date(pack.attributes.delivery_date)
                && pack.attributes.status !== 'completed'
                  ? <Icon color='yellow' name='warning sign' />
                  : ''}
                <br />
                {prediction(pack) !== null
                  ? `(${moment(predictionLastUpdate(pack)).fromNow()})`
                  : ''}
              </p>
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color={process.env.REACT_APP_PRIMARY_COLOR}
            icon='edit'
            labelPosition='right'
            content='Editar'
            onClick={this.handleEdit}
          />
        </Modal.Actions>
      </Modal>
    );

    return (
      <>
        <InfoModal />
        <Message
          visible={!success}
          hidden={!success}
          success
          header='Sucesso'
          content='A sua ação foi completa com sucesso'
        />
        <Message
          hidden={!error.visible}
          negative
          header='Oops, algo de errado aconteceu'
          content={error.message}
          list={error.extra}
        />

        <Segment loading={loading}>
          <Grid columns='equal'>
            <Grid.Column style={{ fontSize: '1.7em' }} verticalAlign='middle'>
              <p>
                <b>{firstDay.format('MMMM')}</b>
                {' '}
                {firstDay.format('YYYY')}
              </p>
            </Grid.Column>
            <Grid.Column textAlign='right' verticalAlign='middle'>
              <Button.Group
                size={width <= MOBILE_VERSION_PX ? 'mini' : 'medium'}
                vertical={width <= MOBILE_VERSION_PX}
              >
                <Popup
                  trigger={(
                    <Button
                      icon='setting'
                      basic
                      color={process.env.REACT_APP_TERTIARY_COLOR}
                    />
                  )}
                  on='click'
                  position='left center'
                >
                  <Checkbox
                    style={{ background: '#FFFFFF' }}
                    label='Fim de Semana'
                    toggle
                    onChange={() => this.handleToggle('weekends', 'openInfo')}
                    checked={weekends}
                  />
                  <p />
                  <Checkbox
                    style={{ background: '#FFFFFF' }}
                    label='Completas'
                    toggle
                    onChange={() => this.handleToggle('completed', 'openInfo')}
                    checked={completed}
                  />
                </Popup>
                <Button
                  color={process.env.REACT_APP_TERTIARY_COLOR}
                  basic
                  icon='angle left'
                  onClick={() => this.handleShift(-7)}
                />
                <Button
                  color={process.env.REACT_APP_TERTIARY_COLOR}
                  basic
                  content='Hoje'
                  onClick={() => this.handleShift(0)}
                />
                <Button
                  color={process.env.REACT_APP_TERTIARY_COLOR}
                  basic
                  icon='angle right'
                  onClick={() => this.handleShift(7)}
                />
              </Button.Group>
            </Grid.Column>
          </Grid>
          <Segment>
            <Grid
              divided
              columns='equal'
              stackable
              className={!(width <= MOBILE_VERSION_PX) ? 'calendarContainer' : ''}
            >
              {week.slice(0, weekends ? 7 : 5).map((weekday, i) => (
                <Grid.Column
                  className={!(width <= MOBILE_VERSION_PX) ? 'weekdayContainer' : ''}
                  style={{ background: i < 5 ? '#F3F4F5' : '#d5d9dc' }}
                  key={weekday.day}
                >
                  <Grid columns='equal' verticalAlign='middle'>
                    <Grid.Column>
                      <Button
                        color={weekday.day.isSame(moment(), 'day') ? process.env.REACT_APP_SECONDARY_COLOR : process.env.REACT_APP_PRIMARY_COLOR}
                        fluid
                        content={weekday.day.format('ddd D')}
                      />
                    </Grid.Column>
                  </Grid>
                  <Card.Group>
                    {weekday.packages.data.map((packAux) => (
                      <Card
                        onClick={() => this.handleOpenInfoModal(packAux)}
                        fluid
                        key={packAux.id}
                        color={process.env.REACT_APP_TERTIARY_COLOR}
                      >
                        <Card.Content>
                          {packAux.attributes.status === 'completed'
                          || new Date(packAux.attributes.delivery_date) < new Date()
                          || (prediction(packAux) !== null
                            && new Date(prediction(packAux))
                              > new Date(packAux.attributes.delivery_date))
                            ? (
                              <Label
                                corner='right'
                                size='small'
                                icon={packAux.attributes.status === 'completed' ? 'check' : 'warning sign'}
                                id={packAux.id}
                                color={packAux.attributes.status === 'completed' ? completedOnTime(packAux) : predictionOnTime(packAux)}
                              />
                            )
                            : ''}
                          <Card.Header>
                            {packAux.attributes.number}
                          </Card.Header>
                          <Card.Meta style={{ color: '#000000' }}>
                            <Label
                              circular
                              size='mini'
                              empty
                              color={process.env.REACT_APP_TERTIARY_COLOR}
                            />
                            {' '}
                            {packAux.attributes.last_place || 'Registada'}
                          </Card.Meta>
                          <Card.Description
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: '3', /* number of lines to show */
                              lineHeight: '20px',
                              height: '57px',
                            }}
                          >
                            <b>
                              {packAux.attributes.owner_name}
                              :
                            </b>
                            {' '}
                            {packAux.attributes.description}
                          </Card.Description>
                        </Card.Content>
                      </Card>
                    ))}
                  </Card.Group>
                </Grid.Column>
              ))}
            </Grid>
          </Segment>
        </Segment>
      </>
    );
  }
}

export default Calendar;

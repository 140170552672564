import React from 'react';
import ConfirmPage from '../../../Components/Private/ConfirmPage';

// eslint-disable-next-line react/prefer-stateless-function
class ConfirmDeletePackage extends React.Component {
  render() {
    const { match } = this.props;
    const { id } = match.params;
    return (
      <ConfirmPage
        kind='delete-package'
        className='privateContainer'
        placeHolder='eliminar encomenda'
        api={`/v1/packages/${id}`}
        method='delete'
        backTo='/packages'
        {...this.props}
      />
    );
  }
}

export default ConfirmDeletePackage;

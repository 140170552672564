import React from 'react';
import {
  FlexibleXYPlot, Hint, HorizontalGridLines, VerticalBarSeries, XAxis, YAxis,
} from 'react-vis';

class Efficiency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      efficiencyValue: null,
    };
  }

  render() {
    const MARGIN_NUMBER = {
      left: 50,
      right: 30,
      bottom: 70,
      top: 5,
    };
    const { efficiency } = this.props;
    const { efficiencyValue } = this.state;
    const {
      domain, utilization, performance, oee, loading,
    } = efficiency;

    return (
      <FlexibleXYPlot
        xType='ordinal'
        yDomain={domain}
        margin={MARGIN_NUMBER}
        height={300}
        animation
      >
        <HorizontalGridLines />
        <XAxis tickLabelAngle={-45} />
        <YAxis tickFormat={(v) => `${v}%`} />
        <VerticalBarSeries
          onValueMouseOver={(v) => this.setState({ efficiencyValue: v })}
          onValueMouseOut={() => this.setState({ efficiencyValue: null })}
          color='#007399'
          data={utilization}
          opacity={loading ? 0.2 : 1}
        />
        <VerticalBarSeries
          onValueMouseOver={(v) => this.setState({ efficiencyValue: v })}
          onValueMouseOut={() => this.setState({ efficiencyValue: null })}
          color='#ff3300'
          data={performance}
          opacity={loading ? 0.2 : 1}
        />
        <VerticalBarSeries
          onValueMouseOver={(v) => this.setState({ efficiencyValue: v })}
          onValueMouseOut={() => this.setState({ efficiencyValue: null })}
          color='#56E29E'
          data={oee}
          opacity={loading ? 0.2 : 1}
        />
        {efficiencyValue ? <Hint value={efficiencyValue} /> : null}
      </FlexibleXYPlot>
    );
  }
}

export default Efficiency;

import React from 'react';
import {
  Button, Container, Form, Grid, Message, Segment,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import has from 'has';
import {
  handleChange, handleError, pipe, setSuccessTimeout,
} from '../../../Helpers';

class EditShift extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timestampStart: null,
      timestampEnd: null,
      weekday: '',
      loading: false,
      error: {
        visible: false,
        message: '',
        extra: [],
      },
    };

    this.setSuccessTimeout = setSuccessTimeout.bind(this);
    this.handleChange = handleChange.bind(this);
    this.handleError = handleError.bind(this);
    this.handleShift = this.handleShift.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
  }

  componentDidMount() {
    document.title = 'ThePipeTracker - Adicionar Turno';

    const { match } = this.props;
    const { id } = match.params;
    pipe(
      'get',
      `/v1/shifts/${id}`,
      this.handleShift,
      this.handleError,
    );
  }

  handleShift(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      this.setState({
        timestampStart: new Date(res.data.data.attributes.timestamp_start) || null,
        timestampEnd: new Date(res.data.data.attributes.timestamp_end) || null,
        weekday: res.data.data.attributes.weekday || '',
        loading: false,
      });
    }
  }

  handleEdit() {
    this.setState({ loading: true });
    const { match } = this.props;
    const { id } = match.params;
    const {
      timestampStart, timestampEnd, weekday,
    } = this.state;

    if (this.verifyContent()) {
      pipe(
        'patch',
        `/v1/shifts/${id}`,
        this.handleSuccess,
        this.handleError,
        {
          shift: {
            timestamp_start: moment(timestampStart).format('YYYY-MM-DDTHH:mm:ss'),
            timestamp_end: moment(timestampEnd).format('YYYY-MM-DDTHH:mm:ss'),
            weekday,
          },
        },
        null,
      );
    } else {
      const error = { visible: true, message: 'Por favor preencha todos os campos' };
      this.setState({ error, loading: false });
    }
  }

  handleDelete() {
    this.setState({ loading: true });
    const { match } = this.props;
    const { id } = match.params;

    pipe(
      'delete',
      `/v1/shifts/${id}`,
      this.handleSuccess,
      this.handleError,
      null,
      null,
    );
  }

  handleSuccess() {
    const { history } = this.props;
    history.push('/shifts');
  }

  handleChangeDate(e, identifier) {
    this.setState({ [identifier]: e });
  }

  handleDayChange(event, e) {
    this.setState({ weekday: e.value });
  }

  verifyContent() {
    const {
      timestampStart, timestampEnd, weekday,
    } = this.state;
    return timestampStart !== null
      && timestampEnd !== null
      && weekday !== '';
  }

  render() {
    const week = [
      { key: 1, text: 'Segunda', value: 1 },
      { key: 2, text: 'Terça', value: 2 },
      { key: 3, text: 'Quarta', value: 3 },
      { key: 4, text: 'Quinta', value: 4 },
      { key: 5, text: 'Sexta', value: 5 },
      { key: 6, text: 'Sábado', value: 6 },
      { key: 7, text: 'Domingo', value: 7 },
    ];
    const {
      loading, timestampStart, timestampEnd, weekday, error,
    } = this.state;
    return (
      <>
        <Container className='privateContainer'>
          <Segment color={process.env.REACT_APP_PRIMARY_COLOR} loading={loading}>
            <Grid columns='equal' stackable>
              <Grid.Column>
                <h3>Adicionar Novo Turno</h3>
              </Grid.Column>
              <Grid.Column textAlign='right'>
                <Button
                  basic
                  icon='times'
                  labelPosition='left'
                  color={process.env.REACT_APP_PRIMARY_COLOR}
                  size='small'
                  onClick={this.handleDelete}
                  content='Eliminar Turno'
                />
              </Grid.Column>
            </Grid>
            <p />
            <Form>
              <Grid columns='equal' stackable>
                <Grid.Column>
                  <p>
                    Dia da Semana
                    <span style={{ color: 'red' }}>*</span>
                  </p>
                  <Form.Dropdown
                    name='day'
                    value={weekday}
                    onChange={this.handleDayChange}
                    options={week}
                    selection
                  />
                </Grid.Column>
                <Grid.Column>
                  <p>
                    Início
                    <span style={{ color: 'red' }}>*</span>
                  </p>
                  <DatePicker
                    className='react-datepicker-custom'
                    showTimeSelect
                    showTimeSelectOnly
                    selected={timestampStart}
                    timeCaption='Horário'
                    timeFormat='HH:mm'
                    dateFormat='HH:mm'
                    onChange={(date) => this.handleChangeDate(date, 'timestampStart')}
                  />
                </Grid.Column>
                <Grid.Column>
                  <p>
                    Fim
                    <span style={{ color: 'red' }}>*</span>
                  </p>
                  <DatePicker
                    className='react-datepicker-custom'
                    showTimeSelect
                    showTimeSelectOnly
                    selected={timestampEnd}
                    timeCaption='Horário'
                    timeFormat='HH:mm'
                    dateFormat='HH:mm'
                    onChange={(date) => this.handleChangeDate(date, 'timestampEnd')}
                  />
                </Grid.Column>
              </Grid>
            </Form>
            <p />
            <Button
              icon='edit'
              labelPosition='left'
              color={process.env.REACT_APP_PRIMARY_COLOR}
              size='large'
              onClick={this.handleEdit}
              content='Confirmar'
            />
            <Message
              hidden={!error.visible}
              negative
              header='Criação de Turno falhou'
              content={error.message}
              list={error.extra}
            />
          </Segment>
        </Container>
      </>
    );
  }
}

export default EditShift;

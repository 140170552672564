import React from 'react';
import {
  Grid, Label, Message, Tab,
} from 'semantic-ui-react';
import 'react-vis/dist/style.css';
import has from 'has';
import axios from 'axios';
import {
  handleChange, handleError, pipe,
} from '../../../../Helpers';
import MeanTimePaused from '../Plots/MeanTimePaused';
import EmptyChart from '../Plots/EmptyChart';

class QueueTab extends React.Component {
  static createPauseMatrix(places) {
    const matrix = [];
    for (let i = 1; i < places.length; i += 1) {
      matrix.push([]);
      for (let j = 0; j < places.length; j += 1) {
        matrix[i - 1].push({ x: i, y: j, color: '' });
      }
    }
    return matrix;
  }

  constructor(props) {
    super(props);
    this.state = {
      meanTimePaused: {
        data: [],
        labelX: [],
        labelY: [],
        domain: 0,
        loading: true,
      },
      error: {
        visible: false,
        message: '',
        extra: [],
      },
    };
    const { CancelToken } = axios;
    this.source = CancelToken.source();
    this.handleChange = handleChange.bind(this);
    this.handleError = handleError.bind(this);
    this.handleMeanTimePaused = this.handleMeanTimePaused.bind(this);
    this.handleFilterMeanTimePaused = this.handleFilterMeanTimePaused.bind(this);
  }

  componentDidMount() {
    pipe(
      'get',
      '/v1/stats/mean_time_paused',
      this.handleMeanTimePaused,
      this.handleError,
      null,
      null,
      this.source.token,
    );
  }

  componentWillUnmount() {
    this.source.cancel();
  }

  handleMeanTimePaused(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const meanTimePaused = {
        data: [], labelX: [], labelY: [], domain: 0,
      };
      const matrix = QueueTab.createPauseMatrix(res.data.data.places.data);
      const matrixSize = res.data.data.places.data.length - 1;
      const labelX = []; const labelY = []; const
        positionOrder = [];

      res.data.data.places.data.forEach((place) => {
        positionOrder.push(place.order);
        if (place.name.length > 5) {
          labelX.push(`${place.name.substr(0, 5)}.`);
          labelY.push(`${place.name.substr(0, 5)}.`);
        } else {
          labelX.push(place.name);
          labelY.push(place.name);
        }
      });

      res.data.data.time.forEach((item) => {
        // insures that are only used correct values with indexOf positionOrder
        const x = positionOrder.indexOf(item.place_end) - 1;
        const y = matrixSize - positionOrder.indexOf(item.place_start);
        matrix[x][y].color = item.average; // .toFixed(1);
      });

      meanTimePaused.data = [].concat(...matrix);
      meanTimePaused.labelX = labelX;
      meanTimePaused.labelY = labelY.reverse();
      meanTimePaused.domain = matrixSize;
      meanTimePaused.loading = false;
      this.setState({ meanTimePaused });
    }
  }

  handleFilterMeanTimePaused() {
    const { meanTimePaused } = this.state;
    this.setState({ meanTimePaused: { ...meanTimePaused, loading: true } });
    const { filterOptions } = this.props;
    const options = filterOptions;
    pipe(
      'get',
      `/v1/stats/mean_time_paused${options !== '' ? `?${options}` : ''}`,
      this.handleMeanTimePaused,
      this.handleError,
      null,
      null,
      this.source.token,
    );
  }

  render() {
    const { meanTimePaused, error } = this.state;

    return (
      <Tab.Pane attached>

        <Message
          hidden={!error.visible}
          negative
          header='Oops, algo de errado aconteceu'
          content={error.message}
          list={error.extra}
        />

        <Grid centered stackable>
          <Grid.Row columns='equal'>
            {meanTimePaused.data.length !== 0
              ? (
                <Grid.Column textAlign='center'>
                  <Label
                    as='a'
                    basic
                    color={process.env.REACT_APP_PRIMARY_COLOR}
                    content='Tempos de Espera Por Local'
                    size='large'
                    icon='search'
                    labelposition='left'
                    onClick={this.handleFilterMeanTimePaused}
                  />
                  <p />
                  <MeanTimePaused
                    meanTimePaused={meanTimePaused}
                  />
                </Grid.Column>
              )
              : (
                <Grid.Column textAlign='center'>
                  <Label
                    as='a'
                    basic
                    color={process.env.REACT_APP_PRIMARY_COLOR}
                    content='Tempos de Espera Por Local'
                    size='large'
                    icon='search'
                    labelposition='left'
                    onClick={this.handleFilterMeanTimePaused}
                  />
                  <EmptyChart loading={meanTimePaused.loading} />
                </Grid.Column>
              )}
            <p />
          </Grid.Row>
        </Grid>
      </Tab.Pane>
    );
  }
}

export default QueueTab;

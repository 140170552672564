import React from 'react';
import ListItems from '../../../Components/Private/ListItems';

// eslint-disable-next-line react/prefer-stateless-function
class Questions extends React.Component {
  render() {
    return (
      <ListItems
        kind='questions'
        placeHolder='Questões'
        api='/v1/questions'
        add // {(localStorage.getItem('usertype') === 'admin')}
        fields={{
          description: 'Descrição',
          type: 'Tipo',
          order: 'Ordem',
          status: 'Estado',
        }}
        {...this.props}
      />
    );
  }
}

export default Questions;

import React from 'react';
import ListItems from '../../../Components/Private/ListItems';

// eslint-disable-next-line react/prefer-stateless-function
class Places extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickPlace = this.handleClickPlace.bind(this);
  }

  handleClickPlace(id) {
    const { history, location } = this.props;
    const urlString = window.location.href;
    const url = new URL(urlString);
    history.push(`${location.pathname}/${id}/edit${url.search}`);
  }

  render() {
    const placeRenderBodyRow = ({ attributes, id }, i) => ({
      key: id || `row-${i}`,
      onClick: () => this.handleClickPlace(id),
      cells: [
        { key: 'name', content: attributes.name || '' },
        { key: 'order', content: attributes.order || '' },
        { key: 'number_servers', content: attributes.number_servers || '' },
        { key: 'debit_per_hour', content: attributes.debit_per_hour || 'Inquantificável' },
        { key: 'status', content: attributes.status || '' },
      ],
    });

    return (
      <ListItems
        kind='places'
        placeHolder='Locais'
        api='/v1/places'
        add // {(localStorage.getItem('usertype') === 'admin')}
        fields={{
          name: 'Nome',
          order: 'Ordem',
          number_servers: 'Servidores',
          debit_per_hour: 'Débito (Unid./h)',
          status: 'Estado',
        }}
        renderBodyRow={placeRenderBodyRow}
        {...this.props}
      />
    );
  }
}

export default Places;

import React from 'react';
import {
  FlexibleXYPlot, HeatmapSeries, LabelSeries, XAxis, YAxis,
} from 'react-vis';

// eslint-disable-next-line react/prefer-stateless-function
class MeanTimePaused extends React.Component {
  render() {
    const MARGIN_TEXT = {
      left: 45,
      right: 10,
      bottom: 40,
      top: 45,
    };
    const { meanTimePaused } = this.props;
    const {
      data, domain, labelX, labelY, loading,
    } = meanTimePaused;

    const { max } = data.reduce(
      (acc, row) => ({
        max: Math.max(acc.max, row.color),
      }),
      { max: -Infinity },
    );

    return (
      <FlexibleXYPlot
        margin={MARGIN_TEXT}
        xDomain={[1, domain]}
        yDomain={[0, domain]}
        height={500}
        animation
      >
        <XAxis
          orientation='top'
          tickFormat={(v) => labelX[v]}
          tickLabelAngle={-45}
        />
        <YAxis
          tickFormat={(v) => labelY[v]}
          tickLabelAngle={-45}
        />
        <HeatmapSeries
          className='heatmap-series-example'
          colorRange={['#d9d9d9', '#99e699', '#ffcc00', '#ff3300']}
          colorDomain={[0, 0.0001, max * 0.45, max * 0.95]} // 0.0002h less than 1s
          style={{
            stroke: 'white',
            strokeWidth: '2px',
            rectStyle: {
              rx: 10,
              ry: 10,
            },
          }}
          data={data}
          opacity={loading ? 0.2 : 1}
        />
        <LabelSeries
          style={{ pointerEvents: 'none', opacity: loading ? 0.2 : 1 }}
          data={data}
          labelAnchorX='middle'
          labelAnchorY='middle'
          getLabel={(d) => (d.color === '' ? `${d.color}` : `${parseFloat(d.color, 10).toFixed(1)}`)}
        />
      </FlexibleXYPlot>
    );
  }
}

export default MeanTimePaused;

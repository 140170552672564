import React from 'react';
import {
  Button, Container, Form, Message, Segment,
} from 'semantic-ui-react';
import has from 'has';
import { handleChange, handleError, pipe } from '../../../Helpers';
import Error404 from '../../Errors/Error404';

class EditQuestion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      description: '',
      status: '',
      order: '',
      type: '',
      error: {
        visible: false,
        status: '',
        message: '',
        extra: [],
      },
      loading: true,
    };

    this.handleChange = handleChange.bind(this);
    this.handleError = handleError.bind(this);
    this.handleConfirmChangesClick = this.handleConfirmChangesClick.bind(this);
    this.handleQuestion = this.handleQuestion.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }

  componentDidMount() {
    document.title = 'ThePipeTracker - Editar Local';
    const { match } = this.props;
    const { id } = match.params;
    pipe(
      'get',
      `/v1/questions/${id}`,
      this.handleQuestion,
      this.handleError,
    );
  }

  handleQuestion(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      this.setState({
        description: res.data.data.attributes.description || '',
        status: res.data.data.attributes.status || '',
        order: res.data.data.attributes.order || '',
        type: res.data.data.attributes.type || '',
        loading: false,
      });
    }
  }

  handleConfirmChangesClick() {
    this.setState({ loading: true });
    const { match } = this.props;
    const { id } = match.params;
    const { order, status } = this.state;

    if (this.verifyContent() && !isNaN(parseFloat(order))) {
      pipe(
        'patch',
        `/v1/questions/${id}`,
        this.handleSuccess,
        this.handleError,
        {
          question: {
            status,
            order,
          },
        },
      );
    } else {
      const error = { visible: true, message: 'Por favor preencha todos os campos' };
      this.setState({ error, loading: false });
    }
  }

  handleSuccess() {
    const { history } = this.props;
    history.push('/questions');
  }

  verifyContent() {
    const { order } = this.state;
    return order !== '';
  }

  render() {
    const options = [
      { key: 'a', text: 'Active', value: 'active' },
      { key: 'i', text: 'Inactive', value: 'inactive' },
    ];
    const {
      error, loading, order, status, description, type,
    } = this.state;

    return (
      <>
        <Container className='privateContainer'>
          {error.status === 404
            ? <Error404 />
            : (
              <Segment color={process.env.REACT_APP_PRIMARY_COLOR} loading={loading}>
                <h3>Editar Questão</h3>
                <Form>
                  <Form.Input
                    disabled
                    label='Descrição'
                    name='description'
                    maxLength='250'
                    value={description}
                  />
                  <Form.Input
                    disabled
                    label='Tipo'
                    name='type'
                    value={type}
                  />
                  <Form.Input
                    required
                    autoComplete='off'
                    label='Ordem no Questionário'
                    name='order'
                    max='2147483647'
                    min='-2147483648'
                    type='number'
                    value={order}
                    onChange={this.handleChange}
                  />
                  <Form.Dropdown
                    required
                    label='Status'
                    name='status'
                    fluid
                    selection
                    onChange={(e, item) => this.handleChange(item)}
                    value={status}
                    options={options}
                  />
                  <Message
                    hidden={!error.visible}
                    negative
                    header='Edição de Questão Falhou'
                    content={error.message}
                    list={error.extra}
                  />
                  <Button
                    icon='edit'
                    labelPosition='left'
                    color={process.env.REACT_APP_PRIMARY_COLOR}
                    size='large'
                    onClick={this.handleConfirmChangesClick}
                    content='Confirmar'
                  />
                </Form>
              </Segment>
            )}
        </Container>
      </>
    );
  }
}

export default EditQuestion;

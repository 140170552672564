import React from 'react';
import { Header, Icon, Segment } from 'semantic-ui-react';

// eslint-disable-next-line react/prefer-stateless-function
class EmptyPipeline extends React.Component {
  render() {
    const { loading, reconnect } = this.props;
    return (
      <Segment
        loading={loading && reconnect <= 1}
        placeholder
        basic
        style={{ height: '55vh' }}
      >
        <Header icon>
          <Icon name={reconnect <= 1 ? 'box' : 'exclamation triangle'} />
          <br />
          {reconnect <= 1
            ? <p>Não existem locais do Pipeline</p>
            : window.location.reload()}
        </Header>
      </Segment>
    );
  }
}

export default EmptyPipeline;

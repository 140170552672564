import React from 'react';
import {
  Card, Form, Header, Icon, Label, Segment,
} from 'semantic-ui-react';
import '../../CSS/PlaceGroup.css';
import MinimizedPlace from './MinimizedPlace';
import StateCard from './StateCard';

class PlaceGroup extends React.Component {
  constructor(props) {
    super(props);
    this.calcNumMinimizedPlaces = this.calcNumMinimizedPlaces.bind(this);
  }

  calcNumMinimizedPlaces() {
    const { visible } = this.props;
    return visible.filter((place) => !place).length;
  }

  render() {
    const MOBILE_VERSION_PX = 800;
    const {
      pipeline, width,
      visible, loading, number, loadingInput,
      handleMinMax, handleAddEnterKey, handleAdd,
      handleResume, handleTerminate, handleComplete,
      handlePause, handleDelete, handleNumberChange,
    } = this.props;
    const numMinimized = this.calcNumMinimizedPlaces();

    return (
      <Segment.Group
        horizontal={!(width <= MOBILE_VERSION_PX)}
        className={!(width <= MOBILE_VERSION_PX) ? 'pipeContainer' : ''}
        style={{ background: '#FFFFFF' }}
      >
        {pipeline.map((pipePlace, i) => (
          visible[i]
            ? (
              <Segment.Group
                className={!(width <= MOBILE_VERSION_PX) ? 'placeContainer' : 'placeContainerMobile'}
                style={{ '--n': pipeline.length - numMinimized }}
                key={pipePlace.place_id}
              >
                <Segment
                  color={process.env.REACT_APP_TERTIARY_COLOR}
                  key={`place${pipePlace.place_id}`}
                  secondary
                >
                  <Label
                    as='a'
                    icon={!(width <= MOBILE_VERSION_PX) ? 'angle left' : 'angle up'}
                    size='big'
                    style={{ background: '#F3F4F5', color: 'rgba(0, 0, 0, 0.6)' }}
                    content={pipePlace.place.toUpperCase()}
                    onClick={() => handleMinMax(i, false)}
                    attached='top'
                  />

                  <Form.Input
                    fluid
                    required
                    loading={loadingInput}
                    autoComplete='off'
                    placeholder='Nº Encomenda'
                    name='number'
                    type='number'
                    id={`${i}`}
                    value={number[i]}
                    onChange={handleNumberChange}
                    onKeyDown={(e) => handleAddEnterKey(e, pipePlace.place_id, i)}
                    action={{
                      color: process.env.REACT_APP_PRIMARY_COLOR,
                      icon: 'add',
                      onClick: () => handleAdd(pipePlace.place_id, i),
                    }}
                  />
                </Segment>
                <Segment
                  loading={loading}
                  color={process.env.REACT_APP_TERTIARY_COLOR}
                  secondary
                  className={!(width <= MOBILE_VERSION_PX) ? 'packageContainer' : ''}
                  key={`package${pipePlace.place_id}`}
                >
                  {pipePlace.states.length === 0
                    ? (
                      <Segment
                        placeholder
                        basic
                        className={!(width <= MOBILE_VERSION_PX) ? 'packagePlaceHolder' : ''}
                      >
                        <Header icon>
                          <Icon name='box' />
                          <br />
                          <p>Não há encomendas neste local do Pipeline</p>
                        </Header>
                      </Segment>
                    )
                    : (
                      <Card.Group stackable>
                        {pipePlace.states.map((state) => (
                          <StateCard
                            key={state.id}
                            width={width}
                            state={state}
                            visible={visible}
                            pipePlace={pipePlace}
                            handleResume={handleResume}
                            handleTerminate={handleTerminate}
                            handleComplete={handleComplete}
                            handlePause={handlePause}
                            handleDelete={handleDelete}
                            calcNumMinimizedPlaces={this.calcNumMinimizedPlaces}
                          />
                        ))}
                      </Card.Group>
                    )}
                </Segment>
              </Segment.Group>
            )
            : (
              <MinimizedPlace
                key={pipePlace.place_id}
                width={width}
                placeOrder={i}
                handleMinMax={handleMinMax}
                pipePlace={pipePlace}
              />
            )
        ))}
      </Segment.Group>
    );
  }
}

export default PlaceGroup;

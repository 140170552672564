import React from 'react';
import {
  Grid, Label, Message, Tab,
} from 'semantic-ui-react';
import 'react-vis/dist/style.css';
import { DiscreteColorLegend } from 'react-vis';
import has from 'has';
import axios from 'axios';
import {
  handleError, pipe,
} from '../../../../Helpers';
import NumPackages from '../Plots/NumPackages';
import EmptyChart from '../Plots/EmptyChart';
import DeliveryPercentage from '../Plots/DeliveryPercentage';
import DeliveryOverall from '../Plots/DeliveryOverall';

class PackageTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numPackages: {
        registered: [],
        completed: [],
        onTime: [],
        percentage: 0,
        domain: [],
        labelX: [],
        loading: true,
      },
      error: {
        visible: false,
        message: '',
        extra: [],
      },
    };
    const { CancelToken } = axios;
    this.source = CancelToken.source();
    this.handleError = handleError.bind(this);
    this.handleNumPackages = this.handleNumPackages.bind(this);
    this.handleFilterNumPackages = this.handleFilterNumPackages.bind(this);
  }

  componentDidMount() {
    pipe(
      'get',
      '/v1/stats/num_packages',
      this.handleNumPackages,
      this.handleError,
      null,
      null,
      this.source.token,
    );
  }

  componentWillUnmount() {
    this.source.cancel();
  }

  handleNumPackages(res) {
    if (res && has(res, 'data') && has(res.data, 'data')) {
      const numPackages = {
        registered: [],
        completed: [],
        onTime: [],
        percentage: 0,
        labelX: [],
        domain: [0, 0],
        loading: false,
      };
      let accOnTime = 0;
      let accCount = 0;

      res.data.data.registered.forEach((item, i) => {
        numPackages.registered.push({ x: i, y: item.count });
        numPackages.labelX.push(`${item.part}/${item.year}`);

        if (item.count > numPackages.domain[1]) numPackages.domain[1] = item.count;
      });

      res.data.data.completed.forEach((item, i) => {
        numPackages.completed.push({ x: i, y: item.count });
        numPackages.onTime.push({
          x: i,
          y: (item.count !== 0) ? (item.on_time / item.count) * 100 : 0, // percentage by month
        });
        accOnTime += item.on_time;
        accCount += item.count;

        if (item.count > numPackages.domain[1]) numPackages.domain[1] = item.count;
      });

      numPackages.percentage = (accCount !== 0) ? (accOnTime / accCount) : 0;
      this.setState({ numPackages });
    }
  }

  handleFilterNumPackages() {
    const { numPackages } = this.state;
    this.setState({ numPackages: { ...numPackages, loading: true } });
    const { filterOptions } = this.props;
    const options = filterOptions;
    pipe(
      'get',
      `/v1/stats/num_packages${options !== '' ? `?${options}` : ''}`,
      this.handleNumPackages,
      this.handleError,
      null,
      null,
      this.source.token,
    );
  }

  render() {
    const { numPackages, error } = this.state;
    const LEG1_ITEMS = [
      { title: 'Registadas', color: '#007399' },
      { title: 'Completas', color: '#ff3300' },
    ];

    return (
      <Tab.Pane attached>

        <Message
          hidden={!error.visible}
          negative
          header='Oops, algo de errado aconteceu'
          content={error.message}
          list={error.extra}
        />

        <Grid centered stackable>
          <Grid.Row columns='equal'>
            <Grid.Column textAlign='center' verticalAlign='middle'>
              <Label
                as='a'
                basic
                color={process.env.REACT_APP_PRIMARY_COLOR}
                content='Análise Encomendas'
                icon='search'
                onClick={this.handleFilterNumPackages}
                size='large'
              />
            </Grid.Column>
            <Grid.Column textAlign='left'>
              <DiscreteColorLegend
                orientation='horizontal'
                style={{ width: '100%', paddingLeft: '30px' }}
                items={LEG1_ITEMS}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns='equal'>
            {numPackages.completed.length !== 0 || numPackages.registered.length !== 0
              ? (
                <Grid.Column textAlign='center'>
                  <NumPackages numPackages={numPackages} />
                </Grid.Column>
              )
              : (
                <Grid.Column>
                  <EmptyChart loading={numPackages.loading} />
                </Grid.Column>
              )}
          </Grid.Row>

          <Grid.Row columns='equal'>
            <Grid.Column textAlign='center' verticalAlign='middle'>
              <Label
                basic
                color={process.env.REACT_APP_PRIMARY_COLOR}
                content='Percentagem Entregue a Tempo'
                size='large'
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns='equal'>
            {numPackages.completed.length !== 0
              ? (
                <Grid.Column textAlign='center' width={10}>
                  <DeliveryPercentage numPackages={numPackages} />
                </Grid.Column>
              )
              : (
                <Grid.Column textAlign='center' width={10}>
                  <EmptyChart loading={numPackages.loading} />
                </Grid.Column>
              )}
            {numPackages.completed.length !== 0
              ? (
                <Grid.Column textAlign='center' width={6}>
                  <DeliveryOverall numPackages={numPackages} />
                </Grid.Column>
              )
              : (
                <Grid.Column textAlign='center' width={6}>
                  <EmptyChart loading={numPackages.loading} />
                </Grid.Column>
              )}
          </Grid.Row>
        </Grid>
      </Tab.Pane>
    );
  }
}

export default PackageTab;

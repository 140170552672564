import React from 'react';
import { Icon, Label } from 'semantic-ui-react';
import ListItems from '../../../Components/Private/ListItems';

class Packages extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickPackage = this.handleClickPackage.bind(this);
  }

  handleClickPackage(id) {
    const { history, location } = this.props;
    const urlString = window.location.href;
    const url = new URL(urlString);
    history.push(`${location.pathname}/${id}/edit${url.search}`);
  }

  render() {
    const started = function hasStarted(statesCount) {
      if (statesCount === 0) {
        return { key: 'status', content: <Label color='red'>Registada</Label> };
      }
      return 'Em processamento';
    };
    const behindSched = function isBehindSched(deliveryDate, prediction, status) {
      if (deliveryDate < new Date() && status !== 'completed') {
        return (
          <span>
            <Icon color='red' name='warning sign' size='large' />
            {' '}
            {deliveryDate.toLocaleDateString()}
          </span>
        );
      }
      if (prediction !== null && new Date(prediction.attributes.delivery) > deliveryDate && status !== 'completed') {
        return (
          <span>
            <Icon color='yellow' name='warning sign' size='large' />
            {' '}
            {deliveryDate.toLocaleDateString()}
          </span>
        );
      }
      return <span>{deliveryDate.toLocaleDateString()}</span>;
    };

    const packageRenderBodyRow = ({ attributes, id }, i) => ({
      key: id || `row-${i}`,
      onClick: () => this.handleClickPackage(id),
      cells: [
        { key: 'number', content: attributes.number || 'No number specified' },
        { key: 'description', content: attributes.description || 'No description specified' },
        { key: 'owner', content: attributes.owner || 'Indefinido' },
        { key: 'owner_name', content: attributes.owner_name || 'Indefinido' },
        { key: 'registered', content: new Date(attributes.registered).toLocaleDateString() || 'Indefinido' },
        {
          key: 'delivery_date',
          content: attributes.delivery_date ? behindSched(new Date(attributes.delivery_date), attributes.prediction, attributes.status) : 'Indefinido',
        },
        (attributes.status === 'started')
          ? started(attributes.states_count)
          : { key: 'status', content: <Label color='green'>Concluída</Label> },
      ],
    });

    return (
      <ListItems
        placeHolder='Encomendas'
        kind='packages'
        api='/v1/packages'
        add
        search
        filterState
        fields={{
          number: 'Número',
          description: 'Descrição',
          owner: 'Email',
          owner_name: 'Cliente',
          registered: 'Registada',
          delivery_date: 'Entrega',
          status: 'Estado',
        }}
        renderBodyRow={packageRenderBodyRow}
        options={[
          { key: 'number', value: 'number', text: 'Número' },
          { key: 'description', value: 'description', text: 'Descrição' },
          { key: 'owner', value: 'owner', text: 'Email' },
          { key: 'owner_name', value: 'owner_name', text: 'Cliente' },
        ]}
        {...this.props}
      />
    );
  }
}

export default Packages;

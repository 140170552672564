import React, { Component } from 'react';
import {
  Button, Container, Grid, Segment,
} from 'semantic-ui-react';
import '../CSS/CookieBanner.css';
import { Link } from 'react-router-dom';
import { setCookie } from '../../Helpers';

class CookieBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: 'cookie',
    };
    this.handleAccept = this.handleAccept.bind(this);
  }

  handleAccept() {
    setCookie('accepts-cookies', 'true', 365);
    this.setState({ style: 'hidden' });
  }

  render() {
    const { style } = this.state;
    return (
      <Segment className={style}>
        <Container>
          <Grid verticalAlign='middle'>
            <Grid.Column>
              Utilizamos cookies no nosso website. Os cookies são utilizados para melhorar a
              funcionalidade e utilização do nosso Serviço, bem como para efeitos de análise.
              Ao continuar a utilizar o nosso serviço sem alterar as suas definições,
              está a autorizar a utilização de cookies.
              Para saber mais sobre cookies, como os utilizamos
              e como alterar as definições, saiba mais em
              {' '}
              <Link to='/privacy-policy'>Política de Privacidade</Link>
              .
              <b>
                {' '}
                Se desativar os cookies por completo no seu browser, algumas funcionalidades
                da plataforma podem não funcionar corretamente.
              </b>
              <p />
              <Button
                onClick={this.handleAccept}
                size='large'
                basic
                color={process.env.REACT_APP_SECONDARY_COLOR}
                content='Aceitar'
              />
            </Grid.Column>
          </Grid>
        </Container>
      </Segment>
    );
  }
}

export default CookieBanner;

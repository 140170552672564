import React from 'react';
import {
  FlexibleXYPlot, Hint, HorizontalGridLines, LineSeries, MarkSeries, Voronoi, XAxis, YAxis,
} from 'react-vis';

class NumPackages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numPackagesValue: null,
    };
  }

  render() {
    const MARGIN_NUMBER = {
      left: 50,
      right: 30,
      bottom: 70,
      top: 5,
    };
    const { numPackages } = this.props;
    const { numPackagesValue } = this.state;
    const {
      domain, labelX, completed, registered, loading,
    } = numPackages;

    return (
      <FlexibleXYPlot
        margin={MARGIN_NUMBER}
        height={300}
        yDomain={domain}
        animation
      >
        <HorizontalGridLines />
        <XAxis
          title='Data'
          tickFormat={(v) => labelX[v]}
          tickLabelAngle={-45}
        />
        <YAxis
          title='Total'
        />
        <LineSeries
          color='#ff3300'
          curve='curveMonotoneX'
          data={completed}
          opacity={loading ? 0.2 : 1}
          style={{
            strokeWidth: 4,
          }}
        />
        <LineSeries
          color='#007399'
          curve='curveMonotoneX'
          data={registered}
          opacity={loading ? 0.2 : 1}
          style={{
            strokeWidth: 4,
          }}
        />
        {numPackagesValue && <MarkSeries color='white' stroke='black' data={[numPackagesValue]} />}
        <Voronoi
          nodes={registered.concat(completed)}
          onHover={(node) => this.setState({ numPackagesValue: node })}
          onBlur={() => this.setState({ numPackagesValue: null })}
        />
        {numPackagesValue !== null ? <Hint value={numPackagesValue} /> : null}
      </FlexibleXYPlot>
    );
  }
}

export default NumPackages;

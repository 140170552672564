import React from 'react';
import {
  Button, Container, Form, Message, Segment,
} from 'semantic-ui-react';
import { handleChange, handleError, pipe } from '../../../Helpers';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      success: false,
      loading: false,
      error: {
        visible: false,
        message: '',
        extra: [],
      },
    };

    this.handleError = handleError.bind(this);
    this.handleChange = handleChange.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
  }

  componentDidMount() {
    document.title = 'ThePipeTracker - Recuperar Password';
  }

  handleChangePassword() {
    this.setState({ loading: true });
    const { email } = this.state;
    pipe(
      'post',
      '/auth/passwords',
      this.handleSuccess,
      this.handleError,
      {
        user: {
          email,
        },
      },
    );
  }

  handleSuccess() {
    const { history } = this.props;
    history.push('/');
    history.push('/login');
  }

  render() {
    const {
      loading, email, success, error,
    } = this.state;
    return (
      <>
        <Container className='publicContainer'>
          <Segment color={process.env.REACT_APP_PRIMARY_COLOR} loading={loading}>
            <h3>Inserir Email de Recuperação</h3>
            <Form>
              <Form.Input
                required
                label='Email'
                name='email'
                value={email}
                onChange={this.handleChange}
              />
              <Message
                hidden={!success}
                success
                size='mini'
                content='Um email de recuperação foi enviado com sucesso'
              />
              <Message
                hidden={!error.visible}
                negative
                size='mini'
                content={error.message}
                list={error.extra}
              />
              <Button
                icon='key'
                labelPosition='left'
                color={process.env.REACT_APP_PRIMARY_COLOR}
                size='large'
                onClick={this.handleChangePassword}
                content='Recuperar'
              />
            </Form>
          </Segment>
        </Container>
      </>
    );
  }
}

export default ForgotPassword;
